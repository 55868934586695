// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.header-logo {
    height: 40px;
}

.nav-items {
    display: flex;
    align-items: center;
    gap: 20px;
}

.nav-item {
    display: flex;
    align-items: center;
    gap: 10px;
}

.nav-item img {
    height: 24px;
    cursor: pointer;
}

.nav-item img:hover {
    opacity: 0.7;
}

.client-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/public_header/PublicHeader.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,kBAAkB;IAClB,sBAAsB;IACtB,wCAAwC;AAC5C;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,SAAS;AACb","sourcesContent":[".main-header {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    padding: 10px 20px;\n    background-color: #fff;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n}\n\n.header-logo {\n    height: 40px;\n}\n\n.nav-items {\n    display: flex;\n    align-items: center;\n    gap: 20px;\n}\n\n.nav-item {\n    display: flex;\n    align-items: center;\n    gap: 10px;\n}\n\n.nav-item img {\n    height: 24px;\n    cursor: pointer;\n}\n\n.nav-item img:hover {\n    opacity: 0.7;\n}\n\n.client-actions {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    gap: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
