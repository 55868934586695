import React, {useEffect, useState} from 'react';
import './ScreenModal.css';
import {GenericIcons as GenericIcon} from "../../../../assets/icons";
import Organization from "./organization/Organization";
import {PrimaryButton} from "../../../buttons/primary_button/PrimaryButton";
import Donor from "./donor/Donor";
import Device from "./device/Device";
import {clearOrganization} from "../../../../store/autobot/clients/organizations/organizationsSlice";
import {useDispatch, useSelector} from "react-redux";
import TestingReason from "./testing_reason/TestingReason";
import {IconButton} from "../../../buttons/icon_button/IconButton";
import Screener from "./screener/Screener";
import PanelConfiguration from "./panel_configuration/PanelConfiguration";
import Location from "./location/Location";
import ShowDetails from "./show_details/ShowDetails";

const ScreenModal = ({isOpen, onClose, currentSpecimen, onSave, isEditing}) => {
    const [editedFieldsMap, setEditedFieldsMap] = useState({
        device_id: currentSpecimen?.device_id
    });
    const [hasEdits, setHasEdits] = useState(false);
    const [organization, setOrganization] = useState(currentSpecimen?.organization_name || {});
    const [panelConfiguration, setPanelConfiguration] = useState({
        "panel_name": currentSpecimen?.panel_name,
        "panel_id": currentSpecimen?.panel_id,
        "original_panel_id": currentSpecimen?.panel_id
    } || {});
    const [donorName, setDonorName] = useState({});
    const [showDetails, setShowDetails] = useState({show_details: currentSpecimen?.show_details});
    const [testingReason, setTestingReason] = useState({testing_reason: currentSpecimen?.testing_reason } || {});
    const [screener, setScreener] = useState({screener: currentSpecimen?.screener } || {});
    const [location, setLocation] = useState( {
        location_id: currentSpecimen?.location_id,
        location_name: currentSpecimen?.location_name,
    });

    useEffect(() => {
        if (Object.keys(editedFieldsMap).length > 0) {
            setHasEdits(true);
        }
    }, [editedFieldsMap]);


    const dispatch = useDispatch();
    if (!isOpen) return null;

    const handleClose = () => {
        dispatch(clearOrganization());
        setHasEdits(false);
        setEditedFieldsMap({});
        onClose();
    };


    const handleDonorNameChange = (donor) => {
        setEditedFieldsMap(prev => ({
            ...prev,
            original_donor_id: donor?.original_donor_id,
            donor_id: donor?.donor_id
        }));
    };


    const handleOrganizationChange = (org) => {
        setEditedFieldsMap(prev => ({
            ...prev,
            original_organization_id: org?.original_organization_id,
            organization_id: org?.organization_id
        }));
    };


    const handleTestingReasonChange = (testingReason) => {
        setEditedFieldsMap(prev => ({
            ...prev,
            original_testing_reason_id: testingReason?.original_testing_reason_id,
            testing_reason_id: testingReason?.testing_reason_id,
            testing_reason: testingReason?.testing_reason
        }));
    };


    const handlePanelConfigurationChange = (panelConfiguration) => {
        setEditedFieldsMap(prev => ({
            ...prev,
            original_panel_id: panelConfiguration?.original_panel_id,
            panel_id: panelConfiguration?.panel_id
        }));
    };

    const handleScreenerChange = (screenerData) => {
        setEditedFieldsMap(prev => ({
            ...prev,
            original_collector_id: screenerData?.original_collector_id,
            collector_id: screenerData?.collector_id
        }));
    };


    const handleLocationChange = (locationDetails) => {
        setEditedFieldsMap(prev => ({
            ...prev,
            original_location_id: locationDetails?.original_location_id,
            location_id: locationDetails?.location_id,
        }));
    };

    const handleShowDetailsChange = (showDetails) => {
        const originalShowDetails = showDetails ? 0 : 1;
        setEditedFieldsMap(prev => ({
            ...prev,
            show_details: showDetails,
            original_show_details: originalShowDetails,
        }));
    };




    const handleSave = () => {
        const finalEditedData = {
            ...editedFieldsMap,
            device_id: currentSpecimen?.device_id
        };
        onSave(finalEditedData);
        handleClose();
    };



    const donorDetails = {
        donor_id: currentSpecimen?.donor_id,
        donor_name: {
            donor_first_name: currentSpecimen?.donor_first_name,
            donor_last_name: currentSpecimen?.donor_last_name
        }
    };

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <div className="modal-header">
                    <IconButton onClick={handleClose} defaultIcon={GenericIcon.CloseIcon}
                                hoverIcon={GenericIcon.ExitIcon}/>
                </div>
                <form>
                    <div>
                        <Device device_id={currentSpecimen?.device_id}/>
                    </div>
                    <div>
                        <Donor device_id={currentSpecimen?.device_id}
                               donorDetails={donorDetails}
                               onDonorNameChange={handleDonorNameChange}/>
                    </div>
                    <div>
                    {
                        organization ?
                            null
                            :
                            <Organization
                                device_id={currentSpecimen?.device_id}
                                organization={organization}
                                onOrganizationChange={handleOrganizationChange}/>
                    }
                    </div>
                    <div>
                        <TestingReason
                            device_id={currentSpecimen?.device_id}
                            testingReasonDetails={testingReason}
                            onTestingReasonChange={handleTestingReasonChange}
                        />
                    </div>
                    <div>
                        <Screener
                            device_id={currentSpecimen?.device_id}
                            screenerDetails={{"collector_first_name": currentSpecimen?.collector_first_name, "collector_last_name": currentSpecimen?.collector_last_name}}
                            onScreenerChange={handleScreenerChange}
                        />
                    </div>
                    <div>
                        <PanelConfiguration
                            device_id={currentSpecimen?.device_id}
                            panelDetails={panelConfiguration}
                            onScreenerChange={handlePanelConfigurationChange}
                        />
                    </div>
                    <div>
                        <Location
                            device_id={currentSpecimen?.device_id}
                            locationDetails={location}
                            onLocationChange={handleLocationChange}
                        />
                    </div>
                    <div>
                        <ShowDetails
                            device_id={currentSpecimen?.device_id}
                            showDetails={showDetails}
                            onShowDetailsChange={handleShowDetailsChange}
                        />
                    </div>
                    {isEditing && hasEdits && (
                        <div className="update-button-container">
                            <PrimaryButton type="submit" onClick={handleSave}>UPDATE</PrimaryButton>
                        </div>
                    )}
                </form>
            </div>
        </div>
    );
};

export default ScreenModal;
