// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.box-style-options {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.box-style-options .box-image-large {
    width: 100px; /* Adjust as needed for larger size */
    height: 100px;
    cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/modals/order_detail/box_styles/BoxStyleOptions.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,YAAY,EAAE,qCAAqC;IACnD,aAAa;IACb,eAAe;AACnB","sourcesContent":[".box-style-options {\n    display: flex;\n    justify-content: space-between;\n    margin-bottom: 10px;\n}\n\n.box-style-options .box-image-large {\n    width: 100px; /* Adjust as needed for larger size */\n    height: 100px;\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
