// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customer-details-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f4f4f4;
    padding: 20px;
}

.customer-details-card {
    background-color: #ffffff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
    text-align: center;
}

.customer-details-title {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333333;
    font-family: 'Arial', sans-serif;
}

.customer-details-email {
    font-size: 18px;
    color: #555555;
    font-family: 'Arial', sans-serif;
    word-wrap: break-word;
}
`, "",{"version":3,"sources":["webpack://./src/components/user/cusotmer_details/ShopifyCustomerDetails.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,iBAAiB;IACjB,yBAAyB;IACzB,aAAa;AACjB;;AAEA;IACI,yBAAyB;IACzB,aAAa;IACb,kBAAkB;IAClB,wCAAwC;IACxC,gBAAgB;IAChB,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,mBAAmB;IACnB,cAAc;IACd,gCAAgC;AACpC;;AAEA;IACI,eAAe;IACf,cAAc;IACd,gCAAgC;IAChC,qBAAqB;AACzB","sourcesContent":[".customer-details-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    min-height: 100vh;\n    background-color: #f4f4f4;\n    padding: 20px;\n}\n\n.customer-details-card {\n    background-color: #ffffff;\n    padding: 30px;\n    border-radius: 8px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n    max-width: 400px;\n    width: 100%;\n    text-align: center;\n}\n\n.customer-details-title {\n    font-size: 24px;\n    margin-bottom: 20px;\n    color: #333333;\n    font-family: 'Arial', sans-serif;\n}\n\n.customer-details-email {\n    font-size: 18px;\n    color: #555555;\n    font-family: 'Arial', sans-serif;\n    word-wrap: break-word;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
