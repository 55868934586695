import "./Dashboard.css";
import React from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";

const DashboardPage = () => {
    return (
        <div className="dashboard-container">
            <Header />
            <div className="dashboard-content">
                {/* Your main dashboard content will go here */}
                <p>Welcome to the Dashboard!</p>
            </div>
            {/*<Footer />*/}
        </div>
    );
};

export default DashboardPage;
