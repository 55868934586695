// src/routes/ShopifyProtectedRoute.js

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getShopifyOAuthUrl } from '../components/user/sign_in/SignIn';
import LoadingSpinningCup from '../pages/loading/Loading';
import { useLocation } from 'react-router-dom';
import { exchangeCodeForTokens } from '../utils/authUtils';
import { getShopifyAuth } from '../store/external/shopifyAuthSlice';

const ShopifyProtectedRoute = ({ children }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [shopifyGrantToken, setShopifyGrantToken] = useState(
        sessionStorage.getItem('shopifyGrantToken')
    );
    const location = useLocation();
    const hostname = window.location.hostname;
    const dispatch = useDispatch();

    useEffect(() => {
        const authenticateUser = async () => {
            const params = new URLSearchParams(location.search);
            const authorizationCode = params.get('code');

            if (shopifyGrantToken) {
                // User is already authenticated
                dispatch(getShopifyAuth());
                setIsLoading(false);
            } else if (authorizationCode) {
                // Exchange the authorization code for tokens
                try {
                    const tokens = await exchangeCodeForTokens(authorizationCode);
                    sessionStorage.setItem('shopifyGrantToken', tokens.access_token);
                    sessionStorage.setItem('shopifyIdToken', tokens.id_token);
                    setShopifyGrantToken(tokens.access_token);
                    dispatch(getShopifyAuth());

                    // Clean up URL
                    window.history.replaceState({}, document.title, location.pathname);
                    setIsLoading(false);
                } catch (error) {
                    console.error('Failed to exchange code for tokens:', error);
                    // Handle error appropriately
                    setIsLoading(false);
                }
            } else {
                // User is not authenticated; initiate OAuth flow
                try {
                    const oauthUrl = await getShopifyOAuthUrl(hostname);
                    window.location.href = oauthUrl;
                } catch (error) {
                    console.error('Failed to initiate OAuth flow:', error);
                    // Handle error appropriately
                    setIsLoading(false);
                }
            }
        };

        authenticateUser();
    }, [dispatch, hostname, location.pathname, location.search, shopifyGrantToken]);

    if (isLoading) {
        // Show a loading indicator while authenticating
        return <LoadingSpinningCup />;
    }

    if (!shopifyGrantToken) {
        // Authentication failed or user is not authenticated
        return <div>Error: Unable to authenticate. Please try again.</div>;
    }

    // User is authenticated; render the protected component
    return children;
};

export default ShopifyProtectedRoute;
