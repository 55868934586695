// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.automation-page-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: #ffffff;  /* Added a light background color */
}

.content-container {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    max-width: 1600px;
    margin: 0 auto;
}

.labb-clients-search-header {
    margin-bottom: 20px;

    margin-top: 20px;
}
.submit-initiate-automation-button {
    margin-bottom: 20px;
    margin-top: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/automation/AutomationPage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,aAAa;IACb,yBAAyB,GAAG,mCAAmC;AACnE;;AAEA;IACI,SAAO;IACP,aAAa;IACb,sBAAsB;IACtB,aAAa;IACb,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,mBAAmB;;IAEnB,gBAAgB;AACpB;AACA;IACI,mBAAmB;IACnB,gBAAgB;AACpB","sourcesContent":[".automation-page-container {\n    display: flex;\n    flex-direction: column;\n    height: 100vh;\n    background-color: #ffffff;  /* Added a light background color */\n}\n\n.content-container {\n    flex: 1;\n    display: flex;\n    flex-direction: column;\n    padding: 2rem;\n    max-width: 1600px;\n    margin: 0 auto;\n}\n\n.labb-clients-search-header {\n    margin-bottom: 20px;\n\n    margin-top: 20px;\n}\n.submit-initiate-automation-button {\n    margin-bottom: 20px;\n    margin-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
