// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Container for the tabs (buttons) */
.shopify-tabs {
    display: flex;            /* Use Flexbox */
    justify-content: center;   /* Center the buttons horizontally */
    gap: 20px;                 /* Add spacing between the buttons */
    margin-bottom: 20px;       /* Space below the buttons */
}

/* Style for each button */
.shopify-tabs button,
.shopify-tabs .PrimaryButton {
    padding: 10px 20px;        /* Padding for buttons */
    font-size: 16px;           /* Font size for readability */
    cursor: pointer;           /* Pointer cursor on hover */
    border: none;              /* Remove default borders */
    background-color: #007bff; /* Default button background color */
    color: white;              /* Text color */
    border-radius: 5px;        /* Rounded corners */
    transition: background-color 0.3s ease; /* Smooth transition on hover */
}

/* Hover effect for buttons */
.shopify-tabs button:hover,
.shopify-tabs .PrimaryButton:hover {
    background-color: #0056b3; /* Darker background on hover */
}

/* Content area below the tabs */
.shopify-content {
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/shopify/manage/ManageShopifyPage.css"],"names":[],"mappings":"AAAA,qCAAqC;AACrC;IACI,aAAa,aAAa,gBAAgB;IAC1C,uBAAuB,IAAI,oCAAoC;IAC/D,SAAS,kBAAkB,oCAAoC;IAC/D,mBAAmB,QAAQ,4BAA4B;AAC3D;;AAEA,0BAA0B;AAC1B;;IAEI,kBAAkB,SAAS,wBAAwB;IACnD,eAAe,YAAY,8BAA8B;IACzD,eAAe,YAAY,4BAA4B;IACvD,YAAY,eAAe,2BAA2B;IACtD,yBAAyB,EAAE,oCAAoC;IAC/D,YAAY,eAAe,eAAe;IAC1C,kBAAkB,SAAS,oBAAoB;IAC/C,sCAAsC,EAAE,+BAA+B;AAC3E;;AAEA,6BAA6B;AAC7B;;IAEI,yBAAyB,EAAE,+BAA+B;AAC9D;;AAEA,gCAAgC;AAChC;IACI,aAAa;IACb,yBAAyB;IACzB,sBAAsB;IACtB,kBAAkB;AACtB","sourcesContent":["/* Container for the tabs (buttons) */\n.shopify-tabs {\n    display: flex;            /* Use Flexbox */\n    justify-content: center;   /* Center the buttons horizontally */\n    gap: 20px;                 /* Add spacing between the buttons */\n    margin-bottom: 20px;       /* Space below the buttons */\n}\n\n/* Style for each button */\n.shopify-tabs button,\n.shopify-tabs .PrimaryButton {\n    padding: 10px 20px;        /* Padding for buttons */\n    font-size: 16px;           /* Font size for readability */\n    cursor: pointer;           /* Pointer cursor on hover */\n    border: none;              /* Remove default borders */\n    background-color: #007bff; /* Default button background color */\n    color: white;              /* Text color */\n    border-radius: 5px;        /* Rounded corners */\n    transition: background-color 0.3s ease; /* Smooth transition on hover */\n}\n\n/* Hover effect for buttons */\n.shopify-tabs button:hover,\n.shopify-tabs .PrimaryButton:hover {\n    background-color: #0056b3; /* Darker background on hover */\n}\n\n/* Content area below the tabs */\n.shopify-content {\n    padding: 20px;\n    background-color: #f9f9f9;\n    border: 1px solid #ddd;\n    border-radius: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
