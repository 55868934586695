import './ShopifyHeader.css';
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { getShopifyOAuthUrl } from "../user/sign_in/SignIn";
import UserIconCard from "../user/icon_card/IconCard";
import { HeaderIcons } from "../../assets/icons";

const ShopifyHeader = ({ onLogout }) => {
    const handleCollectorLogin = async () => {
        const hostname = window.location.hostname;
        const url = await getShopifyOAuthUrl(hostname);
        window.location.href = url;
    };
    const [shopifyAccessToken, setShopifyAccessToken] = useState(sessionStorage.getItem('shopifyAccessToken'));

    return (
        <header className="shopify-header">
            <div className="header-left">
                <NavLink to="/home" className="logo-container">
                    <img
                        className="header-logo"
                        src="https://static.wixstatic.com/media/40f727_b17ccfdda56f41e494a6586affdeae2e~mv2.png"
                        alt="Labb Logo"
                    />
                </NavLink>
            </div>
            <div className="header-right">
                {shopifyAccessToken && (
                    <nav className="navbar-container">
                        <div className="nav-item">
                            <NavLink to="/panels">
                                <img src={HeaderIcons.PanelsIcon} alt="Panels" className="nav-icon" />
                            </NavLink>
                        </div>
                    </nav>
                )}
                {shopifyAccessToken && (
                    <nav className="navbar-container">
                        <div className="nav-item">
                            <NavLink to="/orders">
                                <img src={HeaderIcons.BillingInvoicesIcon} alt="Billing Invoices" className="nav-icon" />
                            </NavLink>
                        </div>
                    </nav>
                )}
                <div className="user-icon-card">
                    <UserIconCard handleCollectorLogin={handleCollectorLogin} onLogout={onLogout} />
                </div>
            </div>
        </header>
    );
};

export default ShopifyHeader;
