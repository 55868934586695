// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*freddy-react/src/components/specimens/Specimens.css*/

.specimens-container {
    padding: 20px;
    font-family: 'Arial', sans-serif;
    background-color: #f6f6f6;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding-top: 0px;
}

.filter-search-container {
    margin-bottom: 20px;
}

.specimens-table-container {
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 15px;
    position: relative;
    z-index: 1;
}


/* Download csv button css */
.downloadCSV {
    margin-left: 10px;
}

.downloadCSV button {
    width: 120px;
    height: 36px;
    font-size: 14px;
    border-radius: 5px;
    background: #fff;
    border: 1px solid #e0e0e0;
    color: rgb(0 0 0 / 40%);
    font-weight: 300;
}
`, "",{"version":3,"sources":["webpack://./src/components/specimens/Specimens.css"],"names":[],"mappings":"AAAA,sDAAsD;;AAEtD;IACI,aAAa;IACb,gCAAgC;IAChC,yBAAyB;IACzB,kBAAkB;IAClB,wCAAwC;IACxC,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,wCAAwC;IACxC,aAAa;IACb,kBAAkB;IAClB,UAAU;AACd;;;AAGA,4BAA4B;AAC5B;IACI,iBAAiB;AACrB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,yBAAyB;IACzB,uBAAuB;IACvB,gBAAgB;AACpB","sourcesContent":["/*freddy-react/src/components/specimens/Specimens.css*/\n\n.specimens-container {\n    padding: 20px;\n    font-family: 'Arial', sans-serif;\n    background-color: #f6f6f6;\n    border-radius: 5px;\n    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n    padding-top: 0px;\n}\n\n.filter-search-container {\n    margin-bottom: 20px;\n}\n\n.specimens-table-container {\n    background-color: #ffffff;\n    border-radius: 5px;\n    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n    padding: 15px;\n    position: relative;\n    z-index: 1;\n}\n\n\n/* Download csv button css */\n.downloadCSV {\n    margin-left: 10px;\n}\n\n.downloadCSV button {\n    width: 120px;\n    height: 36px;\n    font-size: 14px;\n    border-radius: 5px;\n    background: #fff;\n    border: 1px solid #e0e0e0;\n    color: rgb(0 0 0 / 40%);\n    font-weight: 300;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
