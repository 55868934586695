// freddy-react/src/App.js

import React, {useEffect} from 'react';
import './App.css';
import AuthenticatedWrapper from './components/user/authenticated/AuthenticatedWrapper';
import UnauthenticatedWrapper from "./components/user/unauthenticated/UnauthenticatedWrapper";
import {AuthenticatedTemplate, UnauthenticatedTemplate} from "@azure/msal-react";
import {Provider} from "react-redux";
import store from "./store/store";

function App({ instance }) {
    const hostname = window.location.hostname;

    return (
        <Provider store={store}>
            <div className="App">
                <main>
                    <AuthenticatedTemplate>
                        <AuthenticatedWrapper instance={instance} hostname={hostname} />
                    </AuthenticatedTemplate>

                    <UnauthenticatedTemplate>
                        <UnauthenticatedWrapper instance={instance} hostname={hostname} />
                    </UnauthenticatedTemplate>
                </main>
            </div>
        </Provider>
    );
}

export default App;

