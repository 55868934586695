// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.messages-modal {
    position: absolute;
    top: 60px;
    left: 0;
    width: 300px;
    height: calc(15vh - 5px);
    align-items: center;
    background-color: #fff;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    border-radius: 0 15px 15px 0;
    overflow-y: auto;
    z-index: 8000;
    border: 1px solid #e0e0e0;
}

.messages-modal-header {
    padding: 15px;
    background-color: #f8f8f8;
    border-bottom: 1px solid #e0e0e0;
    text-align: center;
    border-top-right-radius: 14px;
    position: relative;
}

.messages-modal-exit-icon {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    font-weight: bold;
}

.messages-modal-content {
    padding: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/components/modals/messages/MessagesModal.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,SAAS;IACT,OAAO;IACP,YAAY;IACZ,wBAAwB;IACxB,mBAAmB;IACnB,sBAAsB;IACtB,2CAA2C;IAC3C,4BAA4B;IAC5B,gBAAgB;IAChB,aAAa;IACb,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,yBAAyB;IACzB,gCAAgC;IAChC,kBAAkB;IAClB,6BAA6B;IAC7B,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".messages-modal {\n    position: absolute;\n    top: 60px;\n    left: 0;\n    width: 300px;\n    height: calc(15vh - 5px);\n    align-items: center;\n    background-color: #fff;\n    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);\n    border-radius: 0 15px 15px 0;\n    overflow-y: auto;\n    z-index: 8000;\n    border: 1px solid #e0e0e0;\n}\n\n.messages-modal-header {\n    padding: 15px;\n    background-color: #f8f8f8;\n    border-bottom: 1px solid #e0e0e0;\n    text-align: center;\n    border-top-right-radius: 14px;\n    position: relative;\n}\n\n.messages-modal-exit-icon {\n    position: absolute;\n    top: 15px;\n    right: 15px;\n    cursor: pointer;\n    font-weight: bold;\n}\n\n.messages-modal-content {\n    padding: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
