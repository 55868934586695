// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.show-details {
    width: 20%;
    max-width: 450px;
    min-width: 200px;
    height: fit-content;
    border: 2px solid #58585a;
    position: relative;
    background-color: #eceded;
    border-radius: 10px;
    margin-top: 1%;
    margin-left: 2%;
    font-family: Montserrat, sans-serif;
    color: #58585a;
}

.show-details_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    padding: 5px;
    margin: 0;
    border-radius: 6px 6px 0 0;
    background-color: #ffffff;
    font-size: 12px;
    height: fit-content;
    border-bottom: 1px solid #58585a;
}

.show-details_header_text {
    text-align: center;
    padding: 5px;
    margin: 0;
    border-radius: 6px;
    background-color: #ffffff;
    font-size: 15px;
}

.dark_grey_strip {
    width: 100%;
    background-color: #58585a;
    height: 6px;
}

.show-details_info_section {
    width: 95%;
}
`, "",{"version":3,"sources":["webpack://./src/components/modals/edit/screen/show_details/ShowDetails.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,gBAAgB;IAChB,gBAAgB;IAChB,mBAAmB;IACnB,yBAAyB;IACzB,kBAAkB;IAClB,yBAAyB;IACzB,mBAAmB;IACnB,cAAc;IACd,eAAe;IACf,mCAAmC;IACnC,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,kBAAkB;IAClB,YAAY;IACZ,SAAS;IACT,0BAA0B;IAC1B,yBAAyB;IACzB,eAAe;IACf,mBAAmB;IACnB,gCAAgC;AACpC;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,SAAS;IACT,kBAAkB;IAClB,yBAAyB;IACzB,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,yBAAyB;IACzB,WAAW;AACf;;AAEA;IACI,UAAU;AACd","sourcesContent":[".show-details {\n    width: 20%;\n    max-width: 450px;\n    min-width: 200px;\n    height: fit-content;\n    border: 2px solid #58585a;\n    position: relative;\n    background-color: #eceded;\n    border-radius: 10px;\n    margin-top: 1%;\n    margin-left: 2%;\n    font-family: Montserrat, sans-serif;\n    color: #58585a;\n}\n\n.show-details_header {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    text-align: center;\n    padding: 5px;\n    margin: 0;\n    border-radius: 6px 6px 0 0;\n    background-color: #ffffff;\n    font-size: 12px;\n    height: fit-content;\n    border-bottom: 1px solid #58585a;\n}\n\n.show-details_header_text {\n    text-align: center;\n    padding: 5px;\n    margin: 0;\n    border-radius: 6px;\n    background-color: #ffffff;\n    font-size: 15px;\n}\n\n.dark_grey_strip {\n    width: 100%;\n    background-color: #58585a;\n    height: 6px;\n}\n\n.show-details_info_section {\n    width: 95%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
