// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.date-range-picker-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 10px;
    z-index: 20;
}

.date-range-picker-container .cell {
    flex: 1 1 100%;
}

.filter-component {
    flex: 1 1;
    min-width: 200px;
}

.date-range-picker-container .rs-picker {
    width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/specimens/mro_screens/filters/MroSpecimensFilters.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,SAAS;IACT,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,SAAO;IACP,gBAAgB;AACpB;;AAEA;IACI,WAAW;AACf","sourcesContent":[".date-range-picker-container {\n    display: flex;\n    flex-wrap: wrap;\n    gap: 10px;\n    margin-bottom: 10px;\n    z-index: 20;\n}\n\n.date-range-picker-container .cell {\n    flex: 1 1 100%;\n}\n\n.filter-component {\n    flex: 1;\n    min-width: 200px;\n}\n\n.date-range-picker-container .rs-picker {\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
