// freddy-react/src/assets/icons/index.js

import EmailIcon from './Email.svg';
import NoteIcon from './Note.svg';
import SendIcon from './Send.svg';
import ReportCheckIcon from './ReportCheck.svg';
import DownloadIcon from './Download.svg';
import BoxIcon from './Box.svg';
import RejectIcon from './Reject.svg';
import CheckIcon from './Check.svg';
import LockIcon from './Lock.svg';
import LaboratoryIcon from './Laboratory.svg';
import PersonIcon from './Person.svg';
import SaveIcon from './Save.svg';
import SavedIcon from './Saved.svg';
import ActionTrayIcon from './ActionTray.svg';
import AutomationIcon from './Automation.svg';
import UploadIcon from './Upload.svg';
import ScanIcon from './Scan.svg';
import CloseIcon from './Close.svg';
import ExitIcon from './Exit.svg';
import EditIcon from './Edit.svg';
import LogoutIcon from './Logout.svg';
import ClientIcon from './Client.svg';
import CartIcon from './Cart.svg';
import SpeicmenRapidCupIcon from './SpeicmenRapidCup.svg';
import ExpandIcon from './Expand.svg';
import ContractIcon from './Contract.svg';
import ReportIcon from './Report.svg';
import BellIcon from './Bell.svg';
import BillingDownloadIcon from './BillingDownload.svg';
import DollarSignIcon from './DollarSign.svg';
import BillingDownloadActionIcon from './BillingDownloadAction.svg';
import CreateInvoiceIcon from './CreateInvoice.svg';
import SendInvoiceIcon from './SendInvoice.svg';
import DownloadInvoiceIcon from './DownloadInvoice.svg';
import BillingInvoicesIcon from './BillingInvoices.svg';
import BadgeIcon from './Badge.svg';
import PanelsIcon from './Panel.svg';
import ShopifyIcon from './ShopifyGlyph.svg';


// Group for RecentLabScreens
export const RecentLabScreensIcons = {
    EmailIcon,
    NoteIcon,
    SendIcon,
    ReportCheckIcon,
    DownloadIcon,
    BoxIcon,
    RejectIcon,
    CheckIcon,
    LockIcon,
    PersonIcon,
    SaveIcon,
    SavedIcon,
};

export const SpecimenFilterIcons = {
    BillingDownloadIcon,
    DownloadIcon,
    BillingDownloadActionIcon,
};

// Group for Header
export const HeaderIcons = {
    SpeicmenRapidCupIcon,
    LaboratoryIcon,
    AutomationIcon,
    UploadIcon,
    ClientIcon,
    ScanIcon,
    LogoutIcon,
    PersonIcon,
    BellIcon,
    EmailIcon,
    DollarSignIcon,
    BoxIcon,
    BillingInvoicesIcon,
    PanelsIcon,
    CartIcon,
    ShopifyIcon,
};

// Group for Action Tray Icons
export const ActionTrayIcons = {
    ActionTrayIcon,
    RejectIcon,
    LaboratoryIcon,
    EmailIcon,
    NoteIcon,
    SendIcon,
    DownloadIcon,
    EditIcon,
    ReportIcon,
    BadgeIcon,
    DollarSignIcon,
    PersonIcon
};

// Group for Action Tray Icons
export const GenericIcons = {
    CloseIcon,
    ExitIcon,
    EditIcon,
    ExpandIcon,
    ContractIcon,
    CheckIcon,
    DownloadIcon,
    DollarSignIcon,
    ReportIcon,
};


// Group for create Screener Icons
export const SaveIcons = {
    SaveIcon,
    SavedIcon,
};

// Group for create Screener Icons
export const LaboratoryAccountsIcons = {
    DownloadIcon,
};

export const InvoiceIcons = {
    CreateInvoiceIcon,
    DownloadInvoiceIcon,
    SendInvoiceIcon
};


export const PricingIcons = {
    SaveIcon,
}
