// src/pages/emails/EmailPage.js

import React, { useEffect } from "react";
import "./EmailPage.css"; // Ensure to create a corresponding CSS file
import Header from "../../components/header/Header";
// import Footer from "../../components/footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { getEmails } from "../../store/emails/emailsSlice";
import { showMessageModal } from "../../store/modals/modalsSlice";


const EmailPage = ({ emails }) => {
    const dispatch = useDispatch();
    const emailStatus = useSelector(state => state.emails.email_message_status_id);
    const emailError = useSelector(state => state.emails.error);

    useEffect(() => {
        if (emailStatus === 'failed') {
            dispatch(showMessageModal({ message: emailError, link: null }));
        }
    }, [emailStatus, dispatch]);


    return (
        <div className="email-page-container">
            <div className="email-header-container">
                <Header />
            </div>
            <div className="email-body-container">
                <div className="email-content-container">

                </div>
            </div>
            {/*<Footer />*/}
        </div>
    );
};

export default EmailPage;
