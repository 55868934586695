// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.order-detail-ship-address-options-container {
    margin-bottom: 20px;
}

.filter-select {
    width: 100%;
}

.ship-options-rates-container {
    margin-top: 10px;
}

.ship-options-rates-container div {
    background: #f9f9f9;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}
`, "",{"version":3,"sources":["webpack://./src/components/modals/order_detail/ship_selection/ShipSelection.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;IACnB,aAAa;IACb,kBAAkB;IAClB,wCAAwC;AAC5C","sourcesContent":[".order-detail-ship-address-options-container {\n    margin-bottom: 20px;\n}\n\n.filter-select {\n    width: 100%;\n}\n\n.ship-options-rates-container {\n    margin-top: 10px;\n}\n\n.ship-options-rates-container div {\n    background: #f9f9f9;\n    padding: 10px;\n    border-radius: 4px;\n    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
