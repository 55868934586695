import React, { useEffect, useState } from 'react';
import "./PanelDetails.css"
import { useSelector, useDispatch } from 'react-redux';
import { getClientBillingDetails } from "../../../store/clients/clientBillingDetailsSlice";
import { fetchPanelsByLabbThunk } from "../../../store/panels/panelsSlice";

const PanelDetails = () => {
    const dispatch = useDispatch();
    const [combinedPanelDetails, setCombinedPanelDetails] = useState([]);
    const panelDetails = useSelector(state => state.panels?.panels);
    const clientPanelPricing = useSelector(state => state.clientBillingDetails.clientBillingDetailsData);
    const clientId = useSelector(state => state.shopifyUser?.lssCollectorDetails?.client_id);

    useEffect(() => {
        if (clientId) {
            dispatch(getClientBillingDetails({ clientId }));
        }
    }, [dispatch, clientId]);

    useEffect(() => {
        dispatch(fetchPanelsByLabbThunk());
    }, [dispatch]);

    useEffect(() => {
        if (Array.isArray(clientPanelPricing) && Array.isArray(panelDetails)) {
            const combinedDetails = clientPanelPricing.map(clientPanel => {
                return {
                    ...clientPanel
                };
            });
            setCombinedPanelDetails(combinedDetails);
        }
    }, [clientPanelPricing, panelDetails]);


    return (
        <div>
            {combinedPanelDetails.length > 0 ? (
                <table>
                    <thead>
                    <tr>
                        <th>Panel Name</th>
                        <th>Panel Type</th>
                        <th>Panel Price</th>
                    </tr>
                    </thead>
                    <tbody>
                    {combinedPanelDetails.map((panel, index) => (
                        <tr key={index}>
                            <td>{panel.service_name}</td>
                            <td>{panel.service_panel_code}</td>
                            <td>{panel.client_price}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            ) : (
                <p>{!clientPanelPricing || !panelDetails ? 'Loading...' : 'No panel details found.'}</p>
            )}
        </div>
    );
};

export default PanelDetails;
