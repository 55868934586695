import React, { useState, useEffect } from 'react';
import './Panels.css';
import Select from 'react-select';
import { GenericIcons as GenericIcon } from "../../assets/icons";
import { IconButton } from "../buttons/icon_button/IconButton";
import PanelTile from "./panel_tile/PanelTile";
import Masonry from 'react-masonry-css';
import drugNameMapping from '../../assets/csv/DrugMapping';  // Import the drug name mapping

const Panels = ({ data }) => {
    const [filteredData, setFilteredData] = useState({});
    const [selectedDrug, setSelectedDrug] = useState(null);
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [availableDrugs, setAvailableDrugs] = useState([]);
    const [selectedPanelCounts, setSelectedPanelCounts] = useState([]);
    const [panelCounts, setPanelCounts] = useState([]);
    const [isFilterExpanded, setIsFilterExpanded] = useState(false);

    useEffect(() => {
        setFilteredData(data);
        updateAvailableDrugs(data);
        const panelCountMap = generatePanelCounts(data);
        setPanelCounts(panelCountMap);
    }, [data]);

    useEffect(() => {
        applyFilters();
    }, [selectedDrug, selectedFilters, selectedPanelCounts]);

    const updateAvailableDrugs = (data) => {
        const drugNamesSet = new Set();

        // Collect all unique drug names from the backend and trim them
        Object.values(data).forEach(group => {
            group.components.forEach(component => {
                let drugName = component["Test Code Name"]?.trim(); // Trim the drug name
                if (drugName) {
                    let masterFound = false;

                    // Check if this drug name is mapped to a master name
                    for (const [masterName, equivalentNames] of Object.entries(drugNameMapping)) {
                        if (equivalentNames.map(name => name.trim()).includes(drugName)) {  // Trim in comparison
                            drugNamesSet.add(masterName.trim());  // Add the trimmed master name
                            masterFound = true;
                            break;
                        }
                    }

                    if (!masterFound) {
                        drugNamesSet.add(drugName);  // Add the unique trimmed non-master drug name
                    }
                }
            });
        });

        // Filter out drugs that are already selected and remove duplicates
        const uniqueDrugs = Array.from(drugNamesSet)
            .filter(drug => !selectedFilters.some(filter => filter.drug.trim() === drug));

        // Convert the unique drugs set to an array for react-select
        const availableDrugsList = uniqueDrugs.map(value => ({ value, label: value }));

        setAvailableDrugs(availableDrugsList);
    };

    const generatePanelCounts = (data) => {
        const counts = {};
        Object.values(data).forEach(group => {
            const length = group.components.length;
            counts[length] = (counts[length] || 0) + 1;
        });
        return Object.keys(counts).map(count => parseInt(count));
    };

    const applyFilters = () => {
        let filtered = data;
        const excludedNames = ["Ram Test", "Remark Test", "Other Test"];

        const tcTypeOrder = {
            'Screening Test': 1,
            'Confirmation Test': 2,
            'Quant Result': 3,
            'Adulterant': 4,
            'Adulterant Required': 5,
            'Remark Test': 6,
            'Ram Test': 7,
            'Other Test': 8,
        };

        const filterAndSortComponents = (components) => {
            return components
                .filter(component => !excludedNames.includes(component["TC Type"]))
                .sort((a, b) => (tcTypeOrder[a["TC Type"]] || 9) - (tcTypeOrder[b["TC Type"]] || 9));
        };

        if (selectedFilters.length > 0) {
            filtered = Object.keys(filtered).reduce((acc, key) => {
                const serviceGroup = filtered[key];
                const matchesFilters = selectedFilters.every(filter => {
                    const equivalentNames = (drugNameMapping[filter.drug.trim()] || [filter.drug.trim()])
                        .map(name => name.trim());
                    return serviceGroup.components.some(component =>
                        equivalentNames.includes(component["Test Code Name"]?.trim()) ||
                        component["Test Code Name"]?.trim() === filter.drug.trim()
                    );
                });
                if (matchesFilters) {
                    acc[key] = {
                        ...serviceGroup,
                        components: filterAndSortComponents(serviceGroup.components)
                    };
                }
                return acc;
            }, {});
        }

        if (selectedPanelCounts.length > 0) {
            filtered = Object.keys(filtered).reduce((acc, key) => {
                const serviceGroup = filtered[key];
                const filteredComponents = filterAndSortComponents(serviceGroup.components);
                if (selectedPanelCounts.includes(filteredComponents.length)) {
                    acc[key] = {
                        ...serviceGroup,
                        components: filteredComponents
                    };
                }
                return acc;
            }, {});
        }

        setFilteredData(filtered);
    };

    const handleDrugChange = (selectedOption) => {
        if (selectedOption) {
            const newFilter = { drug: selectedOption.value.trim() };
            setSelectedFilters(prevFilters => [...prevFilters, newFilter]);
            setSelectedDrug(null); // Clear the selected drug
        }
    };

    const handlePanelCountChange = (count) => {
        setSelectedPanelCounts(prevCounts =>
            prevCounts.includes(count)
                ? prevCounts.filter(c => c !== count)
                : [...prevCounts, count]
        );
    };

    const removeFilter = (index) => {
        const removedFilter = selectedFilters[index];
        const newFilters = [...selectedFilters];
        newFilters.splice(index, 1);
        setSelectedFilters(newFilters);

        // Re-add the removed filter's drug to the available drugs list
        setAvailableDrugs(prevDrugs => [...prevDrugs, { value: removedFilter.drug, label: removedFilter.drug }]);
    };

    const toggleFilterExpansion = () => {
        setIsFilterExpanded(!isFilterExpanded);
    };

    const breakpointColumnsObj = {
        default: 3,
        1200: 2,
        800: 1
    };

    return (
        <div className="panels-container">
            <div className="panels-container-header">
                <div className="filter-and-legend">
                    <div className="filters-section">
                        <div className="metabolite-selection-section">
                            <div className="search-bar-panels">
                                <Select
                                    options={availableDrugs}
                                    onChange={handleDrugChange}
                                    className="filter-select-panels"
                                    placeholder="Select Drugs..."
                                    value={selectedDrug}
                                    closeMenuOnSelect={false}
                                />
                            </div>
                            <div className="selected-filters-panels">
                                {selectedFilters.map((filter, index) => (
                                    <div key={index} className="selected-filter-panels">
                                        <span>{filter.drug}</span>
                                        <IconButton
                                            className="dropdown-clear-button-panels"
                                            onClick={() => removeFilter(index)}
                                            defaultIcon={GenericIcon.CloseIcon}
                                            hoverIcon={GenericIcon.ExitIcon}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="panels-legend-section">
                        <div className="panel-tile-tiles legend-tile" style={{width: '100%'}}>
                            <div className="panel-header-tiles">
                                <h3>Panel Name</h3>
                                <p className="panel-code-tiles">Panel Code</p>
                            </div>
                            <div className="panel-detail-tiles">
                                <div className="component-details-panel-tiles">
                                    <div className="component-item-panel-tiles">Metabolite Name</div>
                                    <div className="component-item-details-tiles">
                                        <div className="component-item-panel-tiles">Screening Cutoff</div>
                                        <div className="component-item-panel-tiles">Confirmation Cutoff</div>
                                        <div className="component-item-panel-tiles">Specimen Type</div>
                                    </div>
                                    <div className="component-item-panel-tiles">
                                        <div className={`tc-type-bubble tc-type-confirmation-test`}>
                                            Test Type
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Masonry
                breakpointCols={breakpointColumnsObj}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column"
            >
                {Object.values(filteredData).map((group, index) => (
                    <PanelTile key={index} group={group} />
                ))}
            </Masonry>
        </div>
    );
};

export default Panels;
