import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    questAccountData: [],
    isModalVisible: false,
};

const questAccountsSlice = createSlice({
    name: 'questAccounts/questAccountData',
    initialState,
    reducers: {
        setQuestAccounts: (state, action) => {
            state.questAccountData = action.payload;
        },
        appendQuestAccounts: (state, action) => {
            state.questAccountData = [...state.questAccountData, ...action.payload];
        },
        showQuestAccountsModal: (state) => {
            state.isModalVisible = true;
        },
        hideQuestAccountsModal: (state) => {
            state.questAccountData = []
            state.isModalVisible = false;
        },
        approveQuestAccount: (state, action) => {
            const { client_id } = action.payload;
            const index = state.questAccountData.findIndex(questAccount => questAccount.labb_client_id === client_id);
            if (index !== -1) {
                state.questAccountData[index].is_draft = false;
            }
        },
        approveQuestSubAccount: (state, action) => {
            const { organization_id } = action.payload;
            const index = state.questAccountData.findIndex(questAccount => questAccount.ss_organization_id === organization_id);
            if (index !== -1) {
                state.questAccountData[index].is_draft = false;
            }
        },
    },
});

export const {
    approveQuestAccount,
    approveQuestSubAccount,
    appendQuestAccounts,
    setQuestAccounts,
    showQuestAccountsModal,
    hideQuestAccountsModal
} = questAccountsSlice.actions;

export default questAccountsSlice.reducer;
