// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.client {
    width: 15%;
    max-width: 650px;
    min-width: 200px;
    height: fit-content;
    border: 2px solid #58585a;
    position: relative;
    background-color: #eceded;
    border-radius: 10px;
    margin-top: 1%;
    margin-left: 2%;
    font-family: Montserrat, sans-serif;
    color: #58585a;
}

.client_header {
    text-align: center;
    padding: 5px;
    margin: 0;
    border-radius: 6px 6px 0 0;
    background-color: #ffffff;
    font-size: 12px;
    height: fit-content;
    border-bottom: 1px solid #58585a;
}

.dark_grey_strip {
    width: 100%;
    background-color: #58585a;
    height: 6px;
}

.client_header_text {
    text-align: center;
    padding: 5px;
    margin: 0;
    border-radius: 6px;
    background-color: #ffffff;
    font-size: 12px;
}

.client_info_text {
    text-align: right;
    margin: 0;
    font-size: 12px;
}

.client_info_text_input {
    text-align: right;
    margin: 0;
    font-size: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/components/specimens/screen/labb_client/LabbClient.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,gBAAgB;IAChB,gBAAgB;IAChB,mBAAmB;IACnB,yBAAyB;IACzB,kBAAkB;IAClB,yBAAyB;IACzB,mBAAmB;IACnB,cAAc;IACd,eAAe;IACf,mCAAmC;IACnC,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,SAAS;IACT,0BAA0B;IAC1B,yBAAyB;IACzB,eAAe;IACf,mBAAmB;IACnB,gCAAgC;AACpC;;AAEA;IACI,WAAW;IACX,yBAAyB;IACzB,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,SAAS;IACT,kBAAkB;IAClB,yBAAyB;IACzB,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,SAAS;IACT,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,SAAS;IACT,eAAe;AACnB","sourcesContent":[".client {\n    width: 15%;\n    max-width: 650px;\n    min-width: 200px;\n    height: fit-content;\n    border: 2px solid #58585a;\n    position: relative;\n    background-color: #eceded;\n    border-radius: 10px;\n    margin-top: 1%;\n    margin-left: 2%;\n    font-family: Montserrat, sans-serif;\n    color: #58585a;\n}\n\n.client_header {\n    text-align: center;\n    padding: 5px;\n    margin: 0;\n    border-radius: 6px 6px 0 0;\n    background-color: #ffffff;\n    font-size: 12px;\n    height: fit-content;\n    border-bottom: 1px solid #58585a;\n}\n\n.dark_grey_strip {\n    width: 100%;\n    background-color: #58585a;\n    height: 6px;\n}\n\n.client_header_text {\n    text-align: center;\n    padding: 5px;\n    margin: 0;\n    border-radius: 6px;\n    background-color: #ffffff;\n    font-size: 12px;\n}\n\n.client_info_text {\n    text-align: right;\n    margin: 0;\n    font-size: 12px;\n}\n\n.client_info_text_input {\n    text-align: right;\n    margin: 0;\n    font-size: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
