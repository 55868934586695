// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
/* ClientPricingModel.css */
.pricing-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
}

.pricing-modal {
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    width: 80%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.pricing-modal-header {
    display: flex;
    justify-content: flex-end;
}

.pricing-modal-content {
    overflow-y: auto;
    max-height: 500px;
}

.pricing-detail {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #ccc;
}
`, "",{"version":3,"sources":["webpack://./src/components/modals/client_pricing/ClientPricing.css"],"names":[],"mappings":";AACA,2BAA2B;AAC3B;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,oCAAoC;IACpC,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,cAAc;AAClB;;AAEA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,aAAa;IACb,UAAU;IACV,uCAAuC;AAC3C;;AAEA;IACI,aAAa;IACb,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,aAAa;IACb,6BAA6B;AACjC","sourcesContent":["\n/* ClientPricingModel.css */\n.pricing-modal-overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: rgba(0, 0, 0, 0.5);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 10000;\n}\n\n.pricing-modal {\n    background-color: #fff;\n    border-radius: 8px;\n    padding: 20px;\n    width: 80%;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);\n}\n\n.pricing-modal-header {\n    display: flex;\n    justify-content: flex-end;\n}\n\n.pricing-modal-content {\n    overflow-y: auto;\n    max-height: 500px;\n}\n\n.pricing-detail {\n    display: flex;\n    justify-content: space-between;\n    padding: 10px;\n    border-bottom: 1px solid #ccc;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
