// freddy-react/src/store/products/lssProductsSlice.js

import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {createShopifyProducts, fetchShopifyProducts} from "../../services/api/v1/productsService";


export const getShopifyProducts = createAsyncThunk(
    'shopifyProducts/getShopifyProducts',
    async ({ accessToken, shopifyAdminAccessToken }, thunkAPI) => {
        return await fetchShopifyProducts({accessToken, shopifyAdminAccessToken});
    }
);

export const createShopifyServices = createAsyncThunk(
    'shopifyProducts/createShopifyServices',
    async ({ accessToken, shopifyAdminAccessToken, selectedPanels }, thunkAPI) => {
        const panelsData = selectedPanels.map((panel) => {
            const compendium = panel.compendium || {};
            return {
                service_panel_id: panel.service_panel_id,  // still access this from panel
                panel_name: compendium["Req Name"] || 'Unknown Name',
                mnemonic: compendium["Mnemonic"] || 'Unknown Mnemonic'
            };
        });

        const formData = new FormData();
        formData.append('panels', JSON.stringify(panelsData));  // Append panels as a JSON string
        formData.append('shopify_oauth_token', shopifyAdminAccessToken);

        // Call the API to create Shopify products
        return await createShopifyProducts({ accessToken, formData });
    }
);


export const shopifyProductsSlice = createSlice({
    name: 'shopifyProducts',
    initialState: {
        allShopifyProducts: [],
        isLoading: false,
    },
    reducers: {
        setShopifyProducts: (state, action) => {
            state.allShopifyProducts = action.payload;
            state.isLoading = false;
        },
        clearShopifyProducts: (state) => {
            state.allShopifyProducts = [];
            state.isLoading = false;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getShopifyProducts.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getShopifyProducts.fulfilled, (state, action) => {
                state.allShopifyProducts = action.payload;
                state.isLoading = false;
            })
            .addCase(getShopifyProducts.rejected, (state, action) => {
                state.isLoading = false;
            });
    }
});



export const { setShopifyProducts, clearShopifyProducts } = shopifyProductsSlice.actions;


export default shopifyProductsSlice.reducer;


