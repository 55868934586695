import React from 'react';
import "./SpecimenBilling.css";
import SpecimensTable from "../screens/SpecimensTable";

function SpecimenBilling({ userRoles, screensArray, onEdit }) {

    return (
        <div className="specimens-container">
            <div className="specimens-table-container">
                <SpecimensTable userRoles={userRoles} screens={screensArray} onEdit={onEdit} />
            </div>
        </div>
    );
}

export default SpecimenBilling;