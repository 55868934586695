// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.organization-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid #e1e1e1;
    border-radius: 5px;
    overflow: hidden;
}

.organization-header, .organization-row {
    display: grid;
    grid-template-columns: 1fr repeat(7, 100px);
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #e1e1e1;
}

.organization-header {
    background-color: #f5f5f5;
    font-weight: bold;
}

input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: #ffffff;
    border: 1px solid #dcdcdc;
    width: 20px;
    height: 20px;
    cursor: not-allowed; /* Since checkboxes are disabled */
}
`, "",{"version":3,"sources":["webpack://./src/components/autobot/review/organizations/Organizations.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,WAAW;IACX,yBAAyB;IACzB,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,2CAA2C;IAC3C,mBAAmB;IACnB,aAAa;IACb,gCAAgC;AACpC;;AAEA;IACI,yBAAyB;IACzB,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;IAChB,wBAAwB;IACxB,qBAAqB;IACrB,yBAAyB;IACzB,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,mBAAmB,EAAE,kCAAkC;AAC3D","sourcesContent":[".organization-container {\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n    border: 1px solid #e1e1e1;\n    border-radius: 5px;\n    overflow: hidden;\n}\n\n.organization-header, .organization-row {\n    display: grid;\n    grid-template-columns: 1fr repeat(7, 100px);\n    align-items: center;\n    padding: 10px;\n    border-bottom: 1px solid #e1e1e1;\n}\n\n.organization-header {\n    background-color: #f5f5f5;\n    font-weight: bold;\n}\n\ninput[type=\"checkbox\"] {\n    appearance: none;\n    -webkit-appearance: none;\n    -moz-appearance: none;\n    background-color: #ffffff;\n    border: 1px solid #dcdcdc;\n    width: 20px;\n    height: 20px;\n    cursor: not-allowed; /* Since checkboxes are disabled */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
