// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.specimens-filter-input {
    margin-bottom: 15px;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    transition: border-color 0.3s ease-in-out;
}

.specimens-filter-input:focus {
    border-color: #007bff; /* Adjust to match your app's primary color */
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.2); /* Subtle focus shadow */
}

.mro-specimens-container {
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional box shadow for the container */
}

.scroll-container {
    overflow-y: auto;
    max-height: 90vh;
}

table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
}

th, td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    word-wrap: break-word; /* Ensures long text wraps in table cells */
}

thead th {
    background-color: #f5f5f5;
    position: sticky;
    top: 0;
    z-index: 10;
}

tr:nth-child(odd) {
    background-color: #f9f9f9;
}

tr:nth-child(even) {
    background-color: #ffffff;
}`, "",{"version":3,"sources":["webpack://./src/components/specimens/mro_screens/mro_specimens/MroSpecimens.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,aAAa;IACb,WAAW;IACX,sBAAsB;IACtB,eAAe;IACf,sBAAsB;IACtB,kBAAkB;IAClB,aAAa;IACb,yCAAyC;AAC7C;;AAEA;IACI,qBAAqB,EAAE,6CAA6C;IACpE,0CAA0C,EAAE,wBAAwB;AACxE;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,wCAAwC,EAAE,0CAA0C;AACxF;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,yBAAyB;IACzB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,gBAAgB;IAChB,6BAA6B;IAC7B,qBAAqB,EAAE,2CAA2C;AACtE;;AAEA;IACI,yBAAyB;IACzB,gBAAgB;IAChB,MAAM;IACN,WAAW;AACf;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".specimens-filter-input {\n    margin-bottom: 15px;\n    padding: 10px;\n    width: 100%;\n    box-sizing: border-box;\n    font-size: 16px;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n    outline: none;\n    transition: border-color 0.3s ease-in-out;\n}\n\n.specimens-filter-input:focus {\n    border-color: #007bff; /* Adjust to match your app's primary color */\n    box-shadow: 0 0 5px rgba(0, 123, 255, 0.2); /* Subtle focus shadow */\n}\n\n.mro-specimens-container {\n    padding: 20px;\n    background-color: #fff;\n    border-radius: 8px;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional box shadow for the container */\n}\n\n.scroll-container {\n    overflow-y: auto;\n    max-height: 90vh;\n}\n\ntable {\n    width: 100%;\n    border-collapse: collapse;\n    table-layout: fixed;\n}\n\nth, td {\n    padding: 12px;\n    text-align: left;\n    border-bottom: 1px solid #ddd;\n    word-wrap: break-word; /* Ensures long text wraps in table cells */\n}\n\nthead th {\n    background-color: #f5f5f5;\n    position: sticky;\n    top: 0;\n    z-index: 10;\n}\n\ntr:nth-child(odd) {\n    background-color: #f9f9f9;\n}\n\ntr:nth-child(even) {\n    background-color: #ffffff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
