// BillingSpecimenTableHeader.js

const BillingSpecimenTableHeader = () => {
    return (
        <thead>
        <tr>
            <th>Invoice Number</th>
            <th>Device ID</th>
            <th>Collected On</th>
            <th>Product Name</th>
            <th>Retail Price</th>
            <th>Client Name</th>
            <th>Location Name</th>
        </tr>
        </thead>
    );
}

export default BillingSpecimenTableHeader;
