import React, { useEffect, useState, useRef } from "react";
import "./Billing.css";
import Specimens from "../../components/specimens/Specimens";
import { convertSpecimenIdToHash } from "../../services/api/v1/screenService";
import { clearScreenData, getScreenDetails } from "../../store/screen/screenSlice";
import { useDispatch, useSelector } from "react-redux";
import { clearOrganization } from "../../store/autobot/clients/organizations/organizationsSlice";
import ScreenModal from "../../components/modals/edit/screen/ScreenModal";
import { getLaboratoryBillingExport, getLaboratoryBillingRecords } from "../../store/laboratory/laboratorySlice";
import { getUserRolesFromToken } from "../../helpers/token/processToken";
import { setLoading } from "../../store/clients/clientsSlice";
import { getScreens } from "../../store/screens/screensSlice";
import BillingSpecimenTable from "../../components/billing/BillingSpecimenTable";
import SpecimenFilters from "../../components/specimens/specimen_filters/SpecimenFilters";
import { ActionTrayIcons, InvoiceIcons } from "../../assets/icons";
import { createBillingSpecimenInvoices } from "../../store/clients/clientBillingDetailsSlice";
import DownloadInvoiceIcon from "../../assets/icons/DownloadInvoice.svg";
import { showMessageModal } from "../../store/modals/modalsSlice";
import AdminHeader from "../../components/headers/admin_header/AdminHeader";

const BillingPage = () => {
    const dispatch = useDispatch();
    const dateRange = useSelector(state => state.filters.dateRange);
    let zohoAccessToken = useSelector(state => state.zohoAuth.zohoAuth.access_token);
    const [records, setRecords] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [screenParams, setScreenParams] = useState({ offset: 0, limit: 20, filters: {} });
    const userRoles = getUserRolesFromToken(sessionStorage.getItem("accessToken"));
    const [isEditing, setIsEditing] = useState(false);
    const [currentSpecimen, setCurrentSpecimen] = useState(null);
    const laboratoryRecords = useSelector(state => state.laboratory.records);
    const laboratoryExportStatus = useSelector(state => state.laboratory.exportStatus);
    const [polling, setPolling] = useState(false);
    const [exportJobId, setExportJobId] = useState(null);
    const pollingRef = useRef(null);


    if (zohoAccessToken === null) {
        zohoAccessToken = sessionStorage.getItem("zohoAccessToken");
    }
    
    
    useEffect(() => {
        if (laboratoryRecords.status === 'failed') {
            dispatch(showMessageModal({ message: laboratoryRecords.error, link: null }));
        }
    }, [laboratoryRecords.status, dispatch]);

    // Poll for the export status
    useEffect(() => {
        if (polling && exportJobId) {
            pollingRef.current = setInterval(async () => {
                const response = await dispatch(getLaboratoryBillingRecords({ jobId: exportJobId }));
                if (response.payload.status === 'completed') {
                    clearInterval(pollingRef.current);
                    setPolling(false);
                    downloadBillingSummary(response.payload.result);
                } else if (response.payload.status === 'failed') {
                    clearInterval(pollingRef.current);
                    setPolling(false);
                    dispatch(showMessageModal({ message: "Failed to generate export.", link: null }));
                }
            }, 10000); // poll every 10 seconds
        }

        return () => {
            if (pollingRef.current) {
                clearInterval(pollingRef.current);
                pollingRef.current = null;
            }
        };
    }, [polling, exportJobId, dispatch]);

    const invoiceData = useSelector((state) => state.clientBillingDetails.clientBillingDetailsData);

    function downloadBillingSummary(billingSummaryUrl) {
        if (billingSummaryUrl) {
            try {
                const link = document.createElement("a");
                link.href = billingSummaryUrl;
                link.download = 'Billing-Summary.csv'; // You can provide a dynamic filename if needed
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                dispatch(showMessageModal({ message: "Download initiated successfully.", link: link }));
            } catch (error) {
                dispatch(showMessageModal({ message: "Failed to initiate download.", link: null }));
            }
        }
    }

    const handleExportInvoicingClick = () => {
        const date_range = dateRange ? dateRange : null;

        dispatch(getLaboratoryBillingExport({
            offset: 0, limit: 20, filters: { date_range }
        })).then((action) => {
            if (action.type === getLaboratoryBillingExport.fulfilled.toString() && action.payload) {
                setExportJobId(action.payload.job_id);
                setPolling(true);
                dispatch(showMessageModal({ message: "Started export process. Polling for status.", link: null }));
            } else {
                dispatch(showMessageModal({ message: "Failed to initiate export process.", link: null }));
            }
        });
    }

    const today = new Date();
    const tenDaysAgo = new Date();
    tenDaysAgo.setDate(tenDaysAgo.getDate() - 10);

    const defaultScreenParams = {
        offset: 0,
        limit: 20,
        filters: {
            date_range: {
                start_date: tenDaysAgo.toISOString().slice(0, 10),
                end_date: today.toISOString().slice(0, 10)
            }
        }
    }
    useEffect(() => {
        dispatch(getLaboratoryBillingRecords(defaultScreenParams));
    }, [dispatch, screenParams]);

    const handleEdit = async (specimenData) => {
        try {
            const accessToken = sessionStorage.getItem("accessToken");

            // parse SD0 away from the front of specimenData.device_id
            const sd0Regex = /^SD0/;
            const parsedSpecimenId = specimenData.device_id.replace(sd0Regex, '');

            const deviceData = {
                device_id: parsedSpecimenId
            };

            const response = await convertSpecimenIdToHash(deviceData, accessToken);
            const updatedSpecimenData = {
                ...specimenData,
                device_id_hash: response
            };

            if (updatedSpecimenData.device_id_hash) {
                dispatch(clearOrganization());
                dispatch(clearScreenData());
                setCurrentSpecimen(prevSpecimen => updatedSpecimenData);
                setIsModalOpen(true);
                setIsEditing(true);
            }
        } catch (error) {
            console.error("An error occurred while converting specimen_id to device_id_hash:", error);
        }
    };

    const onFilterChange = (filterName, value) => {
        setScreenParams(prevParams => {
            const updatedParams = {
                ...prevParams,
                offset: 0,
                filters: {
                    ...prevParams.filters,
                    [filterName]: value
                }
            };

            dispatch(getLaboratoryBillingRecords(updatedParams));

            return updatedParams;
        });
    };

    const handleCreateInvoicesClick = () => {
        dispatch(createBillingSpecimenInvoices({zohoAccessToken}));
        dispatch(showMessageModal({ message: "Started Invoice Creation", link: null }));
    };

    const downloadIcon = {
        defaultIcon: InvoiceIcons.CreateInvoiceIcon,
        hoverIcon: InvoiceIcons.CreateInvoiceIcon,
    };

    const iconsData = [
        {
            icon: InvoiceIcons.CreateInvoiceIcon,
            alt: "Create Invoices",
            action: handleCreateInvoicesClick,
            permission: "Zoho.Books.Invoicing.Create"
        },
        {
            icon: InvoiceIcons.DownloadInvoiceIcon,
            alt: "Account Details",
            action: handleExportInvoicingClick,
            permission: "Zoho.Books.Invoicing.Export"
        },
    ];

    return (
        <div className="billing-page-container">
            <section>
                <AdminHeader />
            </section>

            <section className="billing-actions">
                <SpecimenFilters
                    onApplyFilters={onFilterChange}
                    iconsData={iconsData}
                />
            </section>
            <section className="billing-section lab-section">
                <div className="screens-content-container">
                    <Specimens
                        userRoles={userRoles}
                        screensArray={laboratoryRecords}
                        onEdit={handleEdit}
                        onFilterChange={onFilterChange}
                    />
                </div>
            </section>
        </div>
    );
};

export default BillingPage;
