// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Assuming this is in Billing.css or a similar stylesheet */

.specimen-row td {
    padding: 8px;
    border-bottom: 1px solid #ddd; /* Light grey line for separation */
    text-align: left;
}

/* Styling for the table header for consistency */
thead th {
    background-color: #f4f4f4; /* Light grey background */
    color: #333; /* Dark grey text */
    padding: 10px;
    text-align: left;
}

/* Add more styles as needed for alignment, font sizes, etc. */
`, "",{"version":3,"sources":["webpack://./src/components/billing/billing_client_section/BillingClientSection.css"],"names":[],"mappings":"AAAA,4DAA4D;;AAE5D;IACI,YAAY;IACZ,6BAA6B,EAAE,mCAAmC;IAClE,gBAAgB;AACpB;;AAEA,iDAAiD;AACjD;IACI,yBAAyB,EAAE,0BAA0B;IACrD,WAAW,EAAE,mBAAmB;IAChC,aAAa;IACb,gBAAgB;AACpB;;AAEA,8DAA8D","sourcesContent":["/* Assuming this is in Billing.css or a similar stylesheet */\n\n.specimen-row td {\n    padding: 8px;\n    border-bottom: 1px solid #ddd; /* Light grey line for separation */\n    text-align: left;\n}\n\n/* Styling for the table header for consistency */\nthead th {\n    background-color: #f4f4f4; /* Light grey background */\n    color: #333; /* Dark grey text */\n    padding: 10px;\n    text-align: left;\n}\n\n/* Add more styles as needed for alignment, font sizes, etc. */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
