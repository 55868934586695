// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* PricesRow.css */
.price-row {
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
    margin: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 350px;
}

.price-row-service-code{
    background: #6f7170;
    color: white;
    padding: 10px;
    width: 100px;
    border-radius: 10px;
}

.price-row-header,
.price-row-body,
.price-row-costs,
.price-row-footer {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.price-row-header {
    background-color: #f9f9f9; /* Light gray background for header */
    border-bottom: 1px solid #eee; /* Separator line */
}

.price-row-header h2 {
    font-size: 16px;
    margin: 0;
}


.price-row-body {
    flex-grow: 1; /* Allow body to grow and fill space */
}

.price-row-costs {
    background-color: #f9f9f9; /* Light gray background for costs */
    border-top: 1px solid #eee; /* Separator line */
}

/* You can specify a fixed height for the rows if needed */
.price-row {
    height: 180px; /* Adjust as needed */
}
`, "",{"version":3,"sources":["webpack://./src/components/modals/prices/prices_row/PricesRow.css"],"names":[],"mappings":"AAAA,kBAAkB;AAClB;IACI,mBAAmB;IACnB,kBAAkB;IAClB,qCAAqC;IACrC,WAAW;IACX,gBAAgB;IAChB,aAAa;IACb,sBAAsB;IACtB,YAAY;AAChB;;AAEA;IACI,mBAAmB;IACnB,YAAY;IACZ,aAAa;IACb,YAAY;IACZ,mBAAmB;AACvB;;AAEA;;;;IAII,aAAa;IACb,aAAa;IACb,mBAAmB;IACnB,8BAA8B;AAClC;;AAEA;IACI,yBAAyB,EAAE,qCAAqC;IAChE,6BAA6B,EAAE,mBAAmB;AACtD;;AAEA;IACI,eAAe;IACf,SAAS;AACb;;;AAGA;IACI,YAAY,EAAE,sCAAsC;AACxD;;AAEA;IACI,yBAAyB,EAAE,oCAAoC;IAC/D,0BAA0B,EAAE,mBAAmB;AACnD;;AAEA,0DAA0D;AAC1D;IACI,aAAa,EAAE,qBAAqB;AACxC","sourcesContent":["/* PricesRow.css */\n.price-row {\n    background: #ffffff;\n    border-radius: 8px;\n    box-shadow: 0 1px 3px rgba(0,0,0,0.1);\n    margin: 8px;\n    overflow: hidden;\n    display: flex;\n    flex-direction: column;\n    width: 350px;\n}\n\n.price-row-service-code{\n    background: #6f7170;\n    color: white;\n    padding: 10px;\n    width: 100px;\n    border-radius: 10px;\n}\n\n.price-row-header,\n.price-row-body,\n.price-row-costs,\n.price-row-footer {\n    padding: 10px;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n}\n\n.price-row-header {\n    background-color: #f9f9f9; /* Light gray background for header */\n    border-bottom: 1px solid #eee; /* Separator line */\n}\n\n.price-row-header h2 {\n    font-size: 16px;\n    margin: 0;\n}\n\n\n.price-row-body {\n    flex-grow: 1; /* Allow body to grow and fill space */\n}\n\n.price-row-costs {\n    background-color: #f9f9f9; /* Light gray background for costs */\n    border-top: 1px solid #eee; /* Separator line */\n}\n\n/* You can specify a fixed height for the rows if needed */\n.price-row {\n    height: 180px; /* Adjust as needed */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
