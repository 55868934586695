// freddy-react/src/index.js

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import App from "./App";

import {PublicClientApplication} from "@azure/msal-browser";
import {msalConfig} from "./utils/authConfig";
import {MsalProvider} from "@azure/msal-react";


export const msalInstance = new PublicClientApplication(msalConfig);


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <MsalProvider instance={msalInstance}>
        <App instance={msalInstance}/>
    </MsalProvider>
);


reportWebVitals();

