// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.orders-table {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    padding: 20px;
    width: 100%;
    align-content: start;
}


@media (max-width: 768px) {
    .orders-table {
        /*apply masinry flag to grid template colums*/
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/orders/ClientOrders.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,2DAA2D;IAC3D,SAAS;IACT,aAAa;IACb,WAAW;IACX,oBAAoB;AACxB;;;AAGA;IACI;QACI,6CAA6C;QAC7C,2DAA2D;IAC/D;AACJ","sourcesContent":[".orders-table {\n    display: grid;\n    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));\n    gap: 20px;\n    padding: 20px;\n    width: 100%;\n    align-content: start;\n}\n\n\n@media (max-width: 768px) {\n    .orders-table {\n        /*apply masinry flag to grid template colums*/\n        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
