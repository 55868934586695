// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*freddy-react/src/components/specimen_tacking/screens/header_row/HeaderRow.css*/

.header-row {
    display: flex;
    justify-content: space-between;
    background-color: #f2f2f2;
    border-bottom: 1px solid #ccc;
    margin-bottom: 15px;
}

.header-cell {
    flex: 1 1;
    text-align: center;
    font-weight: bold;
    padding: 5px 10px;
}

`, "",{"version":3,"sources":["webpack://./src/components/specimens/screens/header_row/HeaderRow.css"],"names":[],"mappings":"AAAA,gFAAgF;;AAEhF;IACI,aAAa;IACb,8BAA8B;IAC9B,yBAAyB;IACzB,6BAA6B;IAC7B,mBAAmB;AACvB;;AAEA;IACI,SAAO;IACP,kBAAkB;IAClB,iBAAiB;IACjB,iBAAiB;AACrB","sourcesContent":["/*freddy-react/src/components/specimen_tacking/screens/header_row/HeaderRow.css*/\n\n.header-row {\n    display: flex;\n    justify-content: space-between;\n    background-color: #f2f2f2;\n    border-bottom: 1px solid #ccc;\n    margin-bottom: 15px;\n}\n\n.header-cell {\n    flex: 1;\n    text-align: center;\n    font-weight: bold;\n    padding: 5px 10px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
