// freddy-react/src/pages/home/HomePage.js
import "./HomePage.css";
import React from "react";
import CreateDemoAccount from "../../components/demo_account/CreateDemoAccount";
import PublicHeader from "../../components/public_header/PublicHeader";
import { PrimaryButton } from "../../components/buttons/primary_button/PrimaryButton";

const DashboardPage = () => {

    const onLogout = () => {
        // Implement logout logic
    };

    return (
        <div className="dashboard-container">
            <header className="public-header">
                <PublicHeader onLogout={onLogout} />
            </header>
            <div>

            </div>
        </div>
    );
};

export default DashboardPage;
