// freddy-react/src/components/user/unauthenticated/UnauthenticatedWrapper.js

import React from 'react';
import UnauthenticatedRoutes from '../../../routes/UnauthenticatedRoutes';
import UnauthModals from "../../modals/unauth/UnauthModals"; // Import the unauthenticated routes

const UnauthenticatedWrapper = ({ instance, hostname }) => {
    return (
        <div className="UnauthenticatedWrapper">
            <UnauthModals />
            <UnauthenticatedRoutes instance={instance} hostname={hostname} /> {/* This includes the routes, with login redirect */}
        </div>
    );
};

export default UnauthenticatedWrapper;
