// src/routes/UnauthenticatedRoutes.js

import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import PanelsPage from '../pages/panels/Panels';
import PublicHomePage from '../pages/./home/./public_home/PublicHome';
import ShopifyHomePage from "../pages/./home/shopify_home/ShopifyHome";
import LoginPage from '../pages/user/login/Login';
import ClientOrdersPage from '../pages/client_orders/ClientOrders';
import ShopifyProtectedRoute from './ShopifyProtectedRoute';
import {useSelector} from "react-redux";
import ShopifySpeedProductPage from "../pages/shopify/speed/product/ShopifySpeedProductPage";
import PublicVerifyPage from "../pages/user/verify/PublicVerify";

const UnauthenticatedRoutes = ({ instance }) => {

    const lssCustomerDetails = useSelector((state) => state.shopifyUser.lssCollectorDetails);

    return (
        <BrowserRouter>
            <Routes>

                {/* Catch-all route */}
                <Route path="/*" element={<PublicHomePage instance={instance} />} />

                {/* Shopify Protected Routes */}
                <Route
                    path="/home"
                    element={
                        <ShopifyProtectedRoute>
                            <ShopifyHomePage />
                        </ShopifyProtectedRoute>
                    }
                />
                {lssCustomerDetails?.can_manage_panels && (
                    <Route
                        path="/panels"
                        element={
                            <ShopifyProtectedRoute>
                                <PanelsPage />
                            </ShopifyProtectedRoute>
                        }
                    />
                )}

                {lssCustomerDetails?.can_order_cartons && (
                    <Route
                        path="/orders"
                        element={
                            <ShopifyProtectedRoute>
                                <ClientOrdersPage />
                            </ShopifyProtectedRoute>
                        }
                    />
                )}

                {/* block access from users who are not login with publicUsername and publicToken */}
                <Route path="/home" element={<PublicHomePage instance={instance} />} />




                {/* Shopify SpeedSwab Product Page Route */}
                <Route path="/product" element={<ShopifySpeedProductPage />} />


                {/*<Route*/}
                {/*    path="/dashboard"*/}
                {/*    element={*/}
                {/*        <ShopifyProtectedRoute>*/}
                {/*            <PanelsPage />*/}
                {/*        </ShopifyProtectedRoute>*/}
                {/*    }*/}
                {/*/>*/}

                {/* Internal login route for staff/admin */}
                <Route path="/login" element={<LoginPage instance={instance} />} />

                <Route path="/verify" element={<PublicVerifyPage instance={instance} />} />

            </Routes>
        </BrowserRouter>
    );
};

export default UnauthenticatedRoutes;