import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    fetchLabbMlAccountDetails,
    fetchLaboratoryAccountDetails,
    saveLaboratoryAccountDetails
} from "../../services/api/v1/laboratoryAccountsService";



// Async thunk for updating laboratory account number from drafts to accounts
export const saveLaboratoryAccountRecords = createAsyncThunk(
    'laboratoryAccounts/saveLaboratoryAccountRecords',
    async (selectedClientDetails) => {
        const accessToken = sessionStorage.getItem("accessToken");
        return await saveLaboratoryAccountDetails(selectedClientDetails, accessToken);
    }
);

// Async thunk for updating laboratory account number from drafts to accounts
export const getLaboratoryAccountRecords = createAsyncThunk(
    'laboratoryAccounts/getLaboratoryAccountRecords',
    async () => {
        const accessToken = sessionStorage.getItem("accessToken");
        return await fetchLaboratoryAccountDetails(accessToken);
    }
);

// get all labb ml acount details
export const getLabbMlAccountRecords = createAsyncThunk(
    'laboratoryAccounts/getLabbMlAccountRecords',
    async () => {
        const accessToken = sessionStorage.getItem("accessToken");
        return await fetchLabbMlAccountDetails(accessToken);
    }
);

// Initial state of the laboratory slice
const initialState = {
    laboratoryAccounts: [],
    unlinkedAccounts: [],
    csvUrl: '',
    status: 'idle',
    error: null,
};

// Laboratory account slice
const laboratoryAccountsSlice = createSlice({
    name: 'laboratoryAccounts',
    initialState,
    reducers: {
        clearLaboratoryAccountData: (state) => {
            state.laboratoryAccounts = [];
            state.unlinkedAccounts = [];
            state.csvUrl = '';
            state.status = 'idle';
            state.error = null;
        },
        hideLinkAccountsModal: (state) => {
            state.laboratoryAccounts = [];
            state.unlinkedAccounts = [];
            state.csvUrl = '';
            state.status = 'idle';
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(saveLaboratoryAccountRecords.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(saveLaboratoryAccountRecords.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.laboratoryAccounts = action.payload || [];
            })
            .addCase(saveLaboratoryAccountRecords.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(getLaboratoryAccountRecords.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getLaboratoryAccountRecords.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.csvUrl = action.payload || [];
            })
            .addCase(getLaboratoryAccountRecords.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action;
            })
            .addCase(getLabbMlAccountRecords.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getLabbMlAccountRecords.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.unlinkedAccounts = action.payload || [];
            })
            .addCase(getLabbMlAccountRecords.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action;
            });
    },
});

export const { clearLaboratoryAccountData, approveLaboratoryAccount, hideLinkAccountsModal } = laboratoryAccountsSlice.actions;

export default laboratoryAccountsSlice.reducer;
