// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
/* ProductPickList.css */
.product-pick-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.pick-list-item {
    cursor: pointer;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 200px;
    text-align: center;
    height: fit-content;
    opacity: 100%;
}

.pick-list-item.selected {
    background-color: #D9D9D9;
}`, "",{"version":3,"sources":["webpack://./src/components/orders/product_pick_list/ProductPickList.css"],"names":[],"mappings":";AACA,wBAAwB;AACxB;IACI,aAAa;IACb,eAAe;IACf,SAAS;AACb;;AAEA;IACI,eAAe;IACf,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;IAClB,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":["\n/* ProductPickList.css */\n.product-pick-list {\n    display: flex;\n    flex-wrap: wrap;\n    gap: 10px;\n}\n\n.pick-list-item {\n    cursor: pointer;\n    padding: 10px;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n    width: 200px;\n    text-align: center;\n    height: fit-content;\n    opacity: 100%;\n}\n\n.pick-list-item.selected {\n    background-color: #D9D9D9;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
