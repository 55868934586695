// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.orders-page-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #f5f5f5; /* Add a light background color */
}

.orders-header-container {
    flex-shrink: 0;
}

.orders-body-container {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.orders-content-container {
    display: flex;
    flex: 1 1;
    gap: 20px;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start; /* Align items to the top */
}

.orders-table-container, .product-pick-list {
    max-height: 90vh; /* Adjust based on your layout */
    overflow-y: auto; /* Allows vertical scrolling */
}

.orders-table-container {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #FFF;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 16px;
    margin-bottom: 20px;
    max-width: 1200px; /* Add a max-width for better control */
}

@media (max-width: 768px) {
    .orders-content-container {
        flex-direction: column;
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/orders/Orders.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,iBAAiB;IACjB,yBAAyB,EAAE,iCAAiC;AAChE;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,SAAO;IACP,aAAa;IACb,sBAAsB;IACtB,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,SAAO;IACP,SAAS;IACT,mBAAmB;IACnB,uBAAuB;IACvB,uBAAuB,EAAE,2BAA2B;AACxD;;AAEA;IACI,gBAAgB,EAAE,gCAAgC;IAClD,gBAAgB,EAAE,8BAA8B;AACpD;;AAEA;IACI,SAAO;IACP,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,gBAAgB;IAChB,kBAAkB;IAClB,wCAAwC;IACxC,aAAa;IACb,mBAAmB;IACnB,iBAAiB,EAAE,uCAAuC;AAC9D;;AAEA;IACI;QACI,sBAAsB;IAC1B;AACJ","sourcesContent":[".orders-page-container {\n    display: flex;\n    flex-direction: column;\n    min-height: 100vh;\n    background-color: #f5f5f5; /* Add a light background color */\n}\n\n.orders-header-container {\n    flex-shrink: 0;\n}\n\n.orders-body-container {\n    flex: 1;\n    display: flex;\n    flex-direction: column;\n    padding: 20px;\n}\n\n.orders-content-container {\n    display: flex;\n    flex: 1;\n    gap: 20px;\n    flex-direction: row;\n    justify-content: center;\n    align-items: flex-start; /* Align items to the top */\n}\n\n.orders-table-container, .product-pick-list {\n    max-height: 90vh; /* Adjust based on your layout */\n    overflow-y: auto; /* Allows vertical scrolling */\n}\n\n.orders-table-container {\n    flex: 1;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    background: #FFF;\n    border-radius: 8px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n    padding: 16px;\n    margin-bottom: 20px;\n    max-width: 1200px; /* Add a max-width for better control */\n}\n\n@media (max-width: 768px) {\n    .orders-content-container {\n        flex-direction: column;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
