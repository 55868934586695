import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchEmails, processSpecimenNoteEmails } from "../../services/api/v1/emailService";

// Async thunk for fetching emails
export const getEmails = createAsyncThunk(
    'emails/fetchEmails',
    async (_, { getState }) => {
        const accessToken = sessionStorage.getItem('emailAccessToken');
        return await fetchEmails(accessToken);
    }
);

// Async thunk for processing emails
export const processEmail = createAsyncThunk(
    'emails/processEmail',
    async ({ device_id, emailId }) => {
        const accessToken = sessionStorage.getItem('emailAccessToken');
        const formData = new FormData();
        formData.append('device_id', device_id);
        formData.append('email_id', emailId);
        return await processSpecimenNoteEmails(formData, accessToken);
    }
);

const emailsSlice = createSlice({
    name: 'emails',
    initialState: {
        emails: [],
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getEmails.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getEmails.fulfilled, (state, action) => {
                state.status = 'idle';
                state.emails = action.payload;
            })
            .addCase(getEmails.rejected, (state, action) => {
                state.status = 'idle';
                state.error = action.error.message;
            })
            .addCase(processEmail.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(processEmail.fulfilled, (state, action) => {
                state.status = 'idle';
                // Update this logic as per your application's need
                // Example: state.emails = state.emails.map(email => email.id === action.payload.id ? action.payload : email);
            })
            .addCase(processEmail.rejected, (state, action) => {
                state.status = 'idle';
                state.error = action.error.message;
            });
    },
});

export default emailsSlice.reducer;
