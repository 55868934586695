// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.badganator-main-frame {
    height: 20vh;
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.badganator-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;
    height: 50px;
}

.badganator-collector-selector {
    width: 450px;
    margin-right: 100px;
}

.multi-selector-user {
    font-family: sans-serif;
    z-index: 3;
}

.get-collector-badge-button-frame {
    margin-right: 100px;
}

.get-collector-badge-button {
    background-color: #0F5EA5;
    color: white;
    font-weight: bold;
    padding: 0.5rem 2rem;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 1rem;
}

.get-collector-badge-button:hover {
    opacity: 0.8;
}

.pre-set-report-icon-frame {
    width: 40px;
    margin-right: 1rem;
    cursor: pointer;
}

.pre-set-report-icon-frame:hover {
    opacity: 0.8;
}
`, "",{"version":3,"sources":["webpack://./src/components/baganator/Badganator.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,uBAAuB;IACvB,UAAU;AACd;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,iBAAiB;IACjB,oBAAoB;IACpB,kBAAkB;IAClB,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".badganator-main-frame {\n    height: 20vh;\n    display: flex;\n    flex-direction: column;\n    padding: 20px;\n}\n\n.badganator-header {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 0 2rem;\n    height: 50px;\n}\n\n.badganator-collector-selector {\n    width: 450px;\n    margin-right: 100px;\n}\n\n.multi-selector-user {\n    font-family: sans-serif;\n    z-index: 3;\n}\n\n.get-collector-badge-button-frame {\n    margin-right: 100px;\n}\n\n.get-collector-badge-button {\n    background-color: #0F5EA5;\n    color: white;\n    font-weight: bold;\n    padding: 0.5rem 2rem;\n    border-radius: 4px;\n    cursor: pointer;\n    margin-right: 1rem;\n}\n\n.get-collector-badge-button:hover {\n    opacity: 0.8;\n}\n\n.pre-set-report-icon-frame {\n    width: 40px;\n    margin-right: 1rem;\n    cursor: pointer;\n}\n\n.pre-set-report-icon-frame:hover {\n    opacity: 0.8;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
