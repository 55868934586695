// src/services/api/v1/upsAuthTokenService.js

import { makePOSTRequest } from '../../utils/httpUtils';

export const fetchUpsAuthToken = async (upsGrantToken, accessToken) => {
    const formData = new FormData();
    formData.append('grant_token', upsGrantToken);

    // Assuming accessToken is needed for backend authorization. Adjust as necessary.
    return makePOSTRequest(`/api/v1/external/ups/auth/token/new`, formData, accessToken);
};
