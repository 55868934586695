import { createSlice } from '@reduxjs/toolkit';

export const modalsSlice = createSlice({
    name: 'modals',
    initialState: {
        uploadStatus: 'idle', // 'idle', 'loading', 'succeeded', 'failed'
        successMessage: '',
        errorMessage: '',
        isModalOpen: false,
        message: '',
        link: null,
    },
    reducers: {
        startUpload: state => {
            state.uploadStatus = 'loading';
        },
        uploadSuccess: (state, action) => {
            state.uploadStatus = 'succeeded';
            state.successMessage = action.payload;
        },
        uploadFailure: (state, action) => {
            state.uploadStatus = 'failed';
            state.errorMessage = action.payload;
        },
        showMessageModal: (state, action) => {
            state.isModalOpen = true;
            state.message = action.payload.message;
            state.link = action.payload.link;
        },
        hideMessageModal: state => {
            state.isModalOpen = false;
            state.message = '';
            state.link = null;
        },
    },
});

export const {
    startUpload,
    uploadSuccess,
    uploadFailure,
    showMessageModal,
    hideMessageModal
} = modalsSlice.actions;

export default modalsSlice.reducer;
