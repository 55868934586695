import React from 'react';

const Input = ({ id, name, placeholder, value, options = [], type = 'text', multiple = false, onChange, required = false }) => {
    if (type === 'select') {
        return (
            <select id={id} name={name} value={value} onChange={onChange} required={required}>
                <option value="" disabled>{placeholder}</option>
                {options.map((option, index) => (
                    <option key={index} value={option}>{option}</option>
                ))}
            </select>
        );
    }

    if (multiple) {
        return (
            <div className="multi-select-options">
                {options.map((option, index) => (
                    <div key={index} className="multi-select-option">
                        <input
                            type="checkbox"
                            id={`${id}-${index}`}
                            checked={value.includes(option)}
                            onChange={() => onChange(option)}
                        />
                        <label htmlFor={`${id}-${index}`}>{option}</label>
                    </div>
                ))}
            </div>
        );
    }

    return (
        <input
            type={type}
            id={id}
            name={name}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            required={required}
        />
    );
};

export default Input;
