// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mro-specimens-table-container {
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    height: 100%;
}

.filters {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20px;
    gap: 10px;
}

.filter-select {
    min-width: 90vh;
}

.table-container {
    overflow-y: auto;
    height: 100%;
}

table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
    height: 100%;
}

th, td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

thead th {
    background-color: #f5f5f5;
    position: sticky;
    top: 0;
    z-index: 10;
}

tr:nth-child(odd) {
    background-color: #f9f9f9;
}

tr:nth-child(even) {
    background-color: #ffffff;
}
`, "",{"version":3,"sources":["webpack://./src/components/specimens/mro_screens/MroSpecimensTable.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,wCAAwC;IACxC,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,8BAA8B;IAC9B,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,yBAAyB;IACzB,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,gBAAgB;IAChB,6BAA6B;AACjC;;AAEA;IACI,yBAAyB;IACzB,gBAAgB;IAChB,MAAM;IACN,WAAW;AACf;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".mro-specimens-table-container {\n    padding: 20px;\n    background-color: #fff;\n    border-radius: 8px;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n    height: 100%;\n}\n\n.filters {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: space-between;\n    margin-bottom: 20px;\n    gap: 10px;\n}\n\n.filter-select {\n    min-width: 90vh;\n}\n\n.table-container {\n    overflow-y: auto;\n    height: 100%;\n}\n\ntable {\n    width: 100%;\n    border-collapse: collapse;\n    table-layout: fixed;\n    height: 100%;\n}\n\nth, td {\n    padding: 12px;\n    text-align: left;\n    border-bottom: 1px solid #ddd;\n}\n\nthead th {\n    background-color: #f5f5f5;\n    position: sticky;\n    top: 0;\n    z-index: 10;\n}\n\ntr:nth-child(odd) {\n    background-color: #f9f9f9;\n}\n\ntr:nth-child(even) {\n    background-color: #ffffff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
