
// Function to generate Shopify OAuth URL
export const getShopifyOAuthUrl = async (hostname) => {
    const clientId = "shp_007be0ac-fa35-4eac-b362-6e5ba2dc4840";
    const redirectUri = `https://${hostname}/home`;
    const scopes = 'openid email https://api.customers.com/auth/customer.graphql';

    const state = generateNonce(); // Generating a secure nonce
    const nonce = generateNonce(); // Use the same function for nonce
    const codeVerifier = generateCodeVerifier(); // Secure code verifier
    const codeChallenge = await generateCodeChallenge(codeVerifier);

    sessionStorage.setItem('codeVerifier', codeVerifier);

    const authorizationRequestUrl = new URL(
        `https://shopify.com/58965033073/auth/oauth/authorize`
    );

    authorizationRequestUrl.searchParams.append('client_id', clientId);
    authorizationRequestUrl.searchParams.append('scope', scopes);
    authorizationRequestUrl.searchParams.append('redirect_uri', redirectUri);
    authorizationRequestUrl.searchParams.append('response_type', 'code');
    authorizationRequestUrl.searchParams.append('state', state);
    authorizationRequestUrl.searchParams.append('nonce', nonce);
    authorizationRequestUrl.searchParams.append('code_challenge', codeChallenge);
    authorizationRequestUrl.searchParams.append('code_challenge_method', 'S256');

    return authorizationRequestUrl.toString();
};

// Function to generate the code verifier
const generateCodeVerifier = () => {
    const array = new Uint32Array(56/2);
    window.crypto.getRandomValues(array);
    return Array.from(array, dec => ('0' + dec.toString(16)).substr(-2)).join('');
};

// Function to generate the code challenge from the verifier
const generateCodeChallenge = async (codeVerifier) => {
    const encoder = new TextEncoder();
    const data = encoder.encode(codeVerifier);
    const hashed = await crypto.subtle.digest('SHA-256', data);
    return btoa(String.fromCharCode(...new Uint8Array(hashed)))
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=+$/, '');
};

// Function to generate a nonce
const generateNonce = () => {
    return generateCodeVerifier(); // Shopify's recommended approach
};