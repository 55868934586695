// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Main table styles */
.specimen-row {
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
}

.specimen-row-expanded {
    background-color: #f3f3f3; /* Light grey to indicate expansion */
}

/* Expanded row details styles */
.specimen-row-detail td {
    background-color: #e9e9e9; /* Slightly darker grey for contrast */
    padding: 10px;
}

.specimen-row-detail-cell {
    grid-column: span 7; /* Make sure it spans all the columns */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/* Existing styles */
.specimen-row-cell-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.specimen-row-cell {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.specimen-row-cell .Tooltip {
    width: 100%;
}

.specimen-row-cell .Tooltip:hover .Tooltip-text {
    visibility: visible;
}

@media (max-width: 768px) {
    .specimen-row-detail-cell {
        grid-column: span 7; /* Adapt to single column layout */
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/specimens/screens/specimens_row/SpecimensRow.css"],"names":[],"mappings":"AAAA,sBAAsB;AACtB;IACI,eAAe;IACf,6CAA6C;AACjD;;AAEA;IACI,yBAAyB,EAAE,qCAAqC;AACpE;;AAEA,gCAAgC;AAChC;IACI,yBAAyB,EAAE,sCAAsC;IACjE,aAAa;AACjB;;AAEA;IACI,mBAAmB,EAAE,uCAAuC;IAC5D,gBAAgB;IAChB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA,oBAAoB;AACpB;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;AAClC;;AAEA;IACI,uBAAuB;IACvB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI;QACI,mBAAmB,EAAE,kCAAkC;IAC3D;AACJ","sourcesContent":["/* Main table styles */\n.specimen-row {\n    cursor: pointer;\n    transition: background-color 0.3s ease-in-out;\n}\n\n.specimen-row-expanded {\n    background-color: #f3f3f3; /* Light grey to indicate expansion */\n}\n\n/* Expanded row details styles */\n.specimen-row-detail td {\n    background-color: #e9e9e9; /* Slightly darker grey for contrast */\n    padding: 10px;\n}\n\n.specimen-row-detail-cell {\n    grid-column: span 7; /* Make sure it spans all the columns */\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n}\n\n/* Existing styles */\n.specimen-row-cell-content {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n}\n\n.specimen-row-cell {\n    text-overflow: ellipsis;\n    overflow: hidden;\n    white-space: nowrap;\n}\n\n.specimen-row-cell .Tooltip {\n    width: 100%;\n}\n\n.specimen-row-cell .Tooltip:hover .Tooltip-text {\n    visibility: visible;\n}\n\n@media (max-width: 768px) {\n    .specimen-row-detail-cell {\n        grid-column: span 7; /* Adapt to single column layout */\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
