import React from 'react';
import './Device.css';

const Device = ({ device_id }) => {
    return (
        <section>
            <div className="device">
                <div className="device_header">
                    <p className="device_header_text">Device Details</p>
                </div>
                <div className="dark_grey_strip" />
                <div className="device_info_section">
                    <p>SD0{device_id}</p>
                </div>
            </div>
        </section>
    );
};

export default Device;
