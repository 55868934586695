// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.screens-page-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #f0f2f5;
}

.screens-header-container {
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 10px 20px;
}

.screens-body-container {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    padding: 20px;
}

.screens-content-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 20px;
}

@media (max-width: 768px) {
    .screens-content-container {
        padding: 10px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/medical_review_officer/MedicalReviewOfficerPage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,iBAAiB;IACjB,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IACzB,wCAAwC;IACxC,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,iBAAiB;IACjB,cAAc;IACd,yBAAyB;IACzB,kBAAkB;IAClB,wCAAwC;IACxC,aAAa;AACjB;;AAEA;IACI;QACI,aAAa;IACjB;AACJ","sourcesContent":[".screens-page-container {\n    display: flex;\n    flex-direction: column;\n    min-height: 100vh;\n    background-color: #f0f2f5;\n}\n\n.screens-header-container {\n    background-color: #ffffff;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n    padding: 10px 20px;\n}\n\n.screens-body-container {\n    flex-grow: 1;\n    display: flex;\n    justify-content: center;\n    padding: 20px;\n}\n\n.screens-content-container {\n    width: 100%;\n    max-width: 1200px;\n    margin: 0 auto;\n    background-color: #ffffff;\n    border-radius: 8px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);\n    padding: 20px;\n}\n\n@media (max-width: 768px) {\n    .screens-content-container {\n        padding: 10px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
