// freddy-react/src/services/ws/v1/notifications/index.js

import { useEffect, useState } from 'react';

const useNotificationsWS = (url) => {
    const [socket, setSocket] = useState(null);
    const [notifications, setNotifications] = useState([]);

    useEffect(() => {
        const ws = new WebSocket(url);

        ws.onopen = () => {
            console.log('Connected to notifications WS');
        };

        ws.onmessage = (event) => {
            const notification = JSON.parse(event.data);
            setNotifications((prev) => [...prev, notification]);
        };

        ws.onclose = () => {
            console.log('Disconnected from notifications WS');
        };

        setSocket(ws);

        return () => {
            ws.close();
        };
    }, [url]);

    return { notifications, socket };
};

export default useNotificationsWS;
