import React from "react";
import "./SpeedSwabsDoNotOverpay.css";
import {PrimaryButton} from "../../buttons/primary_button/PrimaryButton";
import {SecondaryButton} from "../../buttons/secondary_button/SecondaryButton";
import { useNavigate } from "react-router-dom";

const SpeedSwabsDoNotOverpay = () => {
    const navigate = useNavigate();
    const handleBuyNow = async () => {
        // Send event data to Google Tag Manager
        if (window.dataLayer) {
            window.dataLayer.push({
                event: "buy_now_click",
                buttonLocation: "SpeedSwabsOverpay",
                productName: "SpeedSwabs",
                productCategory: "Oral Fluid Drug Test",
                action: "click"
            });
        }

        // Navigate to the product page
        navigate("/product");
    };

    return (
        <div className="do-not-overpay-section">
            <div className="do-not-overpay-heading-container">
                <h2 className="do-not-overpay-heading">Stop <span className="highlight">overpaying</span> for rapid drug tests that don’t work.</h2>
            </div>
            <div className="do-not-overpay-description-container">

                <p className="do-not-overpay-description">Upgrade to the fastest drug test on the market - SpeedSwabs!</p>

            </div>
            <div className="do-not-overpay-buttons">
                <PrimaryButton onClick={handleBuyNow}>
                    Buy now
                </PrimaryButton>
                {/*<SecondaryButton>*/}
                {/*    Learn more*/}
                {/*</SecondaryButton>*/}
            </div>
        </div>
    );
};

export default SpeedSwabsDoNotOverpay;