// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*freddy-react/src/components/autobot/client_selector/ClientSelector.css*/

/* ClientSelector.css */

.labb-clients-search {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #ECEDED;
    padding: 1rem;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.labb-clients-search-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 350px;
}

.search-input {
    flex: 1 1;
    margin: 0.5rem 0;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    max-width: calc(95% - 0.5rem); /* Limit input width to 50% minus margin */
}

/* You might want to adjust the style of the dropdown menu further if needed */
.search-input .react-select__menu {
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.search-input .react-select__control {
    border-radius: 4px;
    border: 1px solid #e1e1e1;
}

.search-input .react-select__placeholder {
    color: #888;
}
`, "",{"version":3,"sources":["webpack://./src/components/autobot/client_selector/ClientSelector.css"],"names":[],"mappings":"AAAA,yEAAyE;;AAEzE,uBAAuB;;AAEvB;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,yBAAyB;IACzB,aAAa;IACb,kBAAkB;IAClB,wCAAwC;AAC5C;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,YAAY;AAChB;;AAEA;IACI,SAAO;IACP,gBAAgB;IAChB,eAAe;IACf,sBAAsB;IACtB,kBAAkB;IAClB,eAAe;IACf,6BAA6B,EAAE,0CAA0C;AAC7E;;AAEA,8EAA8E;AAC9E;IACI,kBAAkB;IAClB,wCAAwC;AAC5C;;AAEA;IACI,kBAAkB;IAClB,yBAAyB;AAC7B;;AAEA;IACI,WAAW;AACf","sourcesContent":["/*freddy-react/src/components/autobot/client_selector/ClientSelector.css*/\n\n/* ClientSelector.css */\n\n.labb-clients-search {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    background-color: #ECEDED;\n    padding: 1rem;\n    border-radius: 4px;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n}\n.labb-clients-search-button {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    width: 350px;\n}\n\n.search-input {\n    flex: 1;\n    margin: 0.5rem 0;\n    padding: 0.5rem;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n    font-size: 16px;\n    max-width: calc(95% - 0.5rem); /* Limit input width to 50% minus margin */\n}\n\n/* You might want to adjust the style of the dropdown menu further if needed */\n.search-input .react-select__menu {\n    border-radius: 4px;\n    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);\n}\n\n.search-input .react-select__control {\n    border-radius: 4px;\n    border: 1px solid #e1e1e1;\n}\n\n.search-input .react-select__placeholder {\n    color: #888;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
