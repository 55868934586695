// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.client-fast-paste-select {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.paste-input {
    width: 100%;
    height: 100px;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    resize: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/clients/fast_select/FastSelect.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,WAAW;IACX,aAAa;IACb,eAAe;IACf,sBAAsB;IACtB,kBAAkB;IAClB,eAAe;IACf,YAAY;AAChB","sourcesContent":[".client-fast-paste-select {\n    display: flex;\n    flex-direction: column;\n    gap: 1rem;\n}\n\n.paste-input {\n    width: 100%;\n    height: 100px;\n    padding: 0.5rem;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n    font-size: 1rem;\n    resize: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
