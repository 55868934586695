import React, { useState, useEffect } from "react";
import './ShowDetails.css';
import { GenericIcons } from "../../../../../assets/icons";
import { useSelector } from "react-redux";
import { IconButton } from "../../../../buttons/icon_button/IconButton";
import { PrimaryButton } from "../../../../buttons/primary_button/PrimaryButton";

const ShowDetails = ({ device_id, showDetails, onShowDetailsChange }) => {
    const accessToken = sessionStorage.getItem("accessToken");
    const originalShowDetails = showDetails?.show_details;

    const binaryToShowDetails = (binary) => binary === 1 ? 'DETAILED' : 'PASS | FAIL';

    const [currentShowDetails, setCurrentShowDetails] = useState(binaryToShowDetails(originalShowDetails));
    const [showDetailsBinary, setShowDetailsBinary] = useState(originalShowDetails);

    useEffect(() => {
        setCurrentShowDetails(binaryToShowDetails(originalShowDetails));
        setShowDetailsBinary(originalShowDetails);
    }, [originalShowDetails]);

    const toggleShowDetails = () => {
        if (currentShowDetails === 'DETAILED') {
            setCurrentShowDetails('PASS | FAIL');
            setShowDetailsBinary(0);
            onShowDetailsChange(0);
        } else {
            setCurrentShowDetails('DETAILED');
            setShowDetailsBinary(1);
            onShowDetailsChange(1);
        }
    };

    return (
        <section>
            <div className="show-details">
                <div className="show-details_header">
                    <p className="show-details_header_text">Show Details</p>
                </div>
                <div className="dark_grey_strip"/>
                <div className="show-details_info_section">
                    <PrimaryButton type="button" onClick={toggleShowDetails}> {currentShowDetails} </PrimaryButton>
                </div>
            </div>
        </section>
    );
};

export default ShowDetails;
