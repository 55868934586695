// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mro-page-header-row {
    display: grid;
    grid-template-columns: 115px 225px 85px 85px 85px 115px 85px 85px 85px; /* Explicitly set column sizes */
    gap: 10px;
    padding: 10px;
    align-items: center;
    overflow: visible;
    z-index: 50;
}

.mro-page-header-cell {
    flex: 1 1;
    text-align: center;
    font-weight: bold;
    padding: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/specimens/mro_screens/header_row/HeaderRow.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sEAAsE,EAAE,gCAAgC;IACxG,SAAS;IACT,aAAa;IACb,mBAAmB;IACnB,iBAAiB;IACjB,WAAW;AACf;;AAEA;IACI,SAAO;IACP,kBAAkB;IAClB,iBAAiB;IACjB,aAAa;AACjB","sourcesContent":[".mro-page-header-row {\n    display: grid;\n    grid-template-columns: 115px 225px 85px 85px 85px 115px 85px 85px 85px; /* Explicitly set column sizes */\n    gap: 10px;\n    padding: 10px;\n    align-items: center;\n    overflow: visible;\n    z-index: 50;\n}\n\n.mro-page-header-cell {\n    flex: 1;\n    text-align: center;\n    font-weight: bold;\n    padding: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
