import React from 'react';
import './IndustrySelector.css';

const IndustrySelector = ({ selectedIndustry, industries, handleSelect }) => {
    return (
        <div className="industry-selector">
            {industries.map((industry, index) => (
                <button
                    key={index}
                    className={`industry-button ${selectedIndustry === industry ? 'selected' : ''}`}
                    onClick={() => handleSelect(industry)}
                >
                    {industry}
                </button>
            ))}
        </div>
    );
};

export default IndustrySelector;