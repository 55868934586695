import React, { useState } from "react";
import "./CreateDemoAccount.css";
import LabFusionLogo from "../../assets/logo/LabFusionLogo.png";

const CreateDemoAccount = () => {
    const [formData, setFormData] = useState({
        company: '',
        firstName: '',
        lastName: '',
        phone: '',
        email: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission logic
        console.log("Form submitted:", formData);
    };

    return (
        <div className="create-demo-account-container">
            <div className="create-demo-account-left">
                <img className="header-logo"
                     src="https://static.wixstatic.com/media/40f727_b17ccfdda56f41e494a6586affdeae2e~mv2.png"
                     alt="Labb Logo"/>
                <p>A simple solution for rapid & lab drug testing</p>
                <ul>
                    <li>Real time specimen tracking</li>
                    <li>Paperless rapid and lab testing</li>
                    <li>Professional result forms</li>
                </ul>
            </div>
            <div className="create-demo-account-right">
                <form className="create-demo-account-form" onSubmit={handleSubmit}>
                    <input
                        type="text"
                        name="company"
                        placeholder="Company"
                        value={formData.company}
                        onChange={handleChange}
                    />
                    <input
                        type="text"
                        name="firstName"
                        placeholder="First Name"
                        value={formData.firstName}
                        onChange={handleChange}
                    />
                    <input
                        type="text"
                        name="lastName"
                        placeholder="Last Name"
                        value={formData.lastName}
                        onChange={handleChange}
                    />
                    <input
                        type="text"
                        name="phone"
                        placeholder="Phone"
                        value={formData.phone}
                        onChange={handleChange}
                    />
                    <input
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={formData.email}
                        onChange={handleChange}
                    />
                    <button type="submit">CREATE ACCOUNT</button>
                </form>
            </div>
        </div>
    );
};

export default CreateDemoAccount;
