// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    border: 1px solid #ECEDED;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 2;
    background: #fff;
    margin-bottom: 0px;
}

.filter {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.filter-actions {
    display: flex;
    justify-content: flex-end; /* Example to align ActionTray to the right */
}



.filter-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.client-selector,
.location-selector {
    flex-grow: 1;
    margin-right: 10px;
}

.date-range-row {
    display: flex;
    justify-content: center;
    padding: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/specimens/specimen_filters/SpecimenFilters.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,oBAAoB;IACpB,yBAAyB;IACzB,kBAAkB;IAClB,aAAa;IACb,0CAA0C;IAC1C,kBAAkB;IAClB,UAAU;IACV,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,8BAA8B;AAClC;;AAEA;IACI,aAAa;IACb,yBAAyB,EAAE,6CAA6C;AAC5E;;;;AAIA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;;IAEI,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,YAAY;AAChB","sourcesContent":[".filter-container {\n    display: flex;\n    flex-direction: column;\n    align-items: stretch;\n    border: 1px solid #ECEDED;\n    border-radius: 5px;\n    padding: 10px;\n    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);\n    position: relative;\n    z-index: 2;\n    background: #fff;\n    margin-bottom: 0px;\n}\n\n.filter {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: space-between;\n}\n\n.filter-actions {\n    display: flex;\n    justify-content: flex-end; /* Example to align ActionTray to the right */\n}\n\n\n\n.filter-row {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: space-between;\n    margin-bottom: 10px;\n}\n\n.client-selector,\n.location-selector {\n    flex-grow: 1;\n    margin-right: 10px;\n}\n\n.date-range-row {\n    display: flex;\n    justify-content: center;\n    padding: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
