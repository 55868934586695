// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.clients-content-container {
    position: relative;
    z-index: 1;
}

.labb-clients-search-header .dropdown-list.show {
    position: absolute;
    z-index: 999;
    background: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}`, "",{"version":3,"sources":["webpack://./src/pages/clients/Clients.css"],"names":[],"mappings":";AACA;IACI,kBAAkB;IAClB,UAAU;AACd;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,gBAAgB;IAChB,wCAAwC;IACxC,kBAAkB;AACtB","sourcesContent":["\n.clients-content-container {\n    position: relative;\n    z-index: 1;\n}\n\n.labb-clients-search-header .dropdown-list.show {\n    position: absolute;\n    z-index: 999;\n    background: #fff;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n    border-radius: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
