import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchEmails, processSpecimenNoteEmails, sendEmailInvite } from "../../services/api/v1/emailService";

// Async thunk for fetching emails
export const getEmails = createAsyncThunk(
    'emails/fetchEmails',
    async (_, { getState }) => {
        const accessToken = sessionStorage.getItem('emailAccessToken');
        return await fetchEmails(accessToken);
    }
);

// Async thunk for processing emails
export const processEmail = createAsyncThunk(
    'emails/processEmail',
    async ({ device_id, emailId }) => {
        const accessToken = sessionStorage.getItem('emailAccessToken');
        const formData = new FormData();
        formData.append('device_id', device_id);
        formData.append('email_id', emailId);
        return await processSpecimenNoteEmails(formData, accessToken);
    }
);

// Async thunk for sending an invite email
export const fetchSendInviteEmail = createAsyncThunk(
    'emails/sendInviteEmail',
    async ({ collectorId, permissionLevel }) => {
        const accessToken = sessionStorage.getItem('accessToken');
        const formData = new FormData();
        formData.append('collector_id', collectorId);
        formData.append('permission_level', permissionLevel);
        return await sendEmailInvite(formData, accessToken);
    }
);

const emailsSlice = createSlice({
    name: 'emails',
    initialState: {
        emails: [],
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getEmails.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getEmails.fulfilled, (state, action) => {
                state.status = 'idle';
                state.emails = action.payload;
            })
            .addCase(getEmails.rejected, (state, action) => {
                state.status = 'idle';
                state.error = action.error.message;
            })
            .addCase(processEmail.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(processEmail.fulfilled, (state, action) => {
                state.status = 'idle';
                // Update this logic as per your application's need
                // Example: state.emails = state.emails.map(email => email.id === action.payload.id ? action.payload : email);
            })
            .addCase(processEmail.rejected, (state, action) => {
                state.status = 'idle';
                state.error = action.error.message;
            })
            .addCase(fetchSendInviteEmail.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchSendInviteEmail.fulfilled, (state) => {
                state.status = 'idle';
                alert('Invite email sent successfully!');
            })
            .addCase(fetchSendInviteEmail.rejected, (state, action) => {
                state.status = 'idle';
                state.error = action.error.message;
                alert('Failed to send invite email. Please try again.');
            });
    },
});

export default emailsSlice.reducer;