import React, { useState } from "react";
import "./TalkSwabs.css";
import Input from "../../assets/input/Input";
import ProgressBar from "../../assets/progress_bar/ProgressBar";
import FormStepNavigation from "../../assets/navigation/Navigation";
import IndustrySelector from "../../assets/industry_selector/IndustrySelector";

const TalkSwabs = () => {
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        companyName: "",
        email: "",
        phone: "",
        industry: "",
        testingVolume: "",
        importantFactors: [],
        subscribe: false
    });
    const [step, setStep] = useState(1);

    const industries = [
        "Occupational Health Facility",
        "Employment Agency",
        "Hospital or Urgent Care",
        "Workplace",
        "Other",
        "Probation/Parole Department",
        "Drug Testing Site"
    ];
    const volumes = ["Less than 100", "100-500", "500-1000", "More than 1000"];
    const importantOptions = ["Speed", "Accuracy", "Price", "Customer Support", "Ease of Use"];

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({ ...formData, [name]: type === "checkbox" ? checked : value });
    };

    const handleIndustrySelect = (industry) => {
        setFormData({ ...formData, industry });
    };

    const handleMultiSelect = (option) => {
        setFormData((prevData) => {
            const isSelected = prevData.importantFactors.includes(option);
            const updatedFactors = isSelected
                ? prevData.importantFactors.filter((item) => item !== option)
                : [...prevData.importantFactors, option];
            return { ...prevData, importantFactors: updatedFactors };
        });
    };

    const handleNext = () => {
        setStep(step + 1);
    };

    const handlePrevious = () => {
        setStep(step - 1);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Form submitted:", formData);
    };

    return (
        <div className="talk-swabs-form-wrapper">
            <form className="talk-swabs-form" onSubmit={handleSubmit}>
                <ProgressBar step={step} />
                <h2 className="talk-swabs-form-title">Talk to Us About Your Testing Needs</h2>

                {step === 1 && (
                    <div className="talk-swabs-name-form-group">
                        <Input
                            id="firstName"
                            name="firstName"
                            placeholder="First Name"
                            value={formData.firstName}
                            onChange={handleChange}
                            required
                        />
                        <Input
                            id="lastName"
                            name="lastName"
                            placeholder="Last Name"
                            value={formData.lastName}
                            onChange={handleChange}
                            required
                        />
                    </div>
                )}

                {step === 2 && (
                    <div className="talk-swabs-form-group">
                        <Input
                            id="companyName"
                            name="companyName"
                            placeholder="Company Name"
                            value={formData.companyName}
                            onChange={handleChange}
                            required
                        />
                        <IndustrySelector
                            selectedIndustry={formData.industry}
                            industries={industries}
                            handleSelect={handleIndustrySelect}
                        />
                    </div>
                )}

                {/* The rest of the form remains the same */}

                <div className="button-group">
                    <FormStepNavigation
                        handleNext={step === 4 ? handleSubmit : handleNext}
                        handlePrevious={step > 1 ? handlePrevious : null}
                        isLastStep={step === 4}
                    />
                </div>
            </form>
        </div>
    );
};

export default TalkSwabs;
