// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*src/components/invoices/invoice_filters/InvoiceFilters.css*/
.filter-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    padding: 10px 20px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.filter-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.filter {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/invoices/invoice_filters/InvoiceFilters.css"],"names":[],"mappings":"AAAA,6DAA6D;AAC7D;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,WAAW;IACX,kBAAkB;IAClB,yBAAyB;IACzB,wCAAwC,EAAE,4BAA4B;AAC1E;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,SAAO;IACP,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb","sourcesContent":["/*src/components/invoices/invoice_filters/InvoiceFilters.css*/\n.filter-container {\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n    width: 100%;\n    padding: 10px 20px;\n    background-color: #ffffff;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */\n}\n\n.filter-row {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.filter {\n    flex: 1;\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
