import React, { useEffect, useState } from 'react';
import './ShopifyHome.css';
import { useDispatch, useSelector } from 'react-redux';
import { clearShopifyAuth, getShopifyAuth } from "../../store/external/shopifyAuthSlice";
import { useLocation } from "react-router-dom";
import ShopifyHeader from "../../components/shopify_header/ShopifyHeader";
import LoadingSpinningCup from "../loading/Loading";
import ShopifyCustomerDetails from "../../components/user/cusotmer_details/ShopifyCustomerDetails";
import SpecimenBilling from "../../components/specimens/billing/SpecimenBilling";
import {getClientBillingDetails} from "../../store/clients/clientBillingDetailsSlice";
import PanelDetails from "../../components/clients/panel_details/PanelDetails";
import ClientOrderBilling from "../../components/orders/client_order_billing/ClientOrderBilling";
import TabNavigation from "../../components/public_header/public_nav/PublicNav";
import PublicNav from "../../components/public_header/public_nav/PublicNav";
import PanelsPage from "../panels/Panels";


const exchangeCodeForTokens = async (authorizationCode, hostname) => {
    const clientId = "shp_007be0ac-fa35-4eac-b362-6e5ba2dc4840";
    const redirectUri = `https://${hostname}/home`;

    const codeVerifier = sessionStorage.getItem('codeVerifier');

    const body = new URLSearchParams();
    body.append('grant_type', 'authorization_code');
    body.append('client_id', clientId);
    body.append('code', authorizationCode);
    body.append("code_verifier", codeVerifier);
    body.append('redirect_uri', redirectUri);

    const response = await fetch(`https://shopify.com/58965033073/auth/oauth/token`, {
        method: 'POST',
        body,
    });

    if (!response.ok) {
        throw new Error('Failed to exchange authorization code for tokens');
    }

    const data = await response.json();
    return { access_token: data.access_token, id_token: data.id_token };
};

export const ShopifyHomePage = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const [shopifyGrantToken, setShopifyGrantToken] = useState(sessionStorage.getItem('shopifyGrantToken'));
    const hostname = new URL(window.location.href).hostname;
    const customerDetails = useSelector(state => state.shopifyUser.customerDetails);
    const lssCustomerDetails = useSelector(state => state.shopifyUser.lssCollectorDetails);

    // Function to handle fetching tokens and updating state
    const fetchAndStoreTokens = async (shopifyAuthCode) => {
        try {
            const tokens = await exchangeCodeForTokens(shopifyAuthCode, hostname);
            sessionStorage.setItem('shopifyGrantToken', tokens.access_token);
            sessionStorage.setItem('shopifyIdToken', tokens.id_token);
            setShopifyGrantToken(tokens.access_token);  // Update the state with the new token
            dispatch(getShopifyAuth());
        } catch (error) {
            console.error('Failed to exchange code for tokens:', error);
        }
    };

    // Effect to manage token retrieval
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const shopifyAuthCode = params.get('code');

        if (shopifyAuthCode && !shopifyGrantToken) {
            fetchAndStoreTokens(shopifyAuthCode);
        }
    }, [location, shopifyGrantToken, dispatch]);

    const onLogout = () => {
        const idToken = sessionStorage.getItem('shopifyIdToken');
        const logoutUri = `https://shopify.com/58965033073/auth/logout`;
        const redirectUri = `https://${hostname}/`; // You can change this to any URI you want to redirect after logout

        // Redirect to Shopify logout endpoint
        window.location.href = `${logoutUri}?id_token_hint=${idToken}&post_logout_redirect_uri=${encodeURIComponent(redirectUri)}`;

        // Clear local session
        sessionStorage.removeItem('shopifyGrantToken');
        sessionStorage.removeItem('shopifyIdToken');
        dispatch(clearShopifyAuth());
    };

    // Render Shopify customer details component if tokens are available
    if (shopifyGrantToken) {
        return (
            <div>
                <ShopifyHeader onLogout={onLogout} />
                {shopifyGrantToken ? (
                    <div>
                        <ShopifyCustomerDetails />
                        {/*<PanelsPage />*/}
                        {/*<SpecimenBilling />*/}
                        {/*<PanelDetails />*/}
                        {/*<ClientOrderBilling />*/}
                    </div>
                ) : (
                    <LoadingSpinningCup />
                )}
            </div>
        );
    }

    // Placeholder or loading message while waiting for the token
    return <div>Retrieving access token...</div>;
};

export default ShopifyHomePage;