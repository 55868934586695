// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.expand-cell-container {
    display: flex; /* Flex layout for better alignment */
    align-items: center; /* Vertically aligns items in the middle */
    justify-content: center; /* Horizontally centers the content */
    cursor: pointer; /* Changes the cursor to indicate it's clickable */
}`, "",{"version":3,"sources":["webpack://./src/components/clients/expand_cell/ExpandCell.css"],"names":[],"mappings":"AAAA;IACI,aAAa,EAAE,qCAAqC;IACpD,mBAAmB,EAAE,0CAA0C;IAC/D,uBAAuB,EAAE,qCAAqC;IAC9D,eAAe,EAAE,kDAAkD;AACvE","sourcesContent":[".expand-cell-container {\n    display: flex; /* Flex layout for better alignment */\n    align-items: center; /* Vertically aligns items in the middle */\n    justify-content: center; /* Horizontally centers the content */\n    cursor: pointer; /* Changes the cursor to indicate it's clickable */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
