// BillingClientSection.js
import React from 'react';
import './BillingClientSection.css';
import BillingSpecimenTableHeader from "../header/BillingSpecimenTableHeader";
import { groupAndSortData } from "../../../helpers/process/processBillingSpecimens";
import formatDate from "../../../utils/dateUtils";


const BillingClientSection = ({ clientSpecimens }) => {

    return (
        <div>
            <div className="client-section">
                <table>
                    <BillingSpecimenTableHeader />
                    <tbody>
                    {clientSpecimens?.map((item, index) => (
                        <tr key={index} className="specimen-row">
                            <td> {item.zoho_invoice_number || "Invoice Number Not Found"}</td>
                            <td>{item.specimen_id || "Specimen ID Not Found"}</td>
                            <td>{formatDate(item.collected_on) || 'Collected On Not Found'}</td>
                            <td>{item.product_name || 'Product Name Not Found'}</td>
                            <td>{item.retail_price || 'Retail Price Not Found'}</td>
                            <td>{item.client_name || 'Client Name Not Found'}</td>
                            <td>{item.location_name || 'Location Name Not Found'}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default BillingClientSection;
