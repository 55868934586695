import { makePOSTRequest } from "../../utils/httpUtils";


export const fetchLssProducts = (accessToken) => {
    const formData = new FormData();

    return makePOSTRequest('/api/v1/product/lss/list', formData, accessToken);
}

export const fetchShopifyProducts = ({ accessToken, shopifyAdminAccessToken }) => {
    const formData = new FormData();
    formData.append('shopify_oauth_token', shopifyAdminAccessToken);

    return makePOSTRequest('/api/v1/product/shopify/list', formData, accessToken);
};


export const createShopifyProducts = ({ accessToken, formData }) => {
    return makePOSTRequest('/api/v1/external/shopify/service/create/new', formData, accessToken);
};