// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* PasteUpload.css */

.paste-upload-modal {
    background-color: #484848;
    border-radius: 10px;
    padding: 10px;
    color: white;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
}

.modal-header {
    display: flex;
    justify-content: flex-start;
}

.close-icon {
    cursor: pointer;
    width: 24px;
    height: 24px;
}

.paste-upload-text,
.paste-upload-instruction {
    background-color: #f8f8f8;
    border-radius: 10px;
    padding: 10px;
    color: #484848;
    margin-top: 5px;
    overflow-wrap: break-word;
}

.paste-upload-text {
    font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/components/modals/uploads/paste_upload/PasteUpload.css"],"names":[],"mappings":"AAAA,oBAAoB;;AAEpB;IACI,yBAAyB;IACzB,mBAAmB;IACnB,aAAa;IACb,YAAY;IACZ,eAAe;IACf,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,2BAA2B;AAC/B;;AAEA;IACI,eAAe;IACf,WAAW;IACX,YAAY;AAChB;;AAEA;;IAEI,yBAAyB;IACzB,mBAAmB;IACnB,aAAa;IACb,cAAc;IACd,eAAe;IACf,yBAAyB;AAC7B;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":["/* PasteUpload.css */\n\n.paste-upload-modal {\n    background-color: #484848;\n    border-radius: 10px;\n    padding: 10px;\n    color: white;\n    position: fixed;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    z-index: 1000;\n}\n\n.modal-header {\n    display: flex;\n    justify-content: flex-start;\n}\n\n.close-icon {\n    cursor: pointer;\n    width: 24px;\n    height: 24px;\n}\n\n.paste-upload-text,\n.paste-upload-instruction {\n    background-color: #f8f8f8;\n    border-radius: 10px;\n    padding: 10px;\n    color: #484848;\n    margin-top: 5px;\n    overflow-wrap: break-word;\n}\n\n.paste-upload-text {\n    font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
