import React, { useState } from "react";
import "./ClientsRow.css";
import ActionTray from "../../modals/action_tray/ActionTray";
import ExpandCell from "../expand_cell/ExpandCell";
import OrganizationsTable from "../organizations/Organizations";
import { ActionTrayIcons } from "../../../assets/icons";
import { useDispatch } from "react-redux";
import { toggleBadganatorModal } from "../../../store/badganator/badganatorSlice";
import { fetchAllClientAccounts, setLoading, toggleClientAccountsModal } from "../../../store/clients/clientsSlice";
import { getClientBillingDetails } from "../../../store/clients/clientBillingDetailsSlice";

const ClientsRow = ({ client, userRoles }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const dispatch = useDispatch();

  const handleShowBadganator = () => {
    dispatch(toggleBadganatorModal(client.id));
  };

  const handleClientPricing = async (client) => {
    await dispatch(getClientBillingDetails({clientId: client.id}));
  };

  const handleClientAccountDetails = async (client) => {
    await dispatch(fetchAllClientAccounts({clientId: client.id})).then(() => {
      dispatch(toggleClientAccountsModal(true));
    });
  };

  const iconsData = [
    {
      icon: ActionTrayIcons.BadgeIcon,
      alt: "Badge",
      action: handleShowBadganator,
      permission: "Freddy.Badganator"
    },
    {
      icon: ActionTrayIcons.DollarSignIcon,
      alt: "Client Details",
      action: () => handleClientPricing(client),
      permission: "Labb.Client.Pricing",
    },
    {
      icon: ActionTrayIcons.PersonIcon,
      alt: "Client Account Details",
      action: () => handleClientAccountDetails(client),
      permission: "Labb.Client.Management",
    },
  ];

  return (
      <>
        <div className="client-row">
          <div className="client-info">
            <div className="client-company-cell">{client.company}</div>
            <div className="client-address-cell">{client.address}</div>
          </div>
          <div className="client-action">
            {client.has_organizations && (
                <div className="client-expand-cell">
                  <ExpandCell isExpanded={isExpanded} toggleExpand={() => setIsExpanded(!isExpanded)} />
                </div>
            )}
            <div className="client-action-tray-cell">
              <ActionTray iconsData={iconsData} rowData={client} userRoles={userRoles} />
            </div>
          </div>
        </div>
        {isExpanded && (
            <div className="client-expanded-row">
              <div className="client-expanded-content">
                <OrganizationsTable clientId={client.id} />
              </div>
            </div>
        )}
      </>
  );
};

export default ClientsRow;
