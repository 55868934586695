
import React, { useState, useEffect } from "react";
import './Location.css';
import { fetchDonorDetails } from "../../../../../services/api/v1/donorService";
import { GenericIcons } from "../../../../../assets/icons";
import { IconButton } from "../../../../buttons/icon_button/IconButton";
import { useSelector } from "react-redux";
import {convertSpecimenIdToHash, fetchLocationDetails} from "../../../../../services/api/v1/screenService";

const Location = ({ device_id, locationDetails, onLocationChange }) => {
    const accessToken = sessionStorage.getItem('accessToken');
    const [isEditing, setIsEditing] = useState(false);
    const [locations, setLocations] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [offset, setOffset] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const [showDropdown, setShowDropdown] = useState(false);
    const LIMIT = 20;
    const [selectedLocation, setSelectedLocation] = useState(locationDetails);
    const [originalLocation, setOriginalLocation] = useState(locationDetails);

    useEffect(() => {
        if (isEditing) {
            const accessToken = sessionStorage.getItem('accessToken');
            fetchLocationDetails(device_id, accessToken)
                .then(response => {
                    setLocations(response);
                })
                .catch(error => {
                    console.error("Error fetching donor details:", error);
                });
        }
    }, [isEditing, accessToken]);

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleScroll = (e) => {
        const dropdown = e.target;
        if (dropdown.scrollHeight - dropdown.scrollTop === dropdown.clientHeight && hasMore) {
            setOffset(prev => prev + LIMIT);
        }
    };

    const formatSelectLocation = (location) => {
        console.log("location", location);
        return {
            location_name: location.location_name,
            location_id: location.location_id,
            original_location_id: originalLocation.location_id,

        }
    }

    const handleLocationSelect = (location) => {
        const formattedLocation = formatSelectLocation(location);
        setSelectedLocation(formattedLocation);
        onLocationChange(formattedLocation);
        setIsEditing(false);
    };

    const toggleEdit = (e) => {
        e.preventDefault();
        setIsEditing(!isEditing);
    };

    return (
        <section>
            <div className="location_section">
                <div className="location_section_header">
                    <p className="location_section_header_text">Location</p>
                    {!isEditing
                        ? <IconButton onClick={toggleEdit} defaultIcon={GenericIcons.EditIcon} hoverIcon={GenericIcons.EditIcon} />
                        : <IconButton onClick={toggleEdit} defaultIcon={GenericIcons.CloseIcon} hoverIcon={GenericIcons.ExitIcon} />
                    }
                </div>
                <div className="dark_grey_strip"/>
                <div className="location_info_section">
                    {isEditing ? (
                        <>
                            <input
                                className="location-searchable-dropdown"
                                type="text"
                                value={searchQuery}
                                onChange={handleSearchChange}
                                onFocus={() => setShowDropdown(true)}
                                onBlur={() => setTimeout(() => setShowDropdown(false), 150)} // A delay to allow the dropdown item to be clicked
                                placeholder="Search locations..."
                            />
                            {showDropdown && (
                                <div className="dropdown-content" onScroll={handleScroll}>
                                    {locations && locations.length > 0 ? (
                                        locations
                                            .filter(location =>
                                                `${location?.location_name}`
                                                    .toLowerCase()
                                                    .includes(searchQuery.toLowerCase())
                                            )
                                            .map(location => (
                                                <div className="location_dropdown_row" key={location?.location_id}
                                                     onClick={() => handleLocationSelect(location)}>
                                                    <p className="location_dropdown_row_text">
                                                        {location?.location_name}
                                                    </p>
                                                </div>
                                            ))
                                    ) : (
                                        <div className="location_dropdown_row">
                                            <p className="location_dropdown_row_text">No locations found</p>
                                        </div>
                                    )}
                                </div>
                            )}
                        </>
                    ) : (
                        <p className="location_dropdown_text">{selectedLocation?.location_name}</p>
                    )}
                </div>
            </div>
        </section>
    );
};


export default Location;