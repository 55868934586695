// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.panel-details-container {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.panel-details-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}

.panel-details-table th,
.panel-details-table td {
    padding: 10px;
    text-align: left;
    border: 1px solid #ddd;
}

.panel-details-table th {
    background-color: #f4f4f4;
    font-weight: bold;
}

.panel-details-table tr:hover {
    background-color: #f1f1f1;
}
`, "",{"version":3,"sources":["webpack://./src/components/clients/panel_details/PanelDetails.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,yBAAyB;IACzB,kBAAkB;IAClB,wCAAwC;AAC5C;;AAEA;IACI,WAAW;IACX,yBAAyB;IACzB,gBAAgB;AACpB;;AAEA;;IAEI,aAAa;IACb,gBAAgB;IAChB,sBAAsB;AAC1B;;AAEA;IACI,yBAAyB;IACzB,iBAAiB;AACrB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".panel-details-container {\n    padding: 20px;\n    background-color: #f9f9f9;\n    border-radius: 8px;\n    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);\n}\n\n.panel-details-table {\n    width: 100%;\n    border-collapse: collapse;\n    margin-top: 10px;\n}\n\n.panel-details-table th,\n.panel-details-table td {\n    padding: 10px;\n    text-align: left;\n    border: 1px solid #ddd;\n}\n\n.panel-details-table th {\n    background-color: #f4f4f4;\n    font-weight: bold;\n}\n\n.panel-details-table tr:hover {\n    background-color: #f1f1f1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
