// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pick-list-item {
    background: #FFF;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Center-align the content */
    text-align: center; /* Center-align the text */
    opacity: 100%;
}

.item-quantity {
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
}

.product-prefix {
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
}

.product-panel {
    font-size: 1em;
    color: #777;
}
`, "",{"version":3,"sources":["webpack://./src/components/orders/product_pick_list/pick_list_item/PickListItem.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,wCAAwC;IACxC,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB,EAAE,6BAA6B;IACtD,kBAAkB,EAAE,0BAA0B;IAC9C,aAAa;AACjB;;AAEA;IACI,cAAc;IACd,iBAAiB;IACjB,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,cAAc;IACd,WAAW;AACf","sourcesContent":[".pick-list-item {\n    background: #FFF;\n    border-radius: 8px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n    padding: 16px;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center; /* Center-align the content */\n    text-align: center; /* Center-align the text */\n    opacity: 100%;\n}\n\n.item-quantity {\n    font-size: 2em;\n    font-weight: bold;\n    margin-bottom: 10px;\n    color: #333;\n}\n\n.product-prefix {\n    font-size: 1.5em;\n    font-weight: bold;\n    margin-bottom: 10px;\n    color: #333;\n}\n\n.product-panel {\n    font-size: 1em;\n    color: #777;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
