// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notification-list-container {
    max-width: 280px; /* Slightly less than modal width */
    margin: 0 auto;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
}

.notification-item:not(:last-child) {
    margin-bottom: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/modals/notifications/notificiations_list/NotificiationsList.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB,EAAE,mCAAmC;IACrD,cAAc;IACd,sBAAsB;IACtB,mBAAmB;IACnB,2CAA2C;AAC/C;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".notification-list-container {\n    max-width: 280px; /* Slightly less than modal width */\n    margin: 0 auto;\n    background-color: #fff;\n    border-radius: 10px;\n    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);\n}\n\n.notification-item:not(:last-child) {\n    margin-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
