// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* General styling for the Shopify Header */
.shopify-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    padding: 0 20px;
    background-color: #f5f5f5;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

/* Left section for logo */
.header-left .logo-container {
    display: flex;
    align-items: center;
}

.header-logo {
    width: 120px;
    height: auto;
    object-fit: contain;
}

/* Right section for navigation and user card */
.header-right {
    display: flex;
    align-items: center;
    gap: 20px;
}

/* Navbar container for the icons */
.navbar-container {
    display: flex;
    gap: 16px;
}

.nav-item {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.nav-icon {
    width: 30px;
    height: 30px;
    transition: transform 0.2s ease-in-out;
}

.nav-item:hover .nav-icon {
    transform: scale(1.1);
}

/* User icon card section */
.user-icon-card {
    display: flex;
    align-items: center;
    cursor: pointer;
}

/* Hover effects for clickable icons */
.nav-item:hover {
    background-color: #e4e4e4;
    border-radius: 8px;
    padding: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/shopify_header/ShopifyHeader.css"],"names":[],"mappings":"AAAA,2CAA2C;AAC3C;IACI,WAAW;IACX,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,YAAY;IACZ,eAAe;IACf,yBAAyB;IACzB,0CAA0C;AAC9C;;AAEA,0BAA0B;AAC1B;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,mBAAmB;AACvB;;AAEA,+CAA+C;AAC/C;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;AACb;;AAEA,mCAAmC;AACnC;IACI,aAAa;IACb,SAAS;AACb;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,sCAAsC;AAC1C;;AAEA;IACI,qBAAqB;AACzB;;AAEA,2BAA2B;AAC3B;IACI,aAAa;IACb,mBAAmB;IACnB,eAAe;AACnB;;AAEA,sCAAsC;AACtC;IACI,yBAAyB;IACzB,kBAAkB;IAClB,YAAY;AAChB","sourcesContent":["/* General styling for the Shopify Header */\n.shopify-header {\n    width: 100%;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    height: 60px;\n    padding: 0 20px;\n    background-color: #f5f5f5;\n    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);\n}\n\n/* Left section for logo */\n.header-left .logo-container {\n    display: flex;\n    align-items: center;\n}\n\n.header-logo {\n    width: 120px;\n    height: auto;\n    object-fit: contain;\n}\n\n/* Right section for navigation and user card */\n.header-right {\n    display: flex;\n    align-items: center;\n    gap: 20px;\n}\n\n/* Navbar container for the icons */\n.navbar-container {\n    display: flex;\n    gap: 16px;\n}\n\n.nav-item {\n    display: flex;\n    align-items: center;\n    cursor: pointer;\n}\n\n.nav-icon {\n    width: 30px;\n    height: 30px;\n    transition: transform 0.2s ease-in-out;\n}\n\n.nav-item:hover .nav-icon {\n    transform: scale(1.1);\n}\n\n/* User icon card section */\n.user-icon-card {\n    display: flex;\n    align-items: center;\n    cursor: pointer;\n}\n\n/* Hover effects for clickable icons */\n.nav-item:hover {\n    background-color: #e4e4e4;\n    border-radius: 8px;\n    padding: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
