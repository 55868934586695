// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Container for the entire customer details section */
.customer-details-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* Individual customer card */
.customer-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 15px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}

/* Headings for sections */
.customer-card h1 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #333;
}

/* Paragraph styling for customer details */
.customer-card p {
    margin: 5px 0;
    font-size: 16px;
    color: #666;
}

/* Bold styling for labels */
.customer-card p span.label {
    font-weight: bold;
    color: #333;
}

/* Hover effect on customer card */
.customer-card:hover {
    border-color: #999;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.15);
}
`, "",{"version":3,"sources":["webpack://./src/pages/shopify_home/ShopifyHome.css"],"names":[],"mappings":"AAAA,sDAAsD;AACtD;IACI,gBAAgB;IAChB,cAAc;IACd,aAAa;IACb,yBAAyB;IACzB,kBAAkB;IAClB,yCAAyC;AAC7C;;AAEA,6BAA6B;AAC7B;IACI,sBAAsB;IACtB,sBAAsB;IACtB,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,wCAAwC;AAC5C;;AAEA,0BAA0B;AAC1B;IACI,eAAe;IACf,mBAAmB;IACnB,WAAW;AACf;;AAEA,2CAA2C;AAC3C;IACI,aAAa;IACb,eAAe;IACf,WAAW;AACf;;AAEA,4BAA4B;AAC5B;IACI,iBAAiB;IACjB,WAAW;AACf;;AAEA,kCAAkC;AAClC;IACI,kBAAkB;IAClB,0CAA0C;AAC9C","sourcesContent":["/* Container for the entire customer details section */\n.customer-details-container {\n    max-width: 800px;\n    margin: 0 auto;\n    padding: 20px;\n    background-color: #f9f9f9;\n    border-radius: 8px;\n    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\n}\n\n/* Individual customer card */\n.customer-card {\n    background-color: #fff;\n    border: 1px solid #ddd;\n    border-radius: 8px;\n    padding: 15px;\n    margin-bottom: 15px;\n    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);\n}\n\n/* Headings for sections */\n.customer-card h1 {\n    font-size: 24px;\n    margin-bottom: 10px;\n    color: #333;\n}\n\n/* Paragraph styling for customer details */\n.customer-card p {\n    margin: 5px 0;\n    font-size: 16px;\n    color: #666;\n}\n\n/* Bold styling for labels */\n.customer-card p span.label {\n    font-weight: bold;\n    color: #333;\n}\n\n/* Hover effect on customer card */\n.customer-card:hover {\n    border-color: #999;\n    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.15);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
