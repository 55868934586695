import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './ClientAccountsModal.css';
import { IconButton } from "../../buttons/icon_button/IconButton";
import { GenericIcons } from "../../../assets/icons";
import { toggleClientAccountsModal } from "../../../store/clients/clientsSlice";
import Select from 'react-select';
import SendIcon from "../../../assets/icons/Send.svg";
import {sendEmailInvite} from "../../../services/api/v1/emailService";
import {fetchSendInviteEmail} from "../../../store/emails/emailsSlice";

const ClientAccountsModal = () => {
    const isVisible = useSelector(state => state.clients.isAccountsModalVisible);
    const clientAccounts = useSelector(state => state.clients.clientAccountsDetails);
    const clientCollectors = useSelector(state => state.screeners.screeners);
    const dispatch = useDispatch();
    const [selectedCollector, setSelectedCollector] = useState(null);
    const [selectedPermission, setSelectedPermission] = useState(null);

    const handleClose = () => {
        dispatch(toggleClientAccountsModal(false));
    };



    if (!isVisible) return null;

    // Group accounts by laboratory_client_name
    const groupedAccounts = clientAccounts.reduce((acc, account) => {
        if (!acc[account.laboratory_client_name]) {
            acc[account.laboratory_client_name] = [];
        }
        acc[account.laboratory_client_name].push(account);
        return acc;
    }, {});

    const collectorOptions = clientCollectors.map(collector => ({
        value: collector.collector_id,
        label: `${collector.collector_first_name} ${collector.collector_last_name}`
    }));

    const permissionOptions = [
        { value: 'collector_super_admin', label: 'Collector Super Admin' },
        { value: 'collector_admin', label: 'Collector Admin' },
        { value: 'collector_user', label: 'Collector User' }
    ];


    const handleSendEmail = () => {
        if (selectedCollector && selectedPermission) {
            dispatch(fetchSendInviteEmail({
                collectorId: selectedCollector.value,
                permissionLevel: selectedPermission.value,
            }));
        }
    };


    return (
        <div className="pricing-modal-overlay">
            <div className="pricing-modal">
                <div className="pricing-modal-header">
                    <IconButton hoverIcon={GenericIcons.ExitIcon} defaultIcon={GenericIcons.CloseIcon} onClick={handleClose} />
                </div>
                <div className="pricing-modal-content">
                    <div className="client-accounts-section">
                        <h2>Client Accounts</h2>
                        {Object.keys(groupedAccounts).map((clientName, index) => (
                            <div key={index} className="client-group">
                                <h3>{clientName}</h3>
                                <ul>
                                    {groupedAccounts[clientName].map((account, idx) => (
                                        <li key={idx}>{account.laboratory_account_number}</li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>
                    <div className="client-collectors-section">
                        <h2>Freddy Public Portal Invite</h2>
                        {clientCollectors.length > 0 ? (
                            <div className="collector-select-container">
                                <Select
                                    options={collectorOptions}
                                    onChange={setSelectedCollector}
                                    placeholder="Select a collector..."
                                />
                                <Select
                                    options={permissionOptions}
                                    onChange={setSelectedPermission}
                                    placeholder="Select a permission level..."
                                />
                                <IconButton
                                    hoverIcon={GenericIcons.SendIcon}
                                    defaultIcon={GenericIcons.SendIcon}
                                    onClick={handleSendEmail}
                                    disabled={!selectedCollector || !selectedPermission}
                                />
                            </div>
                        ) : (
                            <p>No collectors found for this client.</p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ClientAccountsModal;