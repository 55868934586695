import React from 'react';

const FormStepNavigation = ({ handleNext, handlePrevious, isLastStep = false }) => (
    <div className="button-group">
        {handlePrevious && <button type="button" className="previous-button" onClick={handlePrevious}>Previous</button>}
        <button type="button" className="next-button full-width" onClick={handleNext}>
            {isLastStep ? 'Submit' : 'Next'}
        </button>
    </div>
);

export default FormStepNavigation;
