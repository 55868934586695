const dev_reign = {
    url: {
        FREDDY_API_URL: "https://reign.dev.freddy.backend.labbvision.com/",
        REACT_APP_REDIRECT_URL: "https://reign.dev.freddy.app.labbvision.com/",
        SHOPIFY_TOKEN_URL: "https://accounts.shopify.com/admin/oauth/authorize",
        UPS_TOKEN_URL: "https://onlinetools.ups.com/security/v1/oauth/authorize",
        ZOHO_TOKEN_URL: "https://accounts.zoho.com/oauth/v2/auth",
    },
};

const dev_labb = {
    url: {
        FREDDY_API_URL: "https://labb.dev.freddy.backend.labbvision.com/",
        REACT_APP_REDIRECT_URL: "https://labb.dev.freddy.app.labbvision.com/",
        SHOPIFY_TOKEN_URL: "https://accounts.shopify.com/admin/oauth/authorize",
        UPS_TOKEN_URL: "https://onlinetools.ups.com/security/v1/oauth/authorize",
        ZOHO_TOKEN_URL: "https://accounts.zoho.com/oauth/v2/auth",
    },
};

const dev_remote = {
    url: {
        FREDDY_API_URL: "https:://remote.dev.freddy.backend.labbvision.com/",
        REACT_APP_REDIRECT_URL: "https:://remote.dev.freddy.app.labbvision.com/",
        SHOPIFY_TOKEN_URL: "https://labb-stage.myshopify.com/admin/oauth/authorize",
        UPS_TOKEN_URL: "https://onlinetools.ups.com/security/v1/oauth/authorize",
        ZOHO_TOKEN_URL: "https://accounts.zoho.com/oauth/v2/auth",
    },
};

const dev = {
    url: {
        FREDDY_API_URL: "https://dev.freddy.backend.labbvision.com",
        REACT_APP_REDIRECT_URL: "https://dev.freddy.app.labbvision.com/",
        SHOPIFY_TOKEN_URL: "https://accounts.shopify.com/admin/oauth/authorize",
        UPS_TOKEN_URL: "https://onlinetools.ups.com/security/v1/oauth/authorize",
        ZOHO_TOKEN_URL: "https://accounts.zoho.com/oauth/v2/auth",
    },
};

const uat = {
    url: {
        FREDDY_API_URL: "https://uat.freddy.backend.labbvision.com",
        REACT_APP_REDIRECT_URL: "https://uat.freddy.app.labbvision.com/",
        SHOPIFY_TOKEN_URL: "https://accounts.shopify.com/admin/oauth/authorize",
        UPS_TOKEN_URL: "https://onlinetools.ups.com/security/v1/oauth/authorize",
        ZOHO_TOKEN_URL: "https://accounts.zoho.com/oauth/v2/auth",
    },
};

const prod = {
    url: {
        FREDDY_API_URL: "https://freddy.backend.labbvision.com",
        REACT_APP_REDIRECT_URL: "https://freddy.app.labbvision.com/",
        SHOPIFY_TOKEN_URL: "https://accounts.shopify.com/admin/oauth/authorize",
        UPS_TOKEN_URL: "https://onlinetools.ups.com/security/v1/oauth/authorize",
        ZOHO_TOKEN_URL: "https://accounts.zoho.com/oauth/v2/auth",
    },
};

let config = dev_remote;

console.log(process.env.NODE_ENV);

if (process.env.NODE_ENV === "development") {
    if (process.env.REACT_APP_REDIRECT_URL === "dev-reign") {
        config = dev_reign;
    } else if (process.env.REACT_APP_REDIRECT_URL === "dev-labb") {
        config = dev_labb;
    } else if (process.env.REACT_APP_REDIRECT_URL === "dev-remote") {
        config = dev_remote;
    } else if (process.env.REACT_APP_REDIRECT_URL === "dev") {
        config = dev;
    } else {
        // throw new Error('Invalid development environment');
    }
} else if (process.env.NODE_ENV === "production") {
    console.log(process.env.REACT_APP_REDIRECT_URL);
    if (process.env.REACT_APP_REDIRECT_URL === "uat") {
        config = uat;
    } else if (process.env.REACT_APP_REDIRECT_URL === "prod") {
        config = prod;
    } else {
        // throw new Error('Invalid prod/uat environment');
    }
} else {
    // throw new Error('Invalid environment');
}

const statusTable = {
    'Presumptive Positive': '100',
    'Lab Positive': '125',
    'MRO Positive': '155',
    'Negative': '200',
    'Lab Negative': '225',
    'MRO Negative': '255',
    'Invalid': '300',
    'Lab Invalid': '325',
    'MRO Invalid': '355',
    'Test Paused': '700',
    'Collection Completed': '800',
    'Lab Test Creating': '1000',
    'Lab Test Creating Error': '1100',
    'Lab Test Created': '1200',
    'Lab Test Transit Delayed': '1300',
    'Lab Test Transit': '1350',
    'Lab Test Received': '1400',
    'Lab Test Delayed': '1500',
    'Lab Test Tested': '1600',
    'Lab Results Received': '1700',
    'MRO Results Requested': '2000',
    'MRO Results Received': '2100'
};

const serviceLevels = [
    "NONE",
    "Rapid Only",
    "Rapid + Tox",
    "Rapid + Tox + MRO (POS ONLY)",
    "Screen",
    "Rapid + Tox + MRO (ALL)",
    "Tox + MRO (ALL)",
    "Tox + MRO (POS ONLY)",
    "DOT",
];

const selectedNavBarIndex = 1;

export {config, statusTable, serviceLevels, selectedNavBarIndex};
