// freddy-react/src/pages/user/login/Login.js

import React from 'react';
import LoginComponent from '../../../components/user/login/Login';
import './Login.css';

export default function LoginPage({ instance }) {
    return (
        <div className="login-page">
            <main className="login-main-content">
                <LoginComponent instance={instance}/>
            </main>
        </div>
    );
}

