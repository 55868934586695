// freddy-react/src/components/user/login/Login.js

import React from 'react';
import './Login.css';
import { PrimaryButton } from '../../buttons/primary_button/PrimaryButton';
import { loginRequest } from '../../../utils/authConfig';
import { useDispatch } from "react-redux";
import {setIsLoggedIn} from "../../../store/user/userSlice";

export default function Login({ instance }) {

    const dispatch = useDispatch();

    const handleLogin = async () => {
        try {
            // Initiate the login process with Azure and retrieve the auth code
            const response = await instance.loginPopup(loginRequest);

            // console.log("Raw response:", response);  // Log the raw response
            const accessToken = response.accessToken;

            // Store the access token in sessionStorage for persistence across refreshes
            sessionStorage.setItem('accessToken', accessToken);


        } catch (error) {
            console.error("Error during login:", error);
            dispatch(setIsLoggedIn(false));
        }
    };

    return (
        <div className="login-wrapper">
            <div className="login-card">
                <img
                    className="login-logo"
                    src="https://static.wixstatic.com/media/40f727_b17ccfdda56f41e494a6586affdeae2e~mv2.png"
                    alt="Labb Logo"
                />
                <div className="login-form">
                    {/* Login button that triggers the handleLogin function when clicked */}
                    <PrimaryButton type="submit" className="login-btn" onClick={handleLogin}>
                        LOGIN
                    </PrimaryButton>
                </div>
            </div>
        </div>
    );
}
