// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 11000; /* High z-index to ensure it's on top */
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 850px; /* Adjust width as necessary */
    position: relative;
    display: flex;
    flex-direction: column;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    border: none;
    background: none;
}

/* Additional Styling for better visibility and interaction */
button:focus {
    outline: none; /* Removes default focus outline */
}

button:active {
    transform: scale(0.98); /* Subtle click effect */
}
`, "",{"version":3,"sources":["webpack://./src/components/modals/link_accounts/LinkAccountsModal.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,oCAAoC,EAAE,sCAAsC;IAC5E,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,cAAc,EAAE,uCAAuC;AAC3D;;AAEA;IACI,iBAAiB;IACjB,aAAa;IACb,kBAAkB;IAClB,wCAAwC;IACxC,YAAY,EAAE,8BAA8B;IAC5C,kBAAkB;IAClB,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,eAAe;IACf,YAAY;IACZ,gBAAgB;AACpB;;AAEA,6DAA6D;AAC7D;IACI,aAAa,EAAE,kCAAkC;AACrD;;AAEA;IACI,sBAAsB,EAAE,wBAAwB;AACpD","sourcesContent":[".modal-overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 11000; /* High z-index to ensure it's on top */\n}\n\n.modal-content {\n    background: white;\n    padding: 20px;\n    border-radius: 8px;\n    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n    width: 850px; /* Adjust width as necessary */\n    position: relative;\n    display: flex;\n    flex-direction: column;\n}\n\n.close-button {\n    position: absolute;\n    top: 10px;\n    right: 10px;\n    cursor: pointer;\n    border: none;\n    background: none;\n}\n\n/* Additional Styling for better visibility and interaction */\nbutton:focus {\n    outline: none; /* Removes default focus outline */\n}\n\nbutton:active {\n    transform: scale(0.98); /* Subtle click effect */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
