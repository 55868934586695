import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchPanelsByLabb } from "../../services/api/v1/panelsService";

// Define the async thunk for fetching panels by organization ID
export const fetchPanelsByLabbThunk = createAsyncThunk(
    'panels/fetchPanelsByLabbThunk',
    async () => {
        const response = await fetchPanelsByLabb();
        return response;
    }
);

const panelSlice = createSlice({
    name: 'panels',
    initialState: {
        panels: [],
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchPanelsByLabbThunk.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchPanelsByLabbThunk.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.panels = action.payload || [];
            })
            .addCase(fetchPanelsByLabbThunk.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export default panelSlice.reducer;
