import React from 'react';
import './Tab.css';

const Tab = ({ label, active, onClick }) => {
    return (
        <button
            className={`tab-button ${active ? 'active' : ''}`}
            onClick={onClick}
        >
            {label}
        </button>
    );
};

export default Tab;
