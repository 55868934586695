// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* LabbLogin component styles */
.login-form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #ECEDED;
    padding: 1rem;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.login-input {
    flex: 1 1;
    margin: 0.5rem 0;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    max-width: calc(95% - 0.5rem); /* Limit input width to 50% minus margin */
}

.email-input::placeholder,
.password-input::placeholder {
    color: #aaa;
}

/* Responsive design */
@media (max-width: 1050px) {
    .login-form {
        flex-direction: column;
        align-items: stretch; /* Stretch to full width */
        padding: 0.5rem;

    }

    .login-input {
        margin: 0.5rem 0;
        max-width: 100%; /* Full width on smaller screens */
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/autobot/labb_login/LabbLogin.css"],"names":[],"mappings":"AAAA,+BAA+B;AAC/B;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,yBAAyB;IACzB,aAAa;IACb,kBAAkB;IAClB,wCAAwC;AAC5C;;AAEA;IACI,SAAO;IACP,gBAAgB;IAChB,eAAe;IACf,sBAAsB;IACtB,kBAAkB;IAClB,eAAe;IACf,6BAA6B,EAAE,0CAA0C;AAC7E;;AAEA;;IAEI,WAAW;AACf;;AAEA,sBAAsB;AACtB;IACI;QACI,sBAAsB;QACtB,oBAAoB,EAAE,0BAA0B;QAChD,eAAe;;IAEnB;;IAEA;QACI,gBAAgB;QAChB,eAAe,EAAE,kCAAkC;IACvD;AACJ","sourcesContent":["/* LabbLogin component styles */\n.login-form {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    background-color: #ECEDED;\n    padding: 1rem;\n    border-radius: 4px;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n}\n\n.login-input {\n    flex: 1;\n    margin: 0.5rem 0;\n    padding: 0.5rem;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n    font-size: 16px;\n    max-width: calc(95% - 0.5rem); /* Limit input width to 50% minus margin */\n}\n\n.email-input::placeholder,\n.password-input::placeholder {\n    color: #aaa;\n}\n\n/* Responsive design */\n@media (max-width: 1050px) {\n    .login-form {\n        flex-direction: column;\n        align-items: stretch; /* Stretch to full width */\n        padding: 0.5rem;\n\n    }\n\n    .login-input {\n        margin: 0.5rem 0;\n        max-width: 100%; /* Full width on smaller screens */\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
