import React from 'react';
import './Footer.css';
import ReadTiles from "./read_tiles/ReadTiles";
import {ws} from "../../services";

export default function Footer() {


    return (
        <div>
            <div className="footer-main-body">
                <ReadTiles />
            </div>
        </div>
    )
}
