import React, {useState, useEffect} from "react";
import './TestingReason.css';
import {GenericIcons} from "../../../../../assets/icons";
import {fetchTestingReasons} from "../../../../../services/api/v1/testingReasonsService";
import {IconButton} from "../../../../buttons/icon_button/IconButton";
import {useSelector} from "react-redux";

const TestingReason = ({device_id, testingReasonDetails, onTestingReasonChange}) => {
    const accessToken = sessionStorage.getItem("accessToken");
    const [isEditing, setIsEditing] = useState(false);
    const [testingReasons, setTestingReasons] = useState([]);
    const [currentTestingReason, setCurrentTestingReason] = useState(testingReasonDetails);
    const [hasMore, setHasMore] = useState(true);
    const [offset, setOffset] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const [showDropdown, setShowDropdown] = useState(false);
    const screenDetails = useSelector(state => state.screen.screen);
    const LIMIT = 20;

    const originalReason = screenDetails[0]?.testing_reason;


    const [originalTestingReason, setOriginalTestingReason] = useState(originalReason);


    useEffect(() => {
        setOriginalTestingReason(originalReason);
    }, [originalReason]);

    useEffect(() => {
        if (isEditing) {
            fetchTestingReasons(accessToken, offset, LIMIT)
                .then(reasons => {

                    setTestingReasons(prevReasons => [...prevReasons, ...reasons]);

                    const matchedOriginalReason = reasons.find(
                        reason => reason.testing_reason === originalTestingReason
                    );
                    if (matchedOriginalReason) {
                        setOriginalTestingReason(matchedOriginalReason);
                    }
                })
                .catch(error => {
                    console.error("Error fetching testing reasons:", error);
                });
        }
    }, [isEditing, accessToken, offset]);



    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleScroll = (e) => {
        const dropdown = e.target;
        if (dropdown.scrollHeight - dropdown.scrollTop === dropdown.clientHeight && hasMore) {
            setOffset(prev => prev + LIMIT);
        }
    };

    const formatSelectedTestingReason = (reason) => {
        console.log("reason", reason);
        console.log("originalTestingReason", originalTestingReason);
        return {
            testing_reason_id: reason?.testing_reason_id,
            original_testing_reason_id: originalTestingReason?.testing_reason_id,
            testing_reason: reason?.testing_reason
        }
    }

    const handleTestingReasonSelect = (reason) => {
        const formattedReason = formatSelectedTestingReason(reason);
        setCurrentTestingReason(formattedReason);
        onTestingReasonChange(formattedReason);
        setIsEditing(false);
    };

    const toggleEdit = (e) => {
        e.preventDefault();
        setIsEditing(!isEditing);
    };


    return (
        <section>
            <div className="testing-reason">
                <div className="testing-reason_header">
                    <p className="testing-reason_header_text">Testing Reason</p>
                    {!isEditing
                        ? <IconButton onClick={toggleEdit} defaultIcon={GenericIcons.EditIcon}
                                      hoverIcon={GenericIcons.EditIcon}/>
                        : <IconButton onClick={toggleEdit} defaultIcon={GenericIcons.CloseIcon}
                                      hoverIcon={GenericIcons.ExitIcon}/>
                    }
                </div>
                <div className="dark_grey_strip"/>
                <div className="testing-reason_info_section">
                    {isEditing ? (
                        <>
                            <input
                                className="testing-reason-searchable-dropdown"
                                type="text"
                                value={searchQuery}
                                onChange={handleSearchChange}
                                onFocus={() => setShowDropdown(true)}
                                onBlur={() => setTimeout(() => setShowDropdown(false), 150)}
                                placeholder="Search testing reason..."
                            />

                            {showDropdown && (
                                <div className="dropdown-content" onScroll={handleScroll}>
                                    {testingReasons && testingReasons.length > 0 ? (
                                        testingReasons
                                            .filter(reason =>
                                                reason?.testing_reason
                                                    .toLowerCase()
                                                    .includes(searchQuery.toLowerCase())
                                            )
                                            .map(reason => (
                                                <div className="testing-reason_dropdown_row" key={reason.index}
                                                     onClick={() => handleTestingReasonSelect(reason)}>
                                                    <p className="testing-reason_dropdown_row_text">
                                                        {reason?.testing_reason}
                                                    </p>
                                                </div>
                                            ))
                                    ) : (
                                        <div className="testing-reason_dropdown_row">
                                            <p className="testing-reason_dropdown_row_text">No testing reasons found</p>
                                        </div>
                                    )}
                                </div>
                            )}
                        </>
                    ) : (
                        <p className="testing-reason_dropdown_text">{currentTestingReason?.testing_reason}</p>
                    )}
                </div>
            </div>
        </section>
    );
};

export default TestingReason;