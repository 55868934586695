

import axios from "axios";
import {config} from "../../components/Constants";

export const makeGETRequest = async (url, token) => {
    const base_url = config.url.FREDDY_API_URL;
    const headers = token ? { Authorization: 'Bearer ' + token } : {};

    try {
        const response = await axios({
            method: "GET",
            baseURL: base_url,
            url: url,
            headers: headers,
        });

        return response.data;

    } catch (error) {
        console.error(error);
        const errorMessage = error.response ? error.response.data.message : error.message;
        throw new Error(errorMessage);
    }
};

export const makePOSTRequest = async (url, data, accessToken) => {
    const base_url = config.url.FREDDY_API_URL;
    const headers = {
        'Content-Type': 'multipart/form-data',
        ...(accessToken && { Authorization: 'Bearer ' + accessToken })
    };

    try {
        const response = await axios({
            method: "POST",
            baseURL: base_url,
            url: url,
            data: data,
            headers: headers,
        });

        return response.data;

    } catch (error) {
        console.error(error);
        const errorMessage = error.response ? error.response.data.message : error.message;
        throw new Error(errorMessage);
    }
};

export const makeUnAuthPOSTRequest = async (url, data) => {
    const base_url = config.url.FREDDY_API_URL;
    const headers = {
        'Content-Type': 'multipart/form-data',
    };

    try {
        const response = await axios({
            method: "POST",
            baseURL: base_url,
            url: url,
            data: data,
            headers: headers,
        });

        return response.data;

    } catch (error) {
        console.error(error);
        const errorMessage = error.response ? error.response.data.message : error.message;
        throw new Error(errorMessage);
    }
};

export const makeURLEncodedPOSTRequest = async (url, data, accessToken) => {
    const base_url = config.url.FREDDY_API_URL;
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
    };

    try {
        const response = await axios({
            method: "POST",
            baseURL: base_url,
            url: url,
            data: data,
            headers: headers,
        });

        return response.data;

    } catch (error) {
        console.error(error);
        const errorMessage = error.response ? error.response.data.message : error.message;
        throw new Error(errorMessage);
    }
};