// Helper function to group and sort data
export const groupAndSortData = (data) => {
    // Group data by client_name and location_name
    const groupedData = data.reduce((acc, currentValue) => {
        const key = `${currentValue.client_name}-${currentValue.location_name}`;
        if (!acc[key]) {
            acc[key] = [];
        }
        acc[key].push(currentValue);
        return acc;
    }, {});

    // Sort each group by product_name and collected_on desc
    Object.keys(groupedData).forEach(key => {
        groupedData[key].sort((a, b) => {
            // Sort by product_name
            if (a.product_name < b.product_name) return -1;
            if (a.product_name > b.product_name) return 1;
            // If product_name is equal, sort by collected_on in descending order
            return new Date(b.collected_on) - new Date(a.collected_on);
        });
    });

    return groupedData;
}
