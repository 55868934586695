// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* AuthenticatedWrapper.css */

.full-screen-modal {
    position: fixed; /* Fixed position to stay in place even on scroll */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 1000; /* High z-index to overlay on top of other content */
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    /* Adjust width and height as needed */
    width: 80%;
    height: 80%;
    overflow-y: auto; /* Add scroll for content overflow */
}
`, "",{"version":3,"sources":["webpack://./src/components/user/authenticated/AuthenticatedWrapper.css"],"names":[],"mappings":"AAAA,6BAA6B;;AAE7B;IACI,eAAe,EAAE,mDAAmD;IACpE,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,WAAW;IACX,YAAY;IACZ,aAAa,EAAE,oDAAoD;IACnE,oCAAoC,EAAE,gCAAgC;IACtE,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,sBAAsB;IACtB,aAAa;IACb,kBAAkB;IAClB,yCAAyC;IACzC,sCAAsC;IACtC,UAAU;IACV,WAAW;IACX,gBAAgB,EAAE,oCAAoC;AAC1D","sourcesContent":["/* AuthenticatedWrapper.css */\n\n.full-screen-modal {\n    position: fixed; /* Fixed position to stay in place even on scroll */\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    width: 100%;\n    height: 100%;\n    z-index: 1000; /* High z-index to overlay on top of other content */\n    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.modal-content {\n    background-color: #fff;\n    padding: 20px;\n    border-radius: 8px;\n    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\n    /* Adjust width and height as needed */\n    width: 80%;\n    height: 80%;\n    overflow-y: auto; /* Add scroll for content overflow */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
