import React, {useEffect, useState} from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getUpsAuth} from "../store/external/upsAuthSlice";
import {getEmailAuth} from "../store/external/emailAuthSlice";
import {getZohoAuth} from "../store/external/zohoAuthSlice";
import {config} from "../components/Constants";
import DashboardPage from "../pages/dashboard/Dashboard";
import ScreenPage from "../pages/screen/Screen";
import ScreensPage from "../pages/screens/Screens";
import LaboratoryPage from "../pages/lab_tracking/Laboratory";
import MedicalReviewOfficerPage from "../pages/medical_review_officer/MedicalReviewOfficerPage";
import BillingPage from "../pages/billing/Billing";
import OrdersPage from "../pages/orders/Orders";
import BadganatorPage from "../components/modals/baganator/Badganator";
import InvoicesPage from "../pages/invoices/Invoices";
import PanelsPage from "../pages/panels/Panels";
import ClientsPage from "../pages/clients/Clients";
import EmailPage from "../pages/email/EmailPage";
import AutomationRoutes from "./automationRoutes";
import UserRoutes from "./userRoutes";
import {getUserRolesFromToken} from "../helpers/token/processToken";
import {emailTokenRequest} from "../utils/authConfig";
import {getShopifyAdminAuth} from "../store/external/shopifyAuthSlice";
import {setUserRoles} from "../store/user/userSlice";
import ManageShopifyPage from "../pages/shopify/manage/ManageShopifyPage";

const AppRoutes = ({instance}) => {
    const [authSuccess, setAuthSuccess] = useState(false);
    const userRoles = useSelector(
        (state) => state.user.userRoles
    );
    const dispatch = useDispatch();
    const [authStep, setAuthStep] = useState(0);

    useEffect(() => {
        const handleAuthFlow = async () => {
            if (!authSuccess) {
                console.log(`Handling auth step: ${authStep}`);
                switch (authStep) {
                    case 0:
                        await handleUPSAuth();
                        break;
                    case 1:
                        await handleZohoAuth();
                        break;
                    case 2:
                        await handleEmailAuth();
                        break;
                    case 3:
                        await handleShopifyAuth();
                        break;
                    default:
                        console.log("All auth flows handled");
                        setAuthSuccess(true);
                        break;
                }
            }
        };

        handleAuthFlow();
    }, [authStep, authSuccess]);

    const handleShopifyAuth = async () => {
        console.log("Starting Shopify authentication");
        const now = new Date().getTime();
        const sessionShopifyAccess = sessionStorage.getItem("shopifyAccessToken");
        const sessionShopifyExpiry = sessionStorage.getItem("shopifyTokenExpiry");

        if (!sessionShopifyAccess || !sessionShopifyExpiry || now >= parseInt(sessionShopifyExpiry, 10)) {
            await authHelper("shopifyGrantToken", "shopifyTokenExpiry", getShopifyAdminAuth, {
                clientId: "62762d62a56a36522ca830767d5a3885",
                authURL: "https://labb-stage.myshopify.com/admin/oauth/authorize",
                scope: "read_orders,write_customers, read_products, write_products, read_customers, write_customers, write_metaobjects, read_metaobjects",
                accessType: "per-user"
            }, () => {
                console.log("Shopify authentication step completed, moving to step completed");
                setAuthSuccess(true);
            });
        }
    };


    const handleUPSAuth = async () => {
        console.log("Starting UPS authentication");
        const now = new Date().getTime();
        const sessionUpsAccess = sessionStorage.getItem("upsAccessToken");
        const sessionUpsExpiry = sessionStorage.getItem("upsTokenExpiry");

        if (!sessionUpsAccess || now >= parseInt(sessionUpsExpiry, 10)) {
            await authHelper("upsGrantToken", "upsTokenExpiry", getUpsAuth, {
                clientId: "FzG9MGggkA1pmWJocmIKjB6h9MZBJCknlOBMvd2TNGwExq4W",
                authPageIdentifier: "ups.com/security",
                authURL: config.url.UPS_TOKEN_URL
            }, () => {
                console.log("UPS authentication step completed, moving to step 1");
                setAuthStep(1);
            });
        } else {
            console.log("UPS token is valid, dispatching to store");
            dispatch(getUpsAuth({
                grantToken: sessionUpsAccess,
            }));
            setAuthStep(1);
        }
    };

    const handleZohoAuth = async () => {
        console.log("Starting Zoho authentication");
        const now = new Date().getTime();
        const sessionZohoGrantAccess = sessionStorage.getItem("zohoGrantToken");
        const sessionZohoExpiry = sessionStorage.getItem("zohoTokenExpiry");

        if (!sessionZohoGrantAccess || !sessionZohoExpiry || now >= parseInt(sessionZohoExpiry, 10)) {
            await authHelper("zohoGrantToken", "zohoTokenExpiry", getZohoAuth, {
                clientId: "1000.FCHYNS46251473XYB8P6SBX8VHG3YL",
                authPageIdentifier: "accounts.zoho.com/oauth/v2/auth",
                authURL: `https://accounts.zoho.com/oauth/v2/auth`,
                scope: encodeURIComponent('ZohoBooks.invoices.CREATE,ZohoBooks.invoices.READ,ZohoBooks.invoices.UPDATE,ZohoBooks.contacts.READ'),
                accessType: 'offline'
            }, () => {
                console.log("Zoho authentication step completed, moving to step 2");
                setAuthStep(2);
            });
        } else {
            console.log("Zoho token is valid, dispatching to store");
            dispatch(getZohoAuth({
                zohoGrantToken: sessionZohoGrantAccess
            }));
            setAuthStep(2);
        }
    };

    const handleEmailAuth = async () => {
        console.log("Starting Email authentication");
        const now = new Date().getTime();
        const sessionEmailToken = sessionStorage.getItem("emailAccessToken");
        const sessionEmailTokenExpiry = sessionStorage.getItem("emailTokenExpiry");

        if (!sessionEmailToken || !sessionEmailTokenExpiry || now >= parseInt(sessionEmailTokenExpiry, 10)) {
            await dispatch(getEmailAuth({instance, emailTokenRequest}));
            console.log("Email authentication step completed");
            setAuthStep(3);
        } else {
            console.log("Email token is valid, dispatching to store");
            dispatch(getEmailAuth({
                accessToken: sessionEmailToken,
                expiryTime: sessionEmailTokenExpiry
            }));
            setAuthStep(3)
        }
    };

    const authHelper = async (tokenKey, expiryKey, dispatchFunction, {
        clientId,
        authPageIdentifier,
        authURL,
        scope = '',
        accessType = ''
    }, onSuccess) => {
        const sessionToken = sessionStorage.getItem(tokenKey);
        const sessionTokenExpiry = sessionStorage.getItem(expiryKey);
        const url = new URL(window.location.href);
        const grantToken = url.searchParams.get('code');
        const timeBeforeExpiry = 5 * 60 * 1000;
        const now = new Date().getTime();

        console.log(`Checking ${tokenKey} with grantToken: ${grantToken}`);

        if (!sessionToken || !sessionTokenExpiry || now >= parseInt(sessionTokenExpiry, 10) - timeBeforeExpiry) {
            if (grantToken) {
                await dispatch(dispatchFunction({
                    instance,
                    grantToken,
                    redirectUrl: config.url.REACT_APP_REDIRECT_URL
                }));
                console.log(`Success Redirecting to ${authPageIdentifier}`);
                url.searchParams.delete('code');
                window.history.pushState({}, '', url.toString());
                sessionStorage.setItem(tokenKey, grantToken);
                sessionStorage.setItem(expiryKey, now + 3600 * 1000); // Assuming 1 hour token validity
                onSuccess();
            } else {
                const authParams = `response_type=code&client_id=${clientId}&scope=${scope}&redirect_uri=${encodeURIComponent(config.url.REACT_APP_REDIRECT_URL)}&access_type=${accessType}`;
                const authFullUrl = `${authURL}?${authParams}`;
                window.location.href = authFullUrl;
                console.log(`Redirecting to ${authFullUrl}`);
            }
        } else {
            console.log(`${tokenKey} is still valid`);
            onSuccess();
        }
    };

    useEffect(() => {
        const token = sessionStorage.getItem('accessToken');
        if (token) {
            const roles = getUserRolesFromToken(token);
            dispatch(setUserRoles(roles));
        }
    }, []);

    const hasRole = (role) => userRoles.includes(role);

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<DashboardPage/>}/>

                {hasRole("Labb.Specimen.Details") && (
                    <Route path="/screen/review" element={<ScreenPage/>}/>
                )}
                {hasRole("Freddy.Panels") && (
                    <Route path="/panels" element={<PanelsPage/>}/>
                )}
                {hasRole("Labb.Specimen.Details") && (
                    <Route path="/screens/" element={<ScreensPage/>}/>
                )}
                {hasRole("Freddy.Laboratory") && (
                    <Route path="/laboratory/" element={<LaboratoryPage/>}/>
                )}
                {hasRole("Labb.Mro.Reports") && (
                    <Route
                        path="/medical-review-officer/"
                        element={<MedicalReviewOfficerPage/>}
                    />
                )}
                {hasRole("Labb.Billing.Reports") && (
                    <Route path="/billing" element={<BillingPage/>}/>
                )}
                {hasRole("Freddy.Billing.Invoice.Reports") && (
                    <Route path="/invoices" element={<InvoicesPage/>}/>
                )}
                {hasRole("Labb.Client.Management") && (
                    <Route
                        path="/clients/"
                        element={<ClientsPage userRoles={userRoles}/>}
                    />
                )}
                {hasRole("Freddy.Orders") && (
                    <Route
                        path="/orders"
                        element={<OrdersPage userRoles={userRoles}/>}
                    />
                )}
                {hasRole("Freddy.Badganator") && (
                    <Route path="/badganator" element={<BadganatorPage/>}/>
                )}
                {hasRole("Freddy.Autobot") && hasRole("Freddy.SuperAdmin") && (
                    <Route
                        path="/automation/*"
                        element={<AutomationRoutes instance={instance}/>}
                    />
                )}
                {hasRole("Freddy.SuperAdmin") && (
                    <Route
                        path="/users/*"
                        element={<UserRoutes instance={instance}/>}
                    />
                )}
                {hasRole("Labb.Email") && (
                    <Route
                        path="/email"
                        element={<EmailPage instance={instance}/>}
                    />
                )}
                {hasRole("Freddy.Manage.Shopify") && (
                    <Route
                        path="/shopify"
                        element={<ManageShopifyPage instance={instance}/>}
                    />
                )}
            </Routes>
        </BrowserRouter>
    );
};

export default AppRoutes;