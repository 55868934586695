import React from 'react';
import UploadModals from "./uploads/Uploads";
import NotificationModal from "./notifications/NotificationModal";
import MessagesModal from "./messages/MessagesModal";
import PricesModal from "./prices/PricesModel";
import BadganatorModel from "./baganator/Badganator";
import ClientPricingModel from "./client_pricing/ClientPricing";
import LinkAccountsModal from "./link_accounts/LinkAccountsModal";
import ClientAccountsModal from "./client_accounts/ClientAccountsModal";
import OrderDetailModal from "./order_detail/OrderDetailModal";

const Modals = () => {
    return (
        <>
            <UploadModals />
            <NotificationModal />
            <ClientPricingModel />
            <PricesModal />
            <BadganatorModel />
            <MessagesModal />
            <LinkAccountsModal />
            <ClientAccountsModal />
            <OrderDetailModal />
        </>
    );
};

export default Modals;
