import React, { useState, useEffect } from 'react';
import './ClientOrders.css';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPanelsByLabbThunk } from '../../store/panels/panelsSlice';
import PasswordProtect from '../../components/lab_fusion/password_protect/PasswordProtect';
import LabFusionLogo from '../../assets/logo/LabFusionLogo.png';
import ShopifyHeader from "../../components/shopify_header/ShopifyHeader";
import {clearShopifyAuth} from "../../store/external/shopifyAuthSlice";
import ClientOrderBilling from "../../components/orders/client_order_billing/ClientOrderBilling";

const ClientOrdersPage = () => {
    const dispatch = useDispatch();
    const hostname = new URL(window.location.href).hostname;
    const [isAuthorized, setIsAuthorized] = useState(false);
    const panelsData = useSelector(state => state.panels.panels);
    let shopifyAccessToken = useSelector(state => state.external?.shopifyAuth.access_token);

    if (!shopifyAccessToken) {
        shopifyAccessToken = sessionStorage.getItem('shopifyAccessToken');
    }
    useEffect(() => {
        dispatch(fetchPanelsByLabbThunk());
    }, [dispatch]);

    const handleAccessGranted = () => {
        setIsAuthorized(true);
    };

    // if (!isAuthorized) {
    //     return <PasswordProtect onAccessGranted={handleAccessGranted} />;
    // }


    const onLogout = () => {
        const idToken = sessionStorage.getItem('shopifyIdToken');
        const logoutUri = `https://shopify.com/58965033073/auth/logout`;
        const redirectUri = `https://${hostname}/`; // You can change this to any URI you want to redirect after logout

        // Redirect to Shopify logout endpoint
        window.location.href = `${logoutUri}?id_token_hint=${idToken}&post_logout_redirect_uri=${encodeURIComponent(redirectUri)}`;

        // Clear local session
        sessionStorage.removeItem('shopifyAccessToken');
        sessionStorage.removeItem('shopifyGrantToken');
        sessionStorage.removeItem('shopifyIdToken');
        dispatch(clearShopifyAuth());
    };

    return (
        <div>
            {shopifyAccessToken && (
                <div className="panels-page-container">
                    <ShopifyHeader onLogout={onLogout}/>
                    {/*<header className="panels-header">*/}
                    {/*    <img*/}
                    {/*        className="panels-logo"*/}
                    {/*        src={LabFusionLogo}*/}
                    {/*        alt="Client Logo"*/}
                    {/*    />*/}
                    {/*</header>*/}
                    <div className="panels-body-container">
                        <div className="panels-content-container">
                            <div className="panels-table-container">
                                <ClientOrderBilling />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ClientOrdersPage;
