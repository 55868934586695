import React, {useState} from 'react';
import './OrganizationsRow.css';
import ActionTray from "../../../modals/action_tray/ActionTray";
import {ActionTrayIcons} from "../../../../assets/icons";

const OrganizationRow = ({ organization, onEdit, userRoles }) => {

    const handleViewOrganizationEditModel = () => {
        if (onEdit) {
            onEdit(organization);
        }
    };

    const iconsData = [
        // {
        //     icon: ActionTrayIcons.RejectIcon,
        //     alt: "Reject",
        //     action: handleRejectReview,
        //     permission: "VIEW_REJECT_ICON"
        // },
        // {
        //     icon: ActionTrayIcons.LaboratoryIcon,
        //     alt: "Laboratory",
        //     action: handleLaboratoryAction,
        //     permission: "VIEW_LABORATORY_ICON"
        // },
        {
            icon: ActionTrayIcons.ReportIcon,
            alt: "Account Details",
            action: handleViewOrganizationEditModel,
            permission: "No.Access"
        },
    ];

    return (
        <>
            <tr className="client-row">
                <th className="cell">{organization.organization_name}</th>

                <td className="cell">
                    <ActionTray iconsData={iconsData} rowData={organization} userRoles={userRoles} onEdit={onEdit} />
                </td>
            </tr>
        </>
    );
};

export default OrganizationRow;
