import React from 'react';
import { SpeedIcons } from '../../../../../assets/icons';

const ProgressBar = ({ step }) => {
    const steps = [
        { icon: SpeedIcons.PersonIcon, label: 'Personal Details' },
        { icon: SpeedIcons.CompanyIcon, label: 'Company Details' },
        { icon: SpeedIcons.EmailIcon, label: 'Contact Details' },
        { icon: SpeedIcons.CheckIcon, label: 'Preferences' }
    ];

    return (
        <div className="progress-bar">
            {steps.map((stepObj, index) => (
                <div key={index} className={`progress-step ${step >= index + 1 ? "active" : ""}`}>
                    <img src={stepObj.icon} alt={stepObj.label} />
                </div>
            ))}
        </div>
    );
};

export default ProgressBar;
