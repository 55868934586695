// utils/authentication.js


import {setIsLoggedIn, setIsRegistered, setToken, setUserId, setUsername} from "../store/user/userSlice";
import {authenticateUser} from "../services/api/v1/userService";

export const authenticateWithToken = async (token, dispatch) => {
    try {
        const response = await authenticateUser({ token });

        dispatch(setToken(token));
        dispatch(setUserId(response.user_id));
        dispatch(setUsername(response.user_name));
        dispatch(setIsLoggedIn(true));

        if (response.user_name) {
            dispatch(setIsRegistered(true));
        }

        return true; // Authentication successful
    } catch (error) {
        return false; // Authentication failed
    }
};

export const refreshToken = async (dispatch) => {
    try {
        const response = await authenticateUser({ token: sessionStorage.getItem("accessToken") });

        dispatch(setToken(response.token));

        // Store the new token's expiry time
        sessionStorage.setItem("tokenExpiry", Date.now() + (response.expires_in * 1000));

        return true;
    } catch (error) {
        console.error("Token refresh failed:", error);
        return false;
    }
};