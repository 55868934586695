import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getClientOrderDetails } from "../../../store/orders/ordersSlice";
import LoadingSpinningCup from "../../../pages/loading/Loading";
import "./ClientOrderBilling.css";

const ClientOrderBilling = () => {
    const dispatch = useDispatch();
    const customerDetails = useSelector(state => state.shopifyUser.customerDetails);
    const orderDetails = useSelector(state => state.orders?.customerOrderDetails);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (customerDetails?.id) {
            setLoading(true);
            dispatch(getClientOrderDetails(customerDetails?.id))
                .then(() => setLoading(false))
                .catch(() => setLoading(false));
        }
    }, [dispatch, customerDetails?.id]);

    if (loading) {
        return <LoadingSpinningCup />;
    }

    if (!orderDetails || !orderDetails.orders || !orderDetails.orders.edges.length) {
        return <p>No orders found.</p>;
    }

    return (
        <div className="order-billing-container">
            {orderDetails.orders.edges.map((orderEdge, index) => {
                const order = orderEdge.node;
                return (
                    <div key={order.id} className="order-card">
                        <div className="order-header">
                            <div className="order-date">
                                <span className="label">Order Date:</span> {new Date(order.createdAt).toLocaleString()}
                            </div>
                            <div className="order-number">
                                <span className="label">Order #:</span> {order.name}
                            </div>
                            <div className="order-email">
                                <span className="label">Email:</span> {order.email}
                            </div>
                        </div>
                        <div className="shipping-address">
                            <span className="label">Shipping Address:</span><br />
                            {order.shippingAddress.firstName} {order.shippingAddress.lastName}<br />
                            {order.shippingAddress.address1}<br />
                            {order.shippingAddress.address2 && <>{order.shippingAddress.address2}<br /></>}
                            {order.shippingAddress.city}, {order.shippingAddress.country}
                        </div>

                        <table className="line-items-table">
                            <thead>
                            <tr>
                                <th>Item</th>
                                <th>Quantity</th>
                                <th>Price</th>
                            </tr>
                            </thead>
                            <tbody>
                            {order.lineItems.edges.map((itemEdge, itemIndex) => {
                                const item = itemEdge.node;
                                return (
                                    <tr key={itemIndex}>
                                        <td>{item.title}</td>
                                        <td>{item.quantity}</td>
                                        <td>{item.price.amount} {item.price.currencyCode}</td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>

                        <div className="order-summary">
                            <div>
                                <span className="label">Total Shipping:</span> {order.totalShipping.amount} {order.totalShipping.currencyCode}
                            </div>
                            <div>
                                <span className="label">Total Price:</span> {order.totalPrice.amount} {order.totalPrice.currencyCode}
                            </div>
                            <div>
                                <span className="label">Payment Status:</span> {order.paymentInformation.paymentStatus}
                            </div>
                            {order.paymentInformation.paymentCollectionUrl && (
                                <div className="payment-link">
                                    <a href={order.paymentInformation.paymentCollectionUrl} target="_blank" rel="noopener noreferrer">
                                        Pay Now
                                    </a>
                                </div>
                            )}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default ClientOrderBilling;
