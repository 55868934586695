// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tab-navigation {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
}

.tab-button {
    background-color: #f5f5f5;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 4px;
    font-weight: bold;
    color: #333;
}

.tab-button.active {
    background-color: #007bff;
    color: white;
}

.tab-button:not(.active):hover {
    background-color: #e0e0e0;
}

.tab-content {
    padding: 20px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}
`, "",{"version":3,"sources":["webpack://./src/components/public_header/public_nav/PublicNav.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,6BAA6B;IAC7B,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,kBAAkB;IAClB,iBAAiB;IACjB,WAAW;AACf;;AAEA;IACI,yBAAyB;IACzB,YAAY;AAChB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,wCAAwC;AAC5C","sourcesContent":[".tab-navigation {\n    display: flex;\n    justify-content: space-around;\n    margin-bottom: 20px;\n}\n\n.tab-button {\n    background-color: #f5f5f5;\n    border: none;\n    padding: 10px 20px;\n    cursor: pointer;\n    border-radius: 4px;\n    font-weight: bold;\n    color: #333;\n}\n\n.tab-button.active {\n    background-color: #007bff;\n    color: white;\n}\n\n.tab-button:not(.active):hover {\n    background-color: #e0e0e0;\n}\n\n.tab-content {\n    padding: 20px;\n    background-color: #fff;\n    border-radius: 4px;\n    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
