// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.badganator-modal-overlay {
    z-index: 5000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
    display: flex;
    justify-content: center;
    align-items: center;
}

.badganator-modal-content {
    z-index: 6000;  /* Even higher than overlay */
    position: relative;  /* Add this line */
    background-color: #fff;
    padding: 20px;
    width: 80vw;
    height: 13vh;
    border-radius: 8px;
}

.badganator-modal-header {
    position: absolute;
    top: 10px;     /* Adjust as needed */
    right: 10px;   /* Adjust as needed */
}

.badganator-modal-body {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.badganator-modal-preview {
    flex-grow: 1;
    margin-top: 20px;
    border-top: 1px solid #e0e0e0;
    padding-top: 20px;
}

.badge-preview-iframe {
    width: 100%;
    height: 100%;
    border: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/modals/baganator/Badganator.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,MAAM;IACN,OAAO;IACP,YAAY;IACZ,aAAa;IACb,oCAAoC,EAAE,2BAA2B;IACjE,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,aAAa,GAAG,6BAA6B;IAC7C,kBAAkB,GAAG,kBAAkB;IACvC,sBAAsB;IACtB,aAAa;IACb,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,SAAS,MAAM,qBAAqB;IACpC,WAAW,IAAI,qBAAqB;AACxC;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,gBAAgB;IAChB,6BAA6B;IAC7B,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,YAAY;AAChB","sourcesContent":[".badganator-modal-overlay {\n    z-index: 5000;\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100vw;\n    height: 100vh;\n    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.badganator-modal-content {\n    z-index: 6000;  /* Even higher than overlay */\n    position: relative;  /* Add this line */\n    background-color: #fff;\n    padding: 20px;\n    width: 80vw;\n    height: 13vh;\n    border-radius: 8px;\n}\n\n.badganator-modal-header {\n    position: absolute;\n    top: 10px;     /* Adjust as needed */\n    right: 10px;   /* Adjust as needed */\n}\n\n.badganator-modal-body {\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n}\n\n.badganator-modal-preview {\n    flex-grow: 1;\n    margin-top: 20px;\n    border-top: 1px solid #e0e0e0;\n    padding-top: 20px;\n}\n\n.badge-preview-iframe {\n    width: 100%;\n    height: 100%;\n    border: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
