// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.action-tray-container {
    position: relative;
    display: inline-block;
}

.action-icons {
    display: flex;
    position: absolute;
    top: 0%; /* Positioning below the trigger */
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-color: #ffffff; /* Lighter background for better contrast */
    border-radius: 8px; /* Slightly more rounded corners */
    z-index: 1000;
    padding: 10px;
}

.icon-wrapper {
    margin: 0 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon-wrapper:hover {
    background-color: #e0e0e0; /* Add a hover effect for better UX */
    border-radius: 4px; /* Match the icon button border radius */
}
`, "",{"version":3,"sources":["webpack://./src/components/modals/action_tray/ActionTray.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,qBAAqB;AACzB;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,OAAO,EAAE,kCAAkC;IAC3C,SAAS;IACT,2BAA2B;IAC3B,wCAAwC;IACxC,yBAAyB,EAAE,2CAA2C;IACtE,kBAAkB,EAAE,kCAAkC;IACtD,aAAa;IACb,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,yBAAyB,EAAE,qCAAqC;IAChE,kBAAkB,EAAE,wCAAwC;AAChE","sourcesContent":[".action-tray-container {\n    position: relative;\n    display: inline-block;\n}\n\n.action-icons {\n    display: flex;\n    position: absolute;\n    top: 0%; /* Positioning below the trigger */\n    left: 50%;\n    transform: translateX(-50%);\n    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n    background-color: #ffffff; /* Lighter background for better contrast */\n    border-radius: 8px; /* Slightly more rounded corners */\n    z-index: 1000;\n    padding: 10px;\n}\n\n.icon-wrapper {\n    margin: 0 5px;\n    cursor: pointer;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.icon-wrapper:hover {\n    background-color: #e0e0e0; /* Add a hover effect for better UX */\n    border-radius: 4px; /* Match the icon button border radius */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
