// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.read-tiles-main-body{
    height: 90%;
    position: fixed;
    bottom: 125px;
    width: 100%;
    padding-bottom: 20px;
    padding-top: 56px;
    top: 125px;
    display: flex;
    flex-direction: row;
    overflow-x: auto; /* add scrolling */
    -ms-overflow-style: none;  /* Internet Explorer 10+ remove scroll bar */
    scrollbar-width: none;  /* Firefox remove scroll bar */
    z-index: 1;
    justify-content: flex-start;
    flex-wrap: wrap;
}


.read-tiles-main-body::-webkit-scrollbar {
    display: none;  /* Safari and Chrome remove scroll bar*/
}
`, "",{"version":3,"sources":["webpack://./src/components/footer/read_tiles/ReadTiles.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,eAAe;IACf,aAAa;IACb,WAAW;IACX,oBAAoB;IACpB,iBAAiB;IACjB,UAAU;IACV,aAAa;IACb,mBAAmB;IACnB,gBAAgB,EAAE,kBAAkB;IACpC,wBAAwB,GAAG,4CAA4C;IACvE,qBAAqB,GAAG,8BAA8B;IACtD,UAAU;IACV,2BAA2B;IAC3B,eAAe;AACnB;;;AAGA;IACI,aAAa,GAAG,uCAAuC;AAC3D","sourcesContent":[".read-tiles-main-body{\n    height: 90%;\n    position: fixed;\n    bottom: 125px;\n    width: 100%;\n    padding-bottom: 20px;\n    padding-top: 56px;\n    top: 125px;\n    display: flex;\n    flex-direction: row;\n    overflow-x: auto; /* add scrolling */\n    -ms-overflow-style: none;  /* Internet Explorer 10+ remove scroll bar */\n    scrollbar-width: none;  /* Firefox remove scroll bar */\n    z-index: 1;\n    justify-content: flex-start;\n    flex-wrap: wrap;\n}\n\n\n.read-tiles-main-body::-webkit-scrollbar {\n    display: none;  /* Safari and Chrome remove scroll bar*/\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
