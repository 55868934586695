// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --main-bg-color: #333;
    --main-accent-color: #007BFF;
    --hover-accent-color: #0056b3;
    --border-color: #e1e1e1;
}

.screens-page-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
}

.screens-content-container {
    display: flex;
    flex: 1 1;
    height: calc(100vh - 10vh);
    overflow: hidden;
}

.filter-container {
    flex-basis: 20%;
    height: 100%;
    padding: 10px;
    border-right: 1px solid var(--border-color);
}

.recent-screens-container {
    flex-basis: 80%;
    height: 100%;
    overflow-y: auto;
}`, "",{"version":3,"sources":["webpack://./src/pages/screens/Screens.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,4BAA4B;IAC5B,6BAA6B;IAC7B,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,aAAa;IACb,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,SAAO;IACP,0BAA0B;IAC1B,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,YAAY;IACZ,aAAa;IACb,2CAA2C;AAC/C;;AAEA;IACI,eAAe;IACf,YAAY;IACZ,gBAAgB;AACpB","sourcesContent":[":root {\n    --main-bg-color: #333;\n    --main-accent-color: #007BFF;\n    --hover-accent-color: #0056b3;\n    --border-color: #e1e1e1;\n}\n\n.screens-page-container {\n    display: flex;\n    flex-direction: column;\n    height: 100vh;\n    width: 100vw;\n}\n\n.screens-content-container {\n    display: flex;\n    flex: 1;\n    height: calc(100vh - 10vh);\n    overflow: hidden;\n}\n\n.filter-container {\n    flex-basis: 20%;\n    height: 100%;\n    padding: 10px;\n    border-right: 1px solid var(--border-color);\n}\n\n.recent-screens-container {\n    flex-basis: 80%;\n    height: 100%;\n    overflow-y: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
