// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Logout.css */

.logout-btn {
    background: transparent;
    border: none;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.logout-btn:hover {
    transform: scale(1.1);
}`, "",{"version":3,"sources":["webpack://./src/pages/user/logout/Logout.css"],"names":[],"mappings":"AAAA,eAAe;;AAEf;IACI,uBAAuB;IACvB,YAAY;IACZ,eAAe;IACf,+BAA+B;AACnC;;AAEA;IACI,qBAAqB;AACzB","sourcesContent":["/* Logout.css */\n\n.logout-btn {\n    background: transparent;\n    border: none;\n    cursor: pointer;\n    transition: transform 0.3s ease;\n}\n\n.logout-btn:hover {\n    transform: scale(1.1);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
