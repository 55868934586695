import React, { useEffect, useState } from "react";
import "./ProductDetails.css";
import { useDispatch, useSelector } from "react-redux";
import { getPublicShopifyProductDetails } from "../../../../store/products/shopifyProductsSlice";
import ProductImages from "./product_images/ProductImages";
import VariantSelector from "./variant_selector/VariantSelector";
import ProductTitle from "./product_title/ProductTitle";
import ProductPriceDetails from "./price/ProductPriceDetails";

const SpeedProductDetails = () => {
    const products = useSelector((state) => state.shopifyProducts.allPublicShopifyProducts);
    const cart = useSelector((state) => state.shopifyCart.cart);
    const dispatch = useDispatch();
    const [selectedVariant, setSelectedVariant] = useState(null);

    useEffect(() => {
        dispatch(getPublicShopifyProductDetails({}));
    }, [dispatch]);

    // Extract product and variants data from Redux store
    const product = products?.data?.products?.edges?.[3]?.node;
    const variants = product?.variants?.nodes || [];

    useEffect(() => {
        // Automatically select the first variant when the products data is loaded
        if (variants.length > 0 && !selectedVariant) {
            setSelectedVariant(variants[0]);
        }
    }, [products]); // Runs when products data changes

    const handleSelectVariant = (variant) => {
        setSelectedVariant(variant);
    };

    return (
        <div className="speed-product-details">
            <div className="product-info">
                <ProductTitle />
                <ProductPriceDetails selectedVariant={selectedVariant} />
                <VariantSelector
                    variants={variants}
                    selectedVariant={selectedVariant}
                    setSelectedVariant={handleSelectVariant}
                    cart={cart}
                />
            </div>
            <ProductImages
                variants={variants}
                selectedVariant={selectedVariant}
                handleSelectVariant={handleSelectVariant}
            />
        </div>
    );
};

export default SpeedProductDetails;