// src/utils/authUtils.js

import {clearShopifyAuth} from '../store/external/shopifyAuthSlice';
import {useDispatch} from 'react-redux';

export const useShopifyLogout = () => {
    const dispatch = useDispatch();
    const hostname = window.location.hostname;

    return () => {
        const idToken = sessionStorage.getItem('shopifyIdToken');
        const logoutUri = `https://shopify.com/66278916260/auth/logout`;
        const redirectUri = `https://${hostname}/`;

        // Redirect to Shopify logout endpoint
        window.location.href = `${logoutUri}?id_token_hint=${idToken}&post_logout_redirect_uri=${encodeURIComponent(
            redirectUri
        )}`;

        // Clear session storage and Redux state
        sessionStorage.removeItem('shopifyAccessToken');
        sessionStorage.removeItem('shopifyIdToken');
        dispatch(clearShopifyAuth());
    };
};


// Function to exchange the authorization code for tokens
export const exchangeCodeForTokens = async (authorizationCode) => {
    const clientId = 'shp_42bae725-7192-4b16-bc62-42263d70954a';
    const codeVerifier = sessionStorage.getItem('codeVerifier');
    const hostname = window.location.hostname;
    const redirectUri = `https://${hostname}${window.location.pathname}`;

    const body = new URLSearchParams();
    body.append('grant_type', 'authorization_code');
    body.append('client_id', clientId);
    body.append('code', authorizationCode);
    body.append('code_verifier', codeVerifier);
    body.append('redirect_uri', redirectUri);

    const response = await fetch(`https://shopify.com/66278916260/auth/oauth/token`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: body.toString(),
    });

    if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to exchange authorization code: ${errorText}`);
    }

    const data = await response.json();
    return { access_token: data.access_token, id_token: data.id_token };
};