import React from 'react';
import './BillingSpecimenTable.css';
import BillingClientSection from "./billing_client_section/BillingClientSection";

const BillingSpecimenTable = ({ invoiceData }) => {
    return (
        <div className="billing-specimen-table-container">
            {invoiceData?.map((clientSpecimens, index) => (
                <div key={index} className="client-specimens-group">
                    <BillingClientSection clientSpecimens={clientSpecimens} />
                </div>
            ))}
        </div>
    );
}

export default BillingSpecimenTable;
