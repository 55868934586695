// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/pages/panels/Panels.css */

.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 5vh;
    background-color: #f0f0f0;
}

.loading-message {
    margin-top: 20px;
    font-size: 1.2em;
    color: #333;
}

.panels-page-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: #f0f0f0;
}

.panels-header {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #f0f0f0;
    padding: 20px 0;
    z-index: 1;
}

.panels-logo {
    max-width: 150px;
}

.panels-body-container {
    position: relative;
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: center;
    flex-grow: 1;
    z-index: 1;
}

.panels-content-container {
    width: 100%;
    max-width: 1500px;
}

.panels-table-container {
    width: 100%;
    height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/pages/panels/Panels.css"],"names":[],"mappings":"AAAA,gCAAgC;;AAEhC;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,WAAW;IACX,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,WAAW;IACX,aAAa;IACb,yBAAyB;AAC7B;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,aAAa;IACb,uBAAuB;IACvB,yBAAyB;IACzB,eAAe;IACf,UAAU;AACd;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,aAAa;IACb,aAAa;IACb,uBAAuB;IACvB,YAAY;IACZ,UAAU;AACd;;AAEA;IACI,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB","sourcesContent":["/* src/pages/panels/Panels.css */\n\n.loading-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    height: 5vh;\n    background-color: #f0f0f0;\n}\n\n.loading-message {\n    margin-top: 20px;\n    font-size: 1.2em;\n    color: #333;\n}\n\n.panels-page-container {\n    position: relative;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    width: 100%;\n    height: 100vh;\n    background-color: #f0f0f0;\n}\n\n.panels-header {\n    position: relative;\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    background-color: #f0f0f0;\n    padding: 20px 0;\n    z-index: 1;\n}\n\n.panels-logo {\n    max-width: 150px;\n}\n\n.panels-body-container {\n    position: relative;\n    width: 100%;\n    padding: 20px;\n    display: flex;\n    justify-content: center;\n    flex-grow: 1;\n    z-index: 1;\n}\n\n.panels-content-container {\n    width: 100%;\n    max-width: 1500px;\n}\n\n.panels-table-container {\n    width: 100%;\n    height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
