// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Login.css */

.login-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f7f9fc;
}

.login-card {
    background: #fff;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    width: 350px;
    text-align: center;
}

.login-logo {
    width: 120px;
    margin-bottom: 30px;
}

.login-form .login-btn {
    width: 100%;
    padding: 12px 15px;
    background-color: #007BFF;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.login-form .login-btn:hover {
    background-color: #0056b3;
}
`, "",{"version":3,"sources":["webpack://./src/components/user/login/Login.css"],"names":[],"mappings":"AAAA,cAAc;;AAEd;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,0CAA0C;IAC1C,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,YAAY;IACZ,eAAe;IACf,eAAe;IACf,sCAAsC;AAC1C;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":["/* Login.css */\n\n.login-wrapper {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100vh;\n    background-color: #f7f9fc;\n}\n\n.login-card {\n    background: #fff;\n    padding: 40px;\n    border-radius: 10px;\n    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);\n    width: 350px;\n    text-align: center;\n}\n\n.login-logo {\n    width: 120px;\n    margin-bottom: 30px;\n}\n\n.login-form .login-btn {\n    width: 100%;\n    padding: 12px 15px;\n    background-color: #007BFF;\n    border: none;\n    border-radius: 5px;\n    color: white;\n    font-size: 16px;\n    cursor: pointer;\n    transition: background-color 0.3s ease;\n}\n\n.login-form .login-btn:hover {\n    background-color: #0056b3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
