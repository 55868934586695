// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.unlinked-accounts {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 20px;
}

.account-match {
    display: grid;
    grid-template-columns: 1fr 2fr;
    align-items: center;
    gap: 10px;
    padding: 10px;
    background-color: #f5f5f5;
    border-radius: 8px;
}

.account-info, .select-container {
    display: flex;
    flex-direction: column;
}
.select-organization-container {
    display: flex;
    min-width: 200px;
    flex-direction: column;
}

.select-client-container {
    display: flex;
    min-width: 200px;
    flex-direction: column;
}

.account-label, .select-label {
    font-weight: bold;
    margin-bottom: 5px;
}

.account-number {
    padding: 10px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.account-select {
    font-size: 16px;
}

.Select__control {
    border-radius: 4px;
    border: 1px solid #ccc;
    height: 40px;
}

.Select__menu {
    border-radius: 4px;
}

.Select__option--is-selected {
    background-color: #007bff;
    color: white;
}
`, "",{"version":3,"sources":["webpack://./src/components/modals/link_accounts/unlinked_accounts/UnlinkedAccounts.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,SAAS;IACT,aAAa;IACb,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;AACA;IACI,aAAa;IACb,gBAAgB;IAChB,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,gBAAgB;IAChB,sBAAsB;AAC1B;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,sBAAsB;IACtB,YAAY;AAChB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,YAAY;AAChB","sourcesContent":[".unlinked-accounts {\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n    margin: 20px;\n}\n\n.account-match {\n    display: grid;\n    grid-template-columns: 1fr 2fr;\n    align-items: center;\n    gap: 10px;\n    padding: 10px;\n    background-color: #f5f5f5;\n    border-radius: 8px;\n}\n\n.account-info, .select-container {\n    display: flex;\n    flex-direction: column;\n}\n.select-organization-container {\n    display: flex;\n    min-width: 200px;\n    flex-direction: column;\n}\n\n.select-client-container {\n    display: flex;\n    min-width: 200px;\n    flex-direction: column;\n}\n\n.account-label, .select-label {\n    font-weight: bold;\n    margin-bottom: 5px;\n}\n\n.account-number {\n    padding: 10px;\n    background-color: white;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n}\n\n.account-select {\n    font-size: 16px;\n}\n\n.Select__control {\n    border-radius: 4px;\n    border: 1px solid #ccc;\n    height: 40px;\n}\n\n.Select__menu {\n    border-radius: 4px;\n}\n\n.Select__option--is-selected {\n    background-color: #007bff;\n    color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
