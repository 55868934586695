// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.employee-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid #e1e1e1;
    border-radius: 5px;
    overflow: hidden;
}

.employee-header {
    display: grid;
    grid-template-columns: repeat(18, 1fr); /* Adjust to the number of columns you need */
    gap: 10px;
    padding: 10px;
    background-color: #f5f5f5;
    font-weight: bold;
    border-bottom: 1px solid #e1e1e1;
}

.employee-row {
    display: grid;
    grid-template-columns: repeat(18, 1fr); /* Adjust to match the header */
    gap: 10px;
    padding: 10px;
    border-bottom: 1px solid #e1e1e1;
}

.employee-row div {
    display: flex;
    flex-direction: column;
}

.employee-row div span {
    font-weight: bold;
    margin-bottom: 5px;
}

/* Responsive Design */
@media (max-width: 1024px) {
    .employee-header,
    .employee-row {
        grid-template-columns: 1fr 1fr; /* Two columns in smaller screens */
    }

    .employee-row div span {
        margin-bottom: 3px;
    }
}

@media (max-width: 768px) {
    .employee-header,
    .employee-row {
        grid-template-columns: 1fr; /* One column in smaller screens */
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/autobot/review/employees/Employees.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,WAAW;IACX,yBAAyB;IACzB,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,sCAAsC,EAAE,6CAA6C;IACrF,SAAS;IACT,aAAa;IACb,yBAAyB;IACzB,iBAAiB;IACjB,gCAAgC;AACpC;;AAEA;IACI,aAAa;IACb,sCAAsC,EAAE,+BAA+B;IACvE,SAAS;IACT,aAAa;IACb,gCAAgC;AACpC;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA,sBAAsB;AACtB;IACI;;QAEI,8BAA8B,EAAE,mCAAmC;IACvE;;IAEA;QACI,kBAAkB;IACtB;AACJ;;AAEA;IACI;;QAEI,0BAA0B,EAAE,kCAAkC;IAClE;AACJ","sourcesContent":[".employee-container {\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n    border: 1px solid #e1e1e1;\n    border-radius: 5px;\n    overflow: hidden;\n}\n\n.employee-header {\n    display: grid;\n    grid-template-columns: repeat(18, 1fr); /* Adjust to the number of columns you need */\n    gap: 10px;\n    padding: 10px;\n    background-color: #f5f5f5;\n    font-weight: bold;\n    border-bottom: 1px solid #e1e1e1;\n}\n\n.employee-row {\n    display: grid;\n    grid-template-columns: repeat(18, 1fr); /* Adjust to match the header */\n    gap: 10px;\n    padding: 10px;\n    border-bottom: 1px solid #e1e1e1;\n}\n\n.employee-row div {\n    display: flex;\n    flex-direction: column;\n}\n\n.employee-row div span {\n    font-weight: bold;\n    margin-bottom: 5px;\n}\n\n/* Responsive Design */\n@media (max-width: 1024px) {\n    .employee-header,\n    .employee-row {\n        grid-template-columns: 1fr 1fr; /* Two columns in smaller screens */\n    }\n\n    .employee-row div span {\n        margin-bottom: 3px;\n    }\n}\n\n@media (max-width: 768px) {\n    .employee-header,\n    .employee-row {\n        grid-template-columns: 1fr; /* One column in smaller screens */\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
