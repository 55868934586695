import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './ClientAccountsModal.css';
import { IconButton } from "../../buttons/icon_button/IconButton";
import { GenericIcons } from "../../../assets/icons";
import { toggleClientAccountsModal } from "../../../store/clients/clientsSlice";

const ClientAccountsModal = () => {
    const isVisible = useSelector(state => state.clients.isAccountsModalVisible);
    const clientAccounts = useSelector(state => state.clients.clientAccountsDetails);
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(toggleClientAccountsModal(false));
    };

    if (!isVisible) return null;

    // Group accounts by laboratory_client_name
    const groupedAccounts = clientAccounts.reduce((acc, account) => {
        if (!acc[account.laboratory_client_name]) {
            acc[account.laboratory_client_name] = [];
        }
        acc[account.laboratory_client_name].push(account);
        return acc;
    }, {});

    return (
        <div className="pricing-modal-overlay">
            <div className="pricing-modal">
                <div className="pricing-modal-header">
                    <IconButton hoverIcon={GenericIcons.ExitIcon} defaultIcon={GenericIcons.CloseIcon} onClick={handleClose} />
                </div>
                <div className="pricing-modal-content">
                    {Object.keys(groupedAccounts).map((clientName, index) => (
                        <div key={index} className="client-group">
                            <h3>{clientName}</h3>
                            <ul>
                                {groupedAccounts[clientName].map((account, idx) => (
                                    <li key={idx}>{account.laboratory_account_number}</li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ClientAccountsModal;
