import React, { useState } from "react";
import { useSelector } from "react-redux";
import {PrimaryButton} from "../../../buttons/primary_button/PrimaryButton";

const ShopifyManageCustomers = () => {
    const [customerData, setCustomerData] = useState({
        firstName: '',
        lastName: '',
        email: '',
    });

    const shopifyAuth = useSelector((state) => state.shopifyAuth.shopifyAuth);
    const accessToken = shopifyAuth?.access_token;

    const createCustomer = async () => {
        const query = `
          mutation {
            customerCreate(input: {
              firstName: "${customerData.firstName}",
              lastName: "${customerData.lastName}",
              email: "${customerData.email}"
            }) {
              customer {
                id
                firstName
                lastName
                email
              }
              userErrors {
                field
                message
              }
            }
          }
        `;

        const response = await fetch('https://labb-stage.myshopify.com/admin/api/2024-07/graphql.json', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-Shopify-Access-Token': accessToken,
            },
            body: JSON.stringify({ query }),
        });

        const responseData = await response.json();
        console.log(responseData);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCustomerData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    return (
        <div>
            <h2>Create Customer</h2>
            <input
                type="text"
                name="firstName"
                value={customerData.firstName}
                placeholder="First Name"
                onChange={handleChange}
            />
            <input
                type="text"
                name="lastName"
                value={customerData.lastName}
                placeholder="Last Name"
                onChange={handleChange}
            />
            <input
                type="email"
                name="email"
                value={customerData.email}
                placeholder="Email"
                onChange={handleChange}
            />
            <PrimaryButton onClick={createCustomer}>Create Customer</PrimaryButton>
        </div>
    );
};

export default ShopifyManageCustomers;
