// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.shipment-box-details {
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 8px;
    background-color: #f9f9f9;
    margin-bottom: 20px;
}

.box-detail {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fff;
}

.box-header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
}

.box-image-large {
    width: 100px;
    height: 100px;
}

.box-details {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.box-product-name {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 5px;
}

.box-quantity {
    font-size: 14px;
    margin-bottom: 5px;
}

.box-subdetails {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    margin-top: 10px;
}

.box-subdetails div {
    flex: 1 1;
    text-align: center;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #f1f1f1;
    margin-right: 5px;
}

.box-subdetails div:last-child {
    margin-right: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/modals/order_detail/shipment_box_details/ShipmentBoxDetails.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,aAAa;IACb,kBAAkB;IAClB,yBAAyB;IACzB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,yBAAyB;IACzB,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,SAAO;IACP,kBAAkB;IAClB,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;IAClB,yBAAyB;IACzB,iBAAiB;AACrB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".shipment-box-details {\n    border: 1px solid #ccc;\n    padding: 20px;\n    border-radius: 8px;\n    background-color: #f9f9f9;\n    margin-bottom: 20px;\n}\n\n.box-detail {\n    display: flex;\n    flex-direction: column;\n    margin-bottom: 20px;\n    padding: 20px;\n    border: 1px solid #ddd;\n    border-radius: 8px;\n    background-color: #fff;\n}\n\n.box-header {\n    display: flex;\n    justify-content: flex-end;\n    margin-bottom: 10px;\n}\n\n.box-image-large {\n    width: 100px;\n    height: 100px;\n}\n\n.box-details {\n    display: flex;\n    flex-direction: column;\n    margin-bottom: 10px;\n}\n\n.box-product-name {\n    font-weight: bold;\n    font-size: 16px;\n    margin-bottom: 5px;\n}\n\n.box-quantity {\n    font-size: 14px;\n    margin-bottom: 5px;\n}\n\n.box-subdetails {\n    display: flex;\n    justify-content: space-between;\n    font-size: 14px;\n    margin-top: 10px;\n}\n\n.box-subdetails div {\n    flex: 1;\n    text-align: center;\n    padding: 5px;\n    border: 1px solid #ddd;\n    border-radius: 4px;\n    background-color: #f1f1f1;\n    margin-right: 5px;\n}\n\n.box-subdetails div:last-child {\n    margin-right: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
