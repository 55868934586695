// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pricing-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.pricing-modal {
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 80%;
    max-width: 600px;
    padding: 20px;
    position: relative;
}

.pricing-modal-header {
    display: flex;
    justify-content: flex-end;
}

.pricing-modal-content {
    margin-top: 20px;
}

.client-group {
    margin-bottom: 20px;
}

.client-group h3 {
    margin-bottom: 10px;
    font-size: 1.2em;
    color: #333;
}

.client-group ul {
    list-style: none;
    padding: 0;
}

.client-group li {
    background: #f9f9f9;
    padding: 10px;
    margin-bottom: 5px;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}
`, "",{"version":3,"sources":["webpack://./src/components/modals/client_accounts/ClientAccountsModal.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,8BAA8B;IAC9B,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,yCAAyC;IACzC,UAAU;IACV,gBAAgB;IAChB,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;IACnB,gBAAgB;IAChB,WAAW;AACf;;AAEA;IACI,gBAAgB;IAChB,UAAU;AACd;;AAEA;IACI,mBAAmB;IACnB,aAAa;IACb,kBAAkB;IAClB,kBAAkB;IAClB,wCAAwC;AAC5C","sourcesContent":[".pricing-modal-overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background: rgba(0, 0, 0, 0.5);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.pricing-modal {\n    background: white;\n    border-radius: 8px;\n    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\n    width: 80%;\n    max-width: 600px;\n    padding: 20px;\n    position: relative;\n}\n\n.pricing-modal-header {\n    display: flex;\n    justify-content: flex-end;\n}\n\n.pricing-modal-content {\n    margin-top: 20px;\n}\n\n.client-group {\n    margin-bottom: 20px;\n}\n\n.client-group h3 {\n    margin-bottom: 10px;\n    font-size: 1.2em;\n    color: #333;\n}\n\n.client-group ul {\n    list-style: none;\n    padding: 0;\n}\n\n.client-group li {\n    background: #f9f9f9;\n    padding: 10px;\n    margin-bottom: 5px;\n    border-radius: 4px;\n    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
