// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/Labb-Repeat-As-Background.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.create-demo-account-container {
    display: flex;
    height: 100vh;
}

.create-demo-account-left {
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) repeat;
    opacity: 50%;
    color: white;
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
}

.create-demo-account-left h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
}

.create-demo-account-left p {
    font-size: 1.2rem;
    line-height: 1.5;
}

.create-demo-account-right {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
}

.create-demo-account-form {
    display: flex;
    flex-direction: column;
    width: 300px;
}

.create-demo-account-form input {
    margin-bottom: 15px;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.create-demo-account-form button {
    padding: 10px;
    font-size: 1rem;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.create-demo-account-form button:hover {
    background-color: #0056b3;
}
`, "",{"version":3,"sources":["webpack://./src/components/demo_account/CreateDemoAccount.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;AACjB;;AAEA;IACI,0DAAoE;IACpE,YAAY;IACZ,YAAY;IACZ,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,UAAU;AACd;;AAEA;IACI,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,UAAU;AACd;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY;AAChB;;AAEA;IACI,mBAAmB;IACnB,aAAa;IACb,eAAe;IACf,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".create-demo-account-container {\n    display: flex;\n    height: 100vh;\n}\n\n.create-demo-account-left {\n    background: url('../../assets/Labb-Repeat-As-Background.png') repeat;\n    opacity: 50%;\n    color: white;\n    padding: 50px;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    width: 50%;\n}\n\n.create-demo-account-left h1 {\n    font-size: 2.5rem;\n    margin-bottom: 20px;\n}\n\n.create-demo-account-left p {\n    font-size: 1.2rem;\n    line-height: 1.5;\n}\n\n.create-demo-account-right {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: 50%;\n}\n\n.create-demo-account-form {\n    display: flex;\n    flex-direction: column;\n    width: 300px;\n}\n\n.create-demo-account-form input {\n    margin-bottom: 15px;\n    padding: 10px;\n    font-size: 1rem;\n    border: 1px solid #ccc;\n    border-radius: 5px;\n}\n\n.create-demo-account-form button {\n    padding: 10px;\n    font-size: 1rem;\n    background-color: #007bff;\n    color: white;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n}\n\n.create-demo-account-form button:hover {\n    background-color: #0056b3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
