// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.quest-account-row {
    display: flex;
    align-items: center;
    background-color: #f5f5f5;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    margin-bottom: 10px;
}

.custom-select {
    flex-grow: 1;
    margin-right: 20px;
}

.react-select__control {
    border: 1px solid #ccc;
    border-radius: 20px;
    box-shadow: none;
}

.react-select__menu {
    border-radius: 20px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.organization-details > span {
    margin-right: 15px;
    font-size: 14px;
}`, "",{"version":3,"sources":["webpack://./src/components/modals/uploads/quest_accounts/quest_account_organizations/quest_account_organization_row/QuestAccountOrganizationRow.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,yBAAyB;IACzB,aAAa;IACb,kBAAkB;IAClB,qCAAqC;IACrC,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,sBAAsB;IACtB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;IACnB,qCAAqC;AACzC;;AAEA;IACI,kBAAkB;IAClB,eAAe;AACnB","sourcesContent":[".quest-account-row {\n    display: flex;\n    align-items: center;\n    background-color: #f5f5f5;\n    padding: 15px;\n    border-radius: 8px;\n    box-shadow: 0 2px 4px rgba(0,0,0,0.1);\n    margin-bottom: 10px;\n}\n\n.custom-select {\n    flex-grow: 1;\n    margin-right: 20px;\n}\n\n.react-select__control {\n    border: 1px solid #ccc;\n    border-radius: 20px;\n    box-shadow: none;\n}\n\n.react-select__menu {\n    border-radius: 20px;\n    box-shadow: 0 4px 6px rgba(0,0,0,0.1);\n}\n\n.organization-details > span {\n    margin-right: 15px;\n    font-size: 14px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
