// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.screens-container {
    overflow-y: auto;
    max-height: calc(100vh - 50px);
}

table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
}

thead th {
    background-color: #f5f5f5;
    position: sticky;
    top: 0;
    z-index: 500;
}

th, td {
    padding: 10px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
}

th {
    font-weight: bold;
}

tr:nth-child(odd) {
    background-color: #f9f9f9;
}

tr:nth-child(even) {
    background-color: #ffffff;
}

.scroll-container {
    display: block;
    overflow-y: auto;
    max-height: calc(100vh - 50px); /* Adjusted for header height */
}


`, "",{"version":3,"sources":["webpack://./src/components/specimens/screens/SpecimensTable.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,8BAA8B;AAClC;;AAEA;IACI,WAAW;IACX,yBAAyB;IACzB,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,gBAAgB;IAChB,MAAM;IACN,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,gBAAgB;IAChB,gBAAgB;IAChB,uBAAuB;AAC3B;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,8BAA8B,EAAE,+BAA+B;AACnE","sourcesContent":[".screens-container {\n    overflow-y: auto;\n    max-height: calc(100vh - 50px);\n}\n\ntable {\n    width: 100%;\n    border-collapse: collapse;\n    table-layout: fixed;\n}\n\nthead th {\n    background-color: #f5f5f5;\n    position: sticky;\n    top: 0;\n    z-index: 500;\n}\n\nth, td {\n    padding: 10px;\n    text-align: left;\n    overflow: hidden;\n    text-overflow: ellipsis;\n}\n\nth {\n    font-weight: bold;\n}\n\ntr:nth-child(odd) {\n    background-color: #f9f9f9;\n}\n\ntr:nth-child(even) {\n    background-color: #ffffff;\n}\n\n.scroll-container {\n    display: block;\n    overflow-y: auto;\n    max-height: calc(100vh - 50px); /* Adjusted for header height */\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
