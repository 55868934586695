// freddy-react/src/pages/screens/Screens.js

import React, {useCallback, useEffect, useState} from "react";
import "./Clients.css";
import Header from "../../components/header/Header";
import {useDispatch, useSelector} from "react-redux";
import ClientsTable from "../../components/clients/ClientsTable";
import {
    getAllClientDetails,
    setLoading
} from "../../store/clients/clientsSlice";
import {PrimaryButton} from "../../components/buttons/primary_button/PrimaryButton";
import Select from "react-select";
import {getClientBillingDetails} from "../../store/clients/clientBillingDetailsSlice";
import LoadingSpinningCup from "../loading/Loading";

const ClientsPage = ({userRoles}) => {
    const error = useSelector(state => state.clients.error);
    const dispatch = useDispatch();
    // Add a state for tracking loading status
    const isLoading = useSelector(state => state.clients.isLoading);
    const [clientParams, setClientParams] = useState({offset: 0, limit: 1000, filters: {}});
    const clientsData = useSelector(state => state.clients.clientData);
    const [clientsArray, setClientsArray] = useState(clientsData ? clientsData : []);
    const [filteredClients, setFilteredClients] = useState(clientsArray);


    useEffect(() => {
        // Fetch data when the component mounts

        const fetchData = async () => {
            setLoading(true);
            await dispatch(getAllClientDetails(clientParams));
            setLoading(false);
        };

        fetchData();
    }, [dispatch, clientParams]);


    const [selectedAccountId, setSelectedAccountId] = useState(null);
    const [selectedClientName, setSelectedClientName] = useState(null);
    const [selectedClientIds, setSelectedClientIds] = useState([]);



    const handleClientClick = (clientId) => {
        setSelectedAccountId(clientId);
    };

    const handleClientName = (clientName) => {
        setSelectedClientName(clientName);
    };

    const handleClientSearchSubmit = (event) => {
        event.preventDefault();
        // Your form submission logic here
    };

    const handleSelectDropdownChange = useCallback((value) => {
    //    clientsArray.find(item => item["company"] === value);
        //  if(selectedClient){ setSelectedClient(selectedClient);}
        if(value){
            const filtered = clientsArray.filter(item => item["company"]?.toLowerCase().includes(value.toLowerCase()));
            setFilteredClients(filtered);
        }else{
            setFilteredClients(clientsArray);
        }

      }, []);



    return (
        <div className="clients-page-container">
            <div className="clients-header-container">
                <Header/>
            </div>
            <div className="clients-body-container">
                <div className="clients-content-container">
                    {isLoading ? (
                        <LoadingSpinningCup />
                    ) : (
                        filteredClients && filteredClients.length > 0 ? (
                            <ClientsTable
                              clients={filteredClients}
                              userRoles={userRoles}
                            />
                        ) : (
                            <div>
                                <LoadingSpinningCup />
                            </div>
                        )
                    )
                    }
                </div>
                {/*)}*/}
            </div>
            {/*<Footer />*/}
        </div>
    );
};
export default ClientsPage;
