// freddy-react/src/store/publicUser/publicUserSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const publicUserSlice = createSlice({
    name: 'publicUser',
    initialState: {
        publicApprovalStatus: null, // This tracks if the public user is pending, approved, or denied
        publicToken: null, // Public access token
        publicUserId: '',  // Public user ID if needed
        isPublicUserLoggedIn: false, // Login state for public user
    },
    reducers: {
        setPublicUserId: (state, action) => {
            state.publicUserId = action.payload;
        },
        setIsPublicUserLoggedIn: (state, action) => {
            state.isPublicUserLoggedIn = action.payload;
        },
        setPublicApprovalStatus: (state, action) => {
            state.publicApprovalStatus = action.payload;
        },
        setPublicToken: (state, action) => {
            state.publicToken = action.payload;
        },
        requestPublicAccess: (state) => {
            state.publicApprovalStatus = 'pending';
        },
        approvePublicAccess: (state) => {
            state.publicApprovalStatus = 'approved';
        },
        denyPublicAccess: (state) => {
            state.publicApprovalStatus = 'denied';
        },
    },
});

export const {
    setPublicUserId,
    setIsPublicUserLoggedIn,
    setPublicApprovalStatus,
    setPublicToken,
    requestPublicAccess,
    approvePublicAccess,
    denyPublicAccess,
} = publicUserSlice.actions;

export const selectPublicUserId = (state) => state.publicUser.publicUserId;
export const selectIsPublicUserLoggedIn = (state) => state.publicUser.isPublicUserLoggedIn;
export const selectPublicApprovalStatus = (state) => state.publicUser.publicApprovalStatus;
export const selectPublicToken = (state) => state.publicUser.publicToken;

export default publicUserSlice.reducer;
