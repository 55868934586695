import {makePOSTRequest, makeURLEncodedPOSTRequest} from '../../utils/httpUtils';

export const fetchShopifyAuthToken = async () => {
    // Get the token from session storage
    const shopifyAuthToken = sessionStorage.getItem("shopifyGrantToken");

    // Create a URLSearchParams object to handle URL encoding
    const formData = new URLSearchParams();
    formData.append('grant_type', 'urn:ietf:params:oauth:grant-type:token-exchange');
    formData.append('client_id', 'shp_007be0ac-fa35-4eac-b362-6e5ba2dc4840');
    formData.append('scopes', 'https://api.customers.com/auth/customer.graphql');
    formData.append('subject_token', shopifyAuthToken);
    formData.append('subject_token_type', 'urn:ietf:params:oauth:token-type:access_token');
    formData.append('audience', '30243aa5-17c1-465a-8493-944bcc4e88aa');

    // Use the makeURLEncodedPOSTRequest method to send the request
    return makeURLEncodedPOSTRequest(`https://shopify.com/58965033073/auth/oauth/token`, formData);
};

export const fetchShopifyAdminAuthToken = async (shopifyAdminGrantToken, accessToken) => {
    const formData = new FormData();
    formData.append('grant_token', shopifyAdminGrantToken);

    // Assuming accessToken is needed for backend authorization. Adjust as necessary.
    return makePOSTRequest(`/api/v1/external/shopify/auth/token/new`, formData, accessToken);
};