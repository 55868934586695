// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 80%;
    width: 100%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    max-height: 80vh;
}

.modal-header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
}

.selected-client-info{
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: bold;
    color: #333;
    text-transform: uppercase;
}

.order-detail-section {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.order-detail-column {
    flex: 1 1;
    min-width: 300px;
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 8px;
    background: #f9f9f9;
}

@media (max-width: 768px) {
    .order-detail-section {
        flex-direction: column;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/modals/order_detail/OrderDetailModal.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,8BAA8B;IAC9B,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,iBAAiB;IACjB,aAAa;IACb,kBAAkB;IAClB,cAAc;IACd,WAAW;IACX,yCAAyC;IACzC,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,yBAAyB;IACzB,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;IACnB,eAAe;IACf,iBAAiB;IACjB,WAAW;IACX,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,eAAe;IACf,SAAS;AACb;;AAEA;IACI,SAAO;IACP,gBAAgB;IAChB,sBAAsB;IACtB,aAAa;IACb,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI;QACI,sBAAsB;IAC1B;AACJ","sourcesContent":[".modal-overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    background: rgba(0, 0, 0, 0.5);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 1000;\n}\n\n.modal-content {\n    background: white;\n    padding: 20px;\n    border-radius: 8px;\n    max-width: 80%;\n    width: 100%;\n    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\n    overflow-y: auto;\n    max-height: 80vh;\n}\n\n.modal-header {\n    display: flex;\n    justify-content: flex-end;\n    margin-bottom: 20px;\n}\n\n.selected-client-info{\n    margin-bottom: 10px;\n    font-size: 20px;\n    font-weight: bold;\n    color: #333;\n    text-transform: uppercase;\n}\n\n.order-detail-section {\n    display: flex;\n    flex-wrap: wrap;\n    gap: 20px;\n}\n\n.order-detail-column {\n    flex: 1;\n    min-width: 300px;\n    border: 1px solid #ddd;\n    padding: 20px;\n    border-radius: 8px;\n    background: #f9f9f9;\n}\n\n@media (max-width: 768px) {\n    .order-detail-section {\n        flex-direction: column;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
