// freddy-react/src/components/dashboards/public_freddy_dashboard/PublicFreddyDashboard.js
import React from "react";
import "./PublicFreddyDashboard.css";
import { PrimaryButton } from "../../../components/buttons/primary_button/PrimaryButton";
import PublicFreddyLogin from "../../../components/user/public_freddy_login/PublicFreddyLogin";

const PublicFreddyDashboard = () => {
    return (
        <div className="public-freddy-dashboard-container">
            <PublicFreddyLogin />
        </div>
    );
};

export default PublicFreddyDashboard;