import React, { useState } from 'react';
import './PasswordProtect.css'; // Create and import a CSS file for styling
import { PrimaryButton } from '../../buttons/primary_button/PrimaryButton';
import LabFusionLogo from '../../../assets/logo/LabFusionLogo.png';
import FLBubbles from '../../../assets/FLBubbles.png';
import axios from 'axios';

const PasswordProtect = ({ onAccessGranted }) => {
    const [step, setStep] = useState(1);
    const [collectorFirstName, setCollectorFirstName] = useState('');
    const [collectorLastName, setCollectorLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [clientName, setClientName] = useState('');
    const [error, setError] = useState('');

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handlePhoneChange = (e) => {
        const formattedPhoneNumber = formatPhoneNumber(e.target.value);
        setPhone(formattedPhoneNumber);
    };

    const handleVerify = async (e) => {
        e.preventDefault();
        try {
            setClientName("Joe's Trucking");
            setCollectorFirstName("Stephen")
            setCollectorLastName("Guglielmo")
            setStep(2);
        } catch (err) {
            setError('Verification failed. Please try again.');
        }
    };

    const handleSetCredentials = (e) => {
        e.preventDefault();
        // Here you would send the username and password to your backend to be saved
        // Assuming the backend returns a success response:
        onAccessGranted();
    };

    const formatPhoneNumber = (value) => {
        if (!value) return value;
        const phoneNumber = value.replace(/[^\d]/g, '');
        const phoneNumberLength = phoneNumber.length;
        if (phoneNumberLength < 4) return phoneNumber;
        if (phoneNumberLength < 7) {
            return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}`;
        }
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
    };

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const validDomains = [
            'com', 'net', 'org', 'gov', 'edu', 'ai', 'co', 'us', 'ca', 'uk', 'de', 'fr', 'jp', 'cn', 'ru', 'br', 'au', 'in', 'it', 'es', 'nl', 'se', 'no', 'dk', 'fi'
        ];
        if (re.test(String(email).toLowerCase())) {
            const domain = email.split('.').pop().toLowerCase();
            return validDomains.includes(domain);
        }
        return false;
    };

    return (
        <div className="auth-wrapper">
            <div className="auth-background-overlay" style={{ backgroundImage: `url(${FLBubbles})` }}></div>
            <div className="auth-card">
                <img
                    className="auth-logo"
                    src={LabFusionLogo}
                    alt="Client Logo"
                />
                <div className="auth-form">
                    {step === 1 && (
                        <form onSubmit={handleVerify}>
                            <div className="auth-input-container">
                                <input
                                    type="email"
                                    value={email}
                                    onChange={handleEmailChange}
                                    placeholder="Enter Email"
                                    className="auth-input"
                                    required
                                />
                            </div>
                            <div className="auth-input-container">
                                <input
                                    type="text"
                                    value={phone}
                                    onChange={handlePhoneChange}
                                    placeholder="Enter Phone Number"
                                    className="auth-input"
                                    maxLength="14" // (123) 456-7890 is 14 characters
                                    required
                                />
                            </div>
                            {validateEmail(email) && phone.length === 14 && (
                                <PrimaryButton type="submit" className="auth-btn">
                                    Verify
                                </PrimaryButton>
                            )}
                        </form>
                    )}
                    {step === 2 && (
                        <form onSubmit={handleSetCredentials}>
                            <div className="auth-input-container">
                                <h3>
                                    WELCOME, {collectorFirstName.toUpperCase()}
                                </h3>
                                <h4>
                                    {clientName.toUpperCase()}
                                </h4>
                            </div>
                            <input
                                type="text"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                placeholder="Enter Username"
                                className="auth-input"
                                required
                            />
                            <input
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Enter Password"
                                className="auth-input"
                                required
                            />
                            <PrimaryButton type="submit" className="auth-btn">
                                SET CREDENTIALS
                            </PrimaryButton>
                        </form>
                    )}
                    {error && <p className="auth-error-message">{error}</p>}
                </div>
            </div>
        </div>
    );
};

export default PasswordProtect;
