// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.client-card {
    background: #4F4F4F;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 16px;
    margin-bottom: 20px;
    width: 100%;
    max-width: 300px; /* Ensure cards have a max-width */
    transition: transform 0.3s ease; /* Add transition for hover effect */
    height: fit-content;
}

.client-card:hover {
    height: fit-content;
    transform: translateY(-5px); /* Lift effect on hover */
}

.client-name {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: center;
    color: #ffffff; /* Darker color for better readability */
}

.order-actions {
    display: flex;
    width: 100%;
    justify-content: center;
    padding-top: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/orders/client_order_card/ClientOrderCard.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,kBAAkB;IAClB,wCAAwC;IACxC,aAAa;IACb,mBAAmB;IACnB,WAAW;IACX,gBAAgB,EAAE,kCAAkC;IACpD,+BAA+B,EAAE,oCAAoC;IACrE,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;IACnB,2BAA2B,EAAE,yBAAyB;AAC1D;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,mBAAmB;IACnB,kBAAkB;IAClB,cAAc,EAAE,wCAAwC;AAC5D;;AAEA;IACI,aAAa;IACb,WAAW;IACX,uBAAuB;IACvB,iBAAiB;AACrB","sourcesContent":[".client-card {\n    background: #4F4F4F;\n    border-radius: 8px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n    padding: 16px;\n    margin-bottom: 20px;\n    width: 100%;\n    max-width: 300px; /* Ensure cards have a max-width */\n    transition: transform 0.3s ease; /* Add transition for hover effect */\n    height: fit-content;\n}\n\n.client-card:hover {\n    height: fit-content;\n    transform: translateY(-5px); /* Lift effect on hover */\n}\n\n.client-name {\n    font-size: 18px;\n    font-weight: bold;\n    margin-bottom: 10px;\n    text-align: center;\n    color: #ffffff; /* Darker color for better readability */\n}\n\n.order-actions {\n    display: flex;\n    width: 100%;\n    justify-content: center;\n    padding-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
