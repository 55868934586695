// freddy-react/src/pages/screen/Screen.js
import ProductDetails from "../../components/specimens/screen/product_details/ProductDetails";
import Screener from "../../components/modals/edit/screen/screener/Screener";
import Donor from "../../components/modals/edit/screen/donor/Donor";
import ClientLocation from "../../components/specimens/screen/location/ClientLocation";
import LabbClient from "../../components/specimens/screen/labb_client/LabbClient";
import ReviewScan from "./review_scan/ReviewScan";
import {useEffect, useState} from "react";
import {getScreenDetails} from "../../store/screen/screenSlice";
import {useDispatch, useSelector} from "react-redux";
import Organization from "../../components/modals/edit/screen/organization/Organization";


function ScreenPage() {
    const [freddyResultReady, setFreddyResultReady] = useState(false);
    const dispatch = useDispatch();

    // Get deviceIdHash from the store
    const deviceIdHash = useSelector(state => state.deviceId);
    // console.log("deviceIdHash", deviceIdHash);

    useEffect(() => {
        dispatch(getScreenDetails(deviceIdHash));
    }, [dispatch, deviceIdHash]);

    const {screenDetails} = useSelector(state => state.screen);

    return (
        <div className="test-input-main-body">
            <div>
                {   
                    freddyResultReady ? (
                        <ReviewScan screenDetails={screenDetails}/>
                    ) : null
                }{
                screenDetails ?
                    <div>
                        <ProductDetails screenDetails={screenDetails}/>
                        <LabbClient screenDetails={screenDetails}/>
                        <Screener screenDetails={screenDetails}/>
                        <Donor screenDetails={screenDetails}/>
                        <Organization screenDetails={screenDetails}/>
                        <ClientLocation screenDetails={screenDetails}/>
                    </div>
                    : null
            }
            </div>
        </div>

    )
        ;
}

export default ScreenPage;
