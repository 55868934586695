import React, { useState } from 'react';
import './PublicNav.css';
import SpecimenBilling from "../../specimens/billing/SpecimenBilling";
import ShopifyCustomerDetails from "../../user/cusotmer_details/ShopifyCustomerDetails";
import ClientOrderBilling from "../../orders/client_order_billing/ClientOrderBilling";
import Tab from "./tab/Tab";
import PanelDetails from "../../clients/panel_details/PanelDetails";

const PublicNav = () => {
    const [activeTab, setActiveTab] = useState('customerDetails');

    const renderContent = () => {
        switch (activeTab) {
            case 'specimenBilling':
                return <SpecimenBilling />;
            case 'panelDetails':
                return <PanelDetails />;
            case 'orderBilling':
                return <ClientOrderBilling />;
            default:
                return null;
        }
    };

    return (
        <div>
            <div className="tab-navigation">
                <ShopifyCustomerDetails />
                {/*<Tab label="Customer Details" active={activeTab === 'customerDetails'} onClick={() => setActiveTab('customerDetails')} />*/}
                {/*<Tab label="Specimen Billing" active={activeTab === 'specimenBilling'} onClick={() => setActiveTab('specimenBilling')} />*/}
                <Tab label="Panel Details" active={activeTab === 'panelDetails'} onClick={() => setActiveTab('panelDetails')} />
                <Tab label="Order Billing" active={activeTab === 'orderBilling'} onClick={() => setActiveTab('orderBilling')} />
            </div>
            <div className="tab-content">
                {renderContent()}
            </div>
        </div>
    );
};

export default PublicNav;
