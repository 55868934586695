import React, {useState, useEffect} from 'react';
import './PanelConfiguration.css';
import {GenericIcons} from "../../../../../assets/icons";
import {IconButton} from "../../../../buttons/icon_button/IconButton";
import {useDispatch, useSelector} from "react-redux";
import {fetchPanelsByOrganizationIdThunk} from "../../../../../store/organizations/organizationsSlice";

const PanelConfiguration = ({device_id, panelDetails, onScreenerChange}) => {
    const [hasMore, setHasMore] = useState(true);
    const [offset, setOffset] = useState(0);
    const [isEditing, setIsEditing] = useState(false);
    const [panelConfigurations, setPanelConfigurations] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [showDropdown, setShowDropdown] = useState(false);
    const specimenData = useSelector(state => state.screen.screen);
    const LIMIT = 20;
    const dispatch = useDispatch();

    // Storing the original panel configuration when the component first loads
    const [originalPanelConfiguration, setOriginalPanelConfiguration] = useState({
        "panel_id": panelDetails.panel_id,
        "panel_name": panelDetails.panel_name,
        "original_panel_id": panelDetails.panel_id
    });

    const [selectedPanelConfiguration, setSelectedPanelConfiguration] = useState({
        "panel_id": panelDetails.panel_id,
        "panel_name": panelDetails.panel_name,
        "original_panel_id": originalPanelConfiguration.panel_id
    });


    const handleScroll = (e) => {
        const dropdown = e.target;
        if (dropdown.scrollHeight - dropdown.scrollTop === dropdown.clientHeight && hasMore) {
            setOffset(prev => prev + LIMIT);
        }
    }

    useEffect(() => {
        if (isEditing && specimenData && specimenData.length > 0) {

            dispatch(fetchPanelsByOrganizationIdThunk({
                "device_id": device_id,
                "organization_id": specimenData[0].organization_id
            }))

                .then(response => {
                    const panels = response?.payload;
                    setPanelConfigurations(panels);

                    // Find the matching organization from the fetched list
                    const matchingPanelConfiguration = panels.find(org => org.panel_name === originalPanelConfiguration.panel_name);
                    if (matchingPanelConfiguration) {
                        setOriginalPanelConfiguration({
                            panel_name: matchingPanelConfiguration.panel_name,
                            panel_id: matchingPanelConfiguration.panel_id
                        });
                    }
                })
                .catch(error => {
                    console.error("Error fetching organizations:", error);
                });
        }
    }, [isEditing, specimenData, offset]);

    const toggleEdit = (e) => {
        e.preventDefault();
        setIsEditing(!isEditing);
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handlePanelConfigurationSelect = (panel) => {
        const selectedPanelInfo = {
            panel_id: panel.panel_id,
            panel_name: panel.panel_name,
            original_panel_id: originalPanelConfiguration.panel_id
        };
        onScreenerChange(selectedPanelInfo)
        setSelectedPanelConfiguration(selectedPanelInfo);
        setShowDropdown(false);
        setIsEditing(false);
    };

    return (
        <section className="panel_configuration">
            <div className="panel_configuration_header">
                <p className="panel_configuration_header_text">Panel Configuration</p>
                {!isEditing
                    ? <IconButton onClick={toggleEdit} defaultIcon={GenericIcons.EditIcon}
                                  hoverIcon={GenericIcons.EditIcon}/>
                    : <IconButton onClick={toggleEdit} defaultIcon={GenericIcons.CloseIcon}
                                  hoverIcon={GenericIcons.ExitIcon}/>
                }
            </div>
            <div className="dark_grey_strip"/>
            <div className="panel_configuration_info_section">
                {isEditing ? (
                    <>
                        <input
                            className="panel-searchable-dropdown"
                            type="text"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            onFocus={() => setShowDropdown(true)}
                            placeholder="Search panel..."
                        />
                        {showDropdown && (
                            <div className="dropdown-content">
                                {panelConfigurations?.map(panel => (
                                    <div key={panel.panel_id} onClick={() => handlePanelConfigurationSelect(panel)}
                                         className="panel_dropdown_row">
                                        <p className="panel_dropdown_row_text">
                                            {panel.panel_name}
                                        </p>
                                    </div>
                                ))}
                            </div>
                        )}
                    </>
                ) : (
                    <p>{selectedPanelConfiguration.panel_name}</p>
                )}
            </div>
        </section>
    );
};

export default PanelConfiguration;
