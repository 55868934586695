// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.specimen-notification {
    background-color: #f2f2f2; /* Light background for the notification */
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.specimen-notification-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.specimen-notification-content-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
}

.specimen-notification-content-description {
    font-size: 14px;
    color: #666; /* Darker text for contrast */
}
`, "",{"version":3,"sources":["webpack://./src/components/modals/notifications/notificiations_list/notification_item/specimen_notificaiotn/SpecimenNotification.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB,EAAE,0CAA0C;IACrE,aAAa;IACb,kBAAkB;IAClB,mBAAmB;IACnB,2CAA2C;AAC/C;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,8BAA8B;AAClC;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,WAAW,EAAE,6BAA6B;AAC9C","sourcesContent":[".specimen-notification {\n    background-color: #f2f2f2; /* Light background for the notification */\n    padding: 15px;\n    border-radius: 5px;\n    margin-bottom: 10px;\n    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);\n}\n\n.specimen-notification-content {\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n}\n\n.specimen-notification-content-title {\n    font-size: 16px;\n    font-weight: bold;\n    margin-bottom: 5px;\n}\n\n.specimen-notification-content-description {\n    font-size: 14px;\n    color: #666; /* Darker text for contrast */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
