// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.countdown-timer {
    height: 5px;
    width: 100%;
    background-color: #e0e0e0;
    border-radius: 2px;
    overflow: hidden;
}

.countdown-progress {
    height: 100%;
    background-color: #3C74B9;
    transition: width 1s linear;
}`, "",{"version":3,"sources":["webpack://./src/components/countdown_timer/CountdownTimer.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,WAAW;IACX,yBAAyB;IACzB,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,yBAAyB;IACzB,2BAA2B;AAC/B","sourcesContent":[".countdown-timer {\n    height: 5px;\n    width: 100%;\n    background-color: #e0e0e0;\n    border-radius: 2px;\n    overflow: hidden;\n}\n\n.countdown-progress {\n    height: 100%;\n    background-color: #3C74B9;\n    transition: width 1s linear;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
