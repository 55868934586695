import React, {useState} from "react";
import "./ManageShopifyPage.css";
import {PrimaryButton} from "../../../components/buttons/primary_button/PrimaryButton";
import Header from "../../../components/header/Header";
import ShopifyManageCustomers from "../../../components/shopify/manage/customers/ShopifyManageCustomers";
import ShopifyManageProducts from "../../../components/shopify/manage/products/ShopifyManageProducts";
import ShopifyManageCompanies from "../../../components/shopify/manage/companies/ShopifyManageCompanies";
import ShopifyManageOrders from "../../../components/shopify/manage/orders/ShopifyManageOrders";
import ShopifyManageServices from "../../../components/shopify/manage/services/ShopifyManageServices";

const ManageShopifyPage = () => {
    const [activeTab, setActiveTab] = useState('customers'); // default tab

    const renderActiveTab = () => {
        switch (activeTab) {
            case 'customers':
                return <ShopifyManageCustomers />;
            case 'products':
                return <ShopifyManageProducts />;
            case 'services':
                return <ShopifyManageServices />;
            case 'companies':
                return <ShopifyManageCompanies />;
            case 'orders':
                return <ShopifyManageOrders />;
            default:
                return <ShopifyManageCustomers />;
        }
    };

    return (
        <div>
            <div>
                <Header />
            </div>
            <div className="shopify-tabs">
                <PrimaryButton onClick={() => setActiveTab('customers')}>Customers</PrimaryButton>
                <PrimaryButton onClick={() => setActiveTab('products')}>Products</PrimaryButton>
                <PrimaryButton onClick={() => setActiveTab('services')}>Services</PrimaryButton>
                <PrimaryButton onClick={() => setActiveTab('companies')}>Companies</PrimaryButton>
                <PrimaryButton onClick={() => setActiveTab('orders')}>Orders</PrimaryButton>
            </div>

            <div className="shopify-content">
                {renderActiveTab()}
            </div>
        </div>
    );
};

export default ManageShopifyPage;
