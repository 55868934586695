// freddy-react/src/components/headers/public_freddy_header/PublicFreddyHeader.js
import "./PublicFreddyHeader.css";
import React from "react";
import { NavLink } from "react-router-dom";
import { PrimaryButton } from "../../buttons/primary_button/PrimaryButton";

const PublicFreddyHeader = ({ logoSrc, onLogout }) => {
    return (
        <header className="public-freddy-header">
            <div className="logo-container">
                <NavLink to="/" className="logo-link">
                    <img className="logo" src={logoSrc} alt="Freddy Logo" />
                </NavLink>
            </div>
            <div className="header-right">
                <PrimaryButton onClick={onLogout}>
                    LOGOUT
                </PrimaryButton>
            </div>
        </header>
    );
};

export default PublicFreddyHeader;