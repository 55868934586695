import React from "react";
import "./SpeedSwabsFeatures.css";
import SpeedSwabsFeaturesCompare from "../feature_cards/compare/SpeedSwabsFeaturesCompare";
import SpeedSwabsFeaturesUltimate from "../feature_cards/ultimate/SpeedSwabsFeaturesUltimate";
import TalkSwabs from "../../modals/forms/progressive/talk_swabs/TalkSwabs";

// The comparison data as an array of objects
const comparisonData = [
    {
        criteria: "Test Speed",
        criteriaSubheader: "Collection + Read Time",
        speedSwabs: "1-2 Minutes",
        speedSwabsSubheader: "On negative results",
        otherTests: "5-10 Minutes",
        otherTestsSubheader: "On negative results"
    },
    {
        criteria: "Success Rate",
        criteriaSubheader: "How often a test fails to run",
        speedSwabs: "99% Guaranteed",
        speedSwabsSubheader: "Results generated every time",
        otherTests: "Fails Often",
        otherTestsSubheader: "10-40% failure rates"
    },
    {
        criteria: "Sample Volume",
        criteriaSubheader: "Amount of specimen required",
        speedSwabs: "Minimal Sample",
        speedSwabsSubheader: "Efficient design uses less specimen",
        otherTests: "Significant Sample",
        otherTestsSubheader: "Poor design requires excess sample"
    },
    {
        criteria: "Tampering",
        criteriaSubheader: "By donor during collection or testing",
        speedSwabs: "Tamper Proof",
        speedSwabsSubheader: "Impossible to impact collection",
        otherTests: "Easy to Tamper",
        otherTestsSubheader: "Collector pad manipulation"
    },
    {
        criteria: "Ease of Use",
        criteriaSubheader: "Steps required to perform",
        speedSwabs: "Very Easy",
        speedSwabsSubheader: "Collection technique important",
        otherTests: "Moderate",
        otherTestsSubheader: "Training & technique required"
    },
    {
        criteria: "Result Obstruction",
        criteriaSubheader: "Sample interference with result window",
        speedSwabs: "Easy to Read",
        speedSwabsSubheader: "Result obstruction not possible",
        otherTests: "Can Be Obstructed",
        otherTestsSubheader: "Leads to inaccurate reads"
    }
];

const SpeedSwabsFeatures = () => {
    return (
        <div className="speed-swabs-features">
            <SpeedSwabsFeaturesUltimate />
            <SpeedSwabsFeaturesCompare comparisonData={comparisonData} />
            <TalkSwabs />
        </div>
    );
};

export default SpeedSwabsFeatures;
