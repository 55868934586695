
// freddy-react/src/components/user/public_freddy_login/PublicFreddyLogin.js
import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import "./PublicFreddyLogin.css";
import {config} from "../../Constants";
import {PrimaryButton} from "../../buttons/primary_button/PrimaryButton";


// Function to convert ArrayBuffer to Base64
const arrayBufferToBase64 = (buffer) => {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
};

const deriveKeyFromToken = async (token) => {
    const cleanedToken = token.replace(/[^a-fA-F0-9]/g, '');  // Clean non-hex chars
    const cleanedTokenBytes = new TextEncoder().encode(cleanedToken);
    const hashBuffer = await crypto.subtle.digest('SHA-256', cleanedTokenBytes);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    while (hashArray.length < 32) {
        hashArray.push(0);
    }
    return new Uint8Array(hashArray);  // Returns Uint8Array of length 32
};

// Function to encrypt the password using AES-GCM
const encryptPassword = async (password, token) => {
    const keyBytes = await deriveKeyFromToken(token);  // Derive key
    const key = await crypto.subtle.importKey(
        'raw',
        keyBytes,
        { name: 'AES-GCM' },
        false,
        ['encrypt']
    );

    const iv = crypto.getRandomValues(new Uint8Array(12));  // Generate 12-byte IV for AES-GCM

    const encrypted = await crypto.subtle.encrypt(
        { name: 'AES-GCM', iv: iv },
        key,
        new TextEncoder().encode(password)
    );

    // Return encrypted data and IV in Base64
    return {
        ciphertext: arrayBufferToBase64(encrypted),  // Convert to Base64
        iv: arrayBufferToBase64(iv)  // Convert IV to Base64
    };
};

const PublicFreddyLogin = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();

    // Extract token from URL
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');

    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!username || !password) {
            setError("Username and password are required.");
            return;
        }

        try {
            const { ciphertext, iv } = await encryptPassword(password, token);

            // Create FormData and send to backend
            const formData = new FormData();
            formData.append('token', token);
            formData.append('username', username);
            formData.append('password', ciphertext);
            formData.append('iv', iv);

            const response = await axios.post(`${config.url.FREDDY_API_URL}api/v1/user/login`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.status === 200) {
                navigate('/dashboard');
            } else {
                setError('Failed to log in. Please try again.');
            }
        } catch (err) {
            console.error('Login failed:', err);
            setError('An error occurred during login. Please try again.');
        }
    };

    return (
        <div className="public-freddy-login-page">
            <div className="login-container">
                <h2>Login to Freddy</h2>
                {error && <div className="error-message">{error}</div>}
                <form onSubmit={handleSubmit} className="login-form">
                    <div className="form-group">
                        <label htmlFor="username">Username:</label>
                        <input
                            type="text"
                            id="username"
                            value={username}
                            onChange={handleUsernameChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Password:</label>
                        <input
                            type="password"
                            id="password"
                            value={password}
                            onChange={handlePasswordChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <PrimaryButton type="submit">Login</PrimaryButton>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default PublicFreddyLogin;