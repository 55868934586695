import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {fetchCollectorByEmail, getShopifyCustomerDetails} from "../../../store/user/shopifyUserSlice";
import './ShopifyCustomerDetails.css';
import {PrimaryButton} from "../../buttons/primary_button/PrimaryButton";
import {getCollectorByEmail} from "../../../services/api/v1/userService";  // Import the CSS file for styling

const ShopifyCustomerDetails = () => {
    const dispatch = useDispatch();
    const shopifyAccessToken = sessionStorage.getItem('shopifyGrantToken');
    const shopifyIdToken = sessionStorage.getItem('shopifyIdToken');
    const customerDetails = useSelector(state => state.shopifyUser.customerDetails);

    useEffect(() => {
        if (shopifyAccessToken && shopifyIdToken) {
            console.log('Fetching customer details using Shopify API...');
            dispatch(getShopifyCustomerDetails());  // Ensure the correct thunk is dispatched
        } else {
            console.log('Shopify access token or ID token missing. Fetching customer details skipped.');
        }
    }, [dispatch, shopifyAccessToken, shopifyIdToken]);

    useEffect(() => {
        if (customerDetails) {
            const formData = new FormData();
            formData.append('collector_email', customerDetails.emailAddress.emailAddress);
            formData.append('shopify_oauth_token', shopifyAccessToken);
            dispatch(fetchCollectorByEmail(formData));
        }
    }, [customerDetails]);
};

export default ShopifyCustomerDetails;