// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.primary-button,
.secondary-button,
.danger-button {
    padding: 4%;
    margin: 4%;
    border-radius: 5px;
    white-space: nowrap;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border: none;
    cursor: pointer;
    width: 85%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.icon-button {
    border: none;
}

.primary-button {
    background: #2F80ED;
    color: #fff;
}

.primary-button.selected,
.secondary-button.selected,
.danger-button.selected {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background: #2F80ED;
}

.secondary-button {
    background: #D8D8D8;
}

.danger-button {
    background: #EB5757;
}

.icon-button {
    background: transparent;
    box-shadow: none;
}

.icon-wrapper {
    width: 24px;
    height: 24px;
}
`, "",{"version":3,"sources":["webpack://./src/components/buttons/button_styles.css"],"names":[],"mappings":"AAAA;;;IAGI,WAAW;IACX,UAAU;IACV,kBAAkB;IAClB,mBAAmB;IACnB,2CAA2C;IAC3C,YAAY;IACZ,eAAe;IACf,UAAU;IACV,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;AACA;IACI,YAAY;AAChB;;AAEA;IACI,mBAAmB;IACnB,WAAW;AACf;;AAEA;;;IAGI,2CAA2C;IAC3C,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,uBAAuB;IACvB,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB","sourcesContent":[".primary-button,\n.secondary-button,\n.danger-button {\n    padding: 4%;\n    margin: 4%;\n    border-radius: 5px;\n    white-space: nowrap;\n    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);\n    border: none;\n    cursor: pointer;\n    width: 85%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n.icon-button {\n    border: none;\n}\n\n.primary-button {\n    background: #2F80ED;\n    color: #fff;\n}\n\n.primary-button.selected,\n.secondary-button.selected,\n.danger-button.selected {\n    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);\n    background: #2F80ED;\n}\n\n.secondary-button {\n    background: #D8D8D8;\n}\n\n.danger-button {\n    background: #EB5757;\n}\n\n.icon-button {\n    background: transparent;\n    box-shadow: none;\n}\n\n.icon-wrapper {\n    width: 24px;\n    height: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
