// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.industry-selector {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin: 1rem 0;
}

.industry-button {
    background-color: #e0e0e0;
    color: #333;
    border: none;
    border-radius: 20px;
    padding: 0.75rem 1.5rem;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    font-size: 1rem;
}

.industry-button:hover {
    background-color: #d4d4d4;
}

.industry-button.selected {
    background-color: #007bff;
    color: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/components/modals/forms/assets/industry_selector/IndustrySelector.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,SAAS;IACT,cAAc;AAClB;;AAEA;IACI,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;IACf,uDAAuD;IACvD,eAAe;AACnB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IACzB,WAAW;AACf","sourcesContent":[".industry-selector {\n    display: flex;\n    flex-wrap: wrap;\n    gap: 1rem;\n    margin: 1rem 0;\n}\n\n.industry-button {\n    background-color: #e0e0e0;\n    color: #333;\n    border: none;\n    border-radius: 20px;\n    padding: 0.75rem 1.5rem;\n    cursor: pointer;\n    transition: background-color 0.3s ease, color 0.3s ease;\n    font-size: 1rem;\n}\n\n.industry-button:hover {\n    background-color: #d4d4d4;\n}\n\n.industry-button.selected {\n    background-color: #007bff;\n    color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
